@import (reference) '~stylesheets/modules/type';

.margin-bottom-24 {
  margin-bottom: 24px;
}

.charity-modal-content-header {
  margin-bottom: 16px;
}

.charity-modal-content {
  padding: 0 6%;

  @media (min-width: 426px) {
    padding: 0 12%;
  }

  @media (min-width: 768px) {
    padding: 0 19%;
  }
}

.charity-heart-image {
  margin-bottom: 24px;

  img {
    width: 106px;
    height: 98px;
  }
}

.charity-details-modal-body > ol {
  list-style-type: none;
  margin: 0;

  li {
    width: 100%;
    margin: 16px 0;
  }
}
