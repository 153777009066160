@import (reference) '~stylesheets/twbs';

.profile-cover__creator-name {
  margin: 0 0 0 0.4rem;

  @media (max-width: @screen-sm-min) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media (max-width: @screen-xs-min) {
    padding-right: 1.5rem;
  }
}

.profile-cover {
  position: absolute;
  bottom: -65px;
  left: 10px;
}

.profile-avatar {
  margin: 'auto';
  margin-bottom: 10px;
}
