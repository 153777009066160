@import (reference) '~stylesheets/twbs';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~stylesheets/modules/type';

.ended-title {
  .text-5();
  margin-bottom: 12px;
}

.title {
  .text-2();
  word-wrap: break-word;
  margin-bottom: 36px;
}

.goal-cover-title {
  font-weight: bold;
  margin-bottom: 6px;
}

.textbox-wrapper {
  margin: 12px 0;

  &:last-child {
    margin-bottom: 20px;
  }
}

.category {
  margin-bottom: 16px;
}

.fs-option {
  .text-5_lighter();
}

.description-container {
  width: 100%;
  margin-bottom: 36px;
}

.textbox-wrapper {
  position: relative;
}

.char-count {
  .text-legal();
  text-align: right;
  position: absolute;
  bottom: 8px;
  right: 16px;
}

.uploader {
  margin-bottom: 40px;
}

.delete-link {
  .text-5();
  display: flex;
}

.delete-icon {
  font-size: 16px;
  .text-5();
  margin-top: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.save-button {
  margin-top: 36px;
}

.modal {
  z-index: 2000;
  width: 936px;

  @media (max-width: 414px) {
    width: 100%;
  }

  &_title {
    text-align: center;
    padding-left: 48px;
  }
}

.modal_title {
  position: absolute;
  width: calc(100% - 10rem);
  align-items: center;

  @media (max-width: 414px) {
    width: 75%;
  }
}

.btn {
  width: 100%;
  display: block;
  margin-bottom: 16px;
}

.charity-header {
  color: #2c2e2f;
  margin-bottom: 8px;
  font-weight: bold;
}

.charity-subtitle {
  color: 687173;
  font-size: 14px;
  margin-bottom: 16px;
}

.detail-title {
  margin-top: 36px;
  margin-bottom: 21px;
}
