.balance {
  & .pledge-row {
    display: flex;
    flex-direction: row;
    margin-top: 14px;
  }

  & .amount-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .label {
    flex-grow: 2;
    display: flex;
    align-items: center;
  }

  & .value {
    flex-grow: 1;
    text-align: right;
  }

  & .bold-value {
    flex-grow: 1;
    text-align: right;
  }

  &-wrapper {
    margin-bottom: 76px;
  }
}

.donation-title {
  margin: 22px auto;
}

.balance-wrapper {
  padding-right: 24px;
}
