@import (reference) '~stylesheets/modules/type';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~stylesheets/vx_extensions';

.title {
  text-align: center;
}

.done-button {
  margin: 0 auto 12px;
}

.sub-title {
  text-align: center;
  margin-top: 12px;
}

.actions {
  margin-top: 48px;
  display: flex;
  justify-content: center;
}

.withdraw-button {
  width: 343px;
}

.success-image {
  margin-bottom: 36px;
  display: flex;
  justify-content: center;
}

.transfer-container {
  padding: 0 16px;
}
