@import (reference) '~pp-tokens/build/web/theme/paypal/tokens';

.card--selected::after {
  content: '';
  position: absolute;
  top: -0.0625rem;
  left: -0.0625rem;
  border: 0.1875rem solid @sys-color-secondary-main;
  box-shadow: 0 0 0 0.375rem @sys-color-structure-focus-outline;
  border-radius: inherit;
  width: calc(100% - -0.125rem);
  height: calc(100% - -0.125rem);
}

.campaign__icon {
  color: @sys-color-primary-main;
  vertical-align: middle;
}

.card__content {
  display: flex;
  flex-direction: row;
  margin: 0 !important;
  padding: 0;
  height: 200px;
}

.content__column-left {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 21px 14px 16px;
}

.content__column-right {
  flex: 1;
  overflow: hidden;
  flex-basis: 20px;
}

.column-left__top,
.column-left__bottom {
  flex-grow: 0;
}

.column-left__top,
.middle__formatted-amount {
  margin-bottom: 8px;
}

.column-left__middle {
  flex-grow: 1;
}

.card {
  padding: 0 !important;
  margin: 0 0 16px;
}
