.flat-progress {
  div.amount {
    margin-bottom: 8px;

    &--mb {
      margin-bottom: 12px;
    }
  }

  div.title {
    margin-bottom: 12px;
  }

  .meter-component {
    padding: 0;
  }
}
