@import (reference) '~bootstrap/less/variables';

@screen-xs-min: 320px;
@screen-sm-min: 700px;
@screen-md-min: 900px;
@screen-lg-min: 1160px;
@screen-vlg-min: 1360px;
@screen-md-max: 750px;
@screen-tab-md-min: 768px;
@vxlib-screen-sm-min: 768px; // which is different from our breakpoint

@grid-gutter-width: 24px;
@grid-extreme-gutter-width: 48px;
@grid-extreme-gutter-width-mobile: 36px;
@grid-columns: 12;
@font-size-base: 16px;

@import '~bootstrap/less/mixins';
@import '~bootstrap/less/responsive-utilities';
@import '~bootstrap/less/grid';

// Use this mixin to align containers to the grid.
// We need this mixin, because
// 1) the extreme gutters are different than the regular gutters between columns.
// 2) the grid's width is capped at @screen-lg-min

.pools-grid-container() {
  .container-fixed(@grid-extreme-gutter-width * 2);
  max-width: @screen-lg-min + @grid-extreme-gutter-width * 2;

  @media (max-width: @screen-sm-min) {
    padding-left: @grid-extreme-gutter-width-mobile;
    padding-right: @grid-extreme-gutter-width-mobile;
  }
}

// A trick to make bootstrap columns contents verically align to center
// https://stackoverflow.com/questions/20547819/vertical-align-with-bootstrap-3

.__vcenter() {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.vcenter() {
  @media (min-width: @screen-sm-min) {
    .__vcenter();
  }

  @media (min-width: @screen-md-min) {
    .__vcenter();
  }

  @media (min-width: @screen-lg-min) {
    .__vcenter();
  }

  .__vcenter();
}
