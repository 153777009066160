@import (reference) '~stylesheets/modules/type';

.campaign-not-found {
  text-align: center;
}

.icon-container {
  width: 116px;
  height: 116px;
  display: inline-block;
  margin-bottom: 35px;
}

.title {
  .text-2();
  margin-bottom: 8px;
}

.description {
  .text-5_lighter();
  margin-bottom: 36px;
}

.cta-button {
  margin-top: 10px;
}
