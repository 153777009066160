@import (reference) '~stylesheets/modules/type';
@import (reference) '~pp-tokens/build/web/less/tokens';

.tip-wrapper {
  display: flex;
  margin-top: 16px;

  .tip-avatar-wrapper {
    align-self: center;
  }

  .tip-icon {
    background-color: @ppui-v2-color-neutral-100 !important;

    span {
      color: @ppui-v2-color-grey-600;
    }
  }

  .tip-text {
    margin-left: 8px;
    align-self: center;

    span {
      color: @ppui-v2-color-grey-600;
    }
  }
}
