@crossFadeTransitionDuration: 350ms;
@crossFadeTransitionDelay: 150ms;

.crossfade-container {
  position: relative;
  width: 100%;
}

.crossfade-transition(@from-opacity, @to-opacity, @dir) {
  .crossfade-@{dir} {
    opacity: @from-opacity;
    position: absolute;

    &.crossfade-@{dir}-active {
      opacity: @to-opacity;
      transition: opacity @crossFadeTransitionDuration ease-out;
      transition-delay: @to-opacity * @crossFadeTransitionDelay; // delay the component that goes to full opacity.
    }
  }
}

.crossfade-transition(0, 1, enter);
.crossfade-transition(1, 0, leave);
