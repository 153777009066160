@import (reference) '~stylesheets/modules/type';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';

.title {
  margin-bottom: 36px;
}

.amount-container {
  margin-top: 24px;
  margin-bottom: 36px;
}

.amount-description {
  .text-legal();
  margin-top: 4px;
}

.transfer-btn {
  margin-top: 33px;
  text-align: center;
}
