.count_down_container {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.unit_container {
  display: flex;

  > div {
    display: flex;
    flex-direction: column;
  }
}

.unit_value {
  text-align: center;
}

.unit_text {
  margin-top: 7px;
}

.unit_separator {
  margin: 0 11px;
  line-height: 1;
}
