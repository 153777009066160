@import (reference) '~pp-tokens/build/web/less/tokens';

.charity-tile-logo {
  width: 72px !important;
  height: 72px !important;
  margin-right: 12px;

  img {
    object-fit: contain;
  }
}

.charity-wrap {
  margin-bottom: 16px;
  background-color: @ppui-v2-color-neutral-100 !important;
  border: none;
  border-radius: 4px;
}

.charity-title {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 16px;
  height: 104px;
}
