@media (max-width: 700px) {
}
.preview-animation-container {
  position: relative;
}
.create-modal-enter {
  opacity: 0;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
.create-modal-enter-active {
  opacity: 1;
  transition: all 500ms ease-out;
  transition-delay: 250ms;
}
.create-modal-leave {
  opacity: 1;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
.create-modal-leave-active {
  opacity: 0;
  transition: all 750ms ease-out;
  transition-delay: 0;
}
.preview-enter {
  opacity: 0;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  overflow: hidden !important;
  height: 100% !important;
}
.preview-enter::before {
  content: '';
  background-color: #F5F7FA;
  height: 100vh;
  width: calc((100% - 612px)/2);
  position: fixed;
  top: 0;
  z-index: 50001;
  left: 0;
  transform: translate3d(0%, 0, 0);
}
.preview-enter::after {
  content: '';
  background-color: #F5F7FA;
  height: 100vh;
  width: calc((100% - 612px)/2);
  position: fixed;
  top: 0;
  z-index: 50001;
  right: 0;
  transform: translate3d(0%, 0, 0);
}
.preview-enter-active {
  opacity: 1;
  transition: all 750ms ease-out;
  transition-delay: 0;
}
.preview-enter-active::before {
  transition: transform 250ms ease-out;
  transition-delay: 500ms;
  transform: translate3d(-100%, 0, 0);
}
.preview-enter-active::after {
  transition: transform 250ms ease-out;
  transition-delay: 500ms;
  transform: translate3d(100%, 0, 0);
}
.preview-leave {
  opacity: 1;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  overflow: hidden !important;
  height: 100% !important;
}
.preview-leave::before {
  content: '';
  background-color: #F5F7FA;
  height: 100vh;
  width: calc((100% - 612px)/2);
  position: fixed;
  top: 0;
  z-index: 50001;
  left: 0;
  transform: translate3d(-100%, 0, 0);
}
.preview-leave::after {
  content: '';
  background-color: #F5F7FA;
  height: 100vh;
  width: calc((100% - 612px)/2);
  position: fixed;
  top: 0;
  z-index: 50001;
  right: 0;
  transform: translate3d(100%, 0, 0);
}
.preview-leave-active {
  opacity: 0;
  transition: all 500ms ease-out;
  transition-delay: 250ms;
}
.preview-leave-active::before {
  transition: transform 250ms ease-out;
  transition-delay: 0ms;
  transform: translate3d(0%, 0, 0);
}
.preview-leave-active::after {
  transition: transform 250ms ease-out;
  transition-delay: 0ms;
  transform: translate3d(0%, 0, 0);
}
