@import (reference) '~pp-tokens/build/web/less/tokens';

.continer {
  border: 1px solid @ppui-v2-color-neutral-200 !important;
  box-shadow: none;
  background: @ppui-v2-color-neutral-200 !important;
  margin-top: 1rem;
  padding: 0 1rem !important;
}

.header {
  margin-bottom: 1rem;
}
