@import (reference) '~stylesheets/modules/type';
@import (reference) '~pp-tokens/build/web/less/tokens';

.title {
  text-align: center;
  margin-bottom: 4px;
}

.sub-title {
  color: @ppui-v2-color-black;
  margin-bottom: 32px;

  li {
    color: #0c0c0d;
    font-family: 'PayPalSansBig-Regular', Helvetica Neue, Arial, sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: normal;
    list-style-type: disc;
  }
}

.uploader {
  margin-bottom: 0;
}

.disclaimer {
  margin-top: 32px;
  margin-bottom: 16px;

  a {
    font-weight: 600;
  }

  a:focus {
    text-decoration: underline !important;
  }
}

.modal {
  z-index: 2000;
  width: 936px;

  @media (max-width: 414px) {
    width: 100%;
  }
}

.modal_title {
  text-align: center;
  padding-left: 48px;
  position: absolute;
  width: calc(100% - 10rem);
  align-items: center;

  @media (max-width: 414px) {
    width: 75%;
  }
}

div[role='dialog'] {
  z-index: 2001;
}

.no_photo_row {
  margin-top: 0.7rem;
}

.no_photo-button {
  width: 100%;

  @media (max-width: 425px) {
    span {
      font-size: 14px;
    }
  }
}

.button_offset {
  margin-top: 2.25rem;
}

.container_class {
  max-width: 295px;
  height: 400px;

  @media (min-width: 426px) {
    max-width: 452px;
  }
}

form {
  padding: 0 16px;
}

.icon-alignment,
.text-alignment {
  text-align: center;
}

.buttons {
  display: inline-grid;
  justify-items: center;
  width: 100%;

  button {
    margin-bottom: 16px;
  }
}
