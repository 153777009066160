@media (max-width: 700px) {
}
.settings-link {
  composes: vx_text-5 from 'vxpattern-lib-paypal/dist/components/type/_type.less';
  margin-top: 12px;
  display: block;
  text-align: center;
}
.mt-10 {
  margin-top: 10px;
}
.mb-25 {
  margin-bottom: 25px;
}
