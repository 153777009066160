@revealTransitionDuration: 300ms;

.reveal-transition(@from, @to, @dir) {
  .reveal-@{dir} {
    transform: scaleY(@from);
    transform-origin: top;
    opacity: @from;

    &.reveal-@{dir}-active {
      transform: scaleY(@to);
      opacity: @to;
      transition: all @revealTransitionDuration ease-out;
    }
  }
}

.reveal-transition(0, 1, enter);
.reveal-transition(1, 0, leave);
