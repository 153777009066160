@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';

@gradient-blue-start: @vxPalBlue;
@gradient-blue-end: @vxPayBlue;
@gradient-purple-start: @vxPurple;
@gradient-purple-end: @vxPayBlue;
@gradient-fuchsia-start: #de0063;
@gradient-fuchsia-end: @vxPurple;
@gradient-red-start: @vxOrange;
@gradient-red-end: @vxRed;
@gradient-teal-start: @vxLightGreen;
@gradient-teal-end: @vxPalBlue;
@gradient-gray-start: #7f7f7f;
@gradient-gray-end: #323232;
@gradient-green-start: #abe456;
@gradient-green-end: #397d1e;
@gradient-sky-start: #4be3f5;
@gradient-sky-end: #0059b1;
@gradient-pink-start: #faa0ac;
@gradient-pink-end: #ff182b;
@gradient-orange-start: #ffd44b;
@gradient-orange-end: #ff9600;

.pools-gradient(@color, @ang: 0deg) {
  @start-color: 'gradient-@{color}-start';
  @end-color: 'gradient-@{color}-end';
  background-image: linear-gradient(@ang, @@start-color 0%, @@end-color 100%);
}
