.image-cropper {
  .icon-panel {
    justify-content: space-between;
    display: flex;
    cursor: pointer;
  }

  .action-button-row {
    padding: 1rem 0;
  }

  .cropper-wrapper {
    width: 100%;
    background: #666;
    justify-content: center;
  }
}
