@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~stylesheets/twbs';

.headerCaptionText {
  margin: 0 0 0 0.4rem;

  @media (max-width: @screen-sm-min) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media (max-width: @screen-xs-min) {
    padding-right: 1.5rem;
  }
}

.iconCaptionContainer {
  display: flex;
  align-items: center;
  padding-right: 0;
  max-width: 100% !important;

  @media (max-width: @screen-sm-min) {
    width: auto !important;
  }

  .categoryBadge {
    margin: 16px 8px 16px 0;
    display: inline-block !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media (max-width: @screen-sm-min) {
      margin: 10px 8px 5px 0;
    }
  }
}

.iconCaptionWrapper {
  flex-wrap: no-wrap;
  max-width: 100%;

  @media (max-width: @screen-sm-min) {
    flex-wrap: wrap;
  }
}

.avatarImage {
  img {
    object-fit: scale-down;
  }
}

.heading {
  margin: 0 !important;
}

@media (max-width: @phonePortraitMax) {
  .heading {
    width: 300px;
    white-space: normal;
    max-width: 100%;
  }
}

.charity-header {
  margin: 8px 0;
}

.top-alignment {
  margin-top: 16px;
}

.charity-name-link {
  margin-left: 5px;
}

.campaign_padding {
  margin: 6px 0 0;
}

.mwebheaderCaptionText {
  @media (max-width: @screen-sm-min) {
    padding: 5px;
  }
}
