.error-wrapper {
  padding: 2rem;
  margin: auto;
}

.back-button {
  width: 90%;
}

.container_class {
  max-width: 295px;
  height: 365px;

  @media (min-width: 426px) {
    max-width: 452px;
  }
}
