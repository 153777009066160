@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '../../stylesheets/gradient_colors';
@import (reference) '~stylesheets/twbs';

.strip {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate3d(0, 0, 0); // improves performance for large data-uris
  border-radius: 12px 12px 0 0;

  @media (max-width: @phonePortraitMax) {
    border-radius: 5px;
  }
}

.static {
  height: 100%;
}

.strip-image {
  border-radius: 0 12px 12px 0;
}

.strip-with-image {
  composes: strip;

  &.responsive {
    height: 0;
    padding-bottom: 33%;

    @media (max-width: @screen-sm-min) {
      padding-bottom: 53%;
    }

    @media (min-width: @screen-vlg-min) {
      padding-bottom: 28%;
    }
  }

  &.responsive-fullscreen {
    height: 33vw;
    max-height: 500px;

    @media (max-width: @screen-sm-min) {
      height: 53vw;
    }

    @media (min-width: @screen-vlg-min) {
      height: 28vw;
    }
  }
}

.strip-with-gradient {
  composes: strip;
  height: 100%;

  @media (max-width: @phonePortraitMax) {
    min-height: 151px;
  }
}

.strip-blue {
  composes: strip-with-gradient;
  .pools-gradient('blue', 90deg);
}

.strip-purple {
  composes: strip-with-gradient;
  .pools-gradient('purple', 90deg);
}

.strip-fuchsia {
  composes: strip-with-gradient;
  .pools-gradient('fuchsia', 90deg);
}

.strip-red {
  composes: strip-with-gradient;
  .pools-gradient('red', 90deg);
}

.strip-teal {
  composes: strip-with-gradient;
  .pools-gradient('teal', 90deg);
}

.strip-gray {
  composes: strip-with-gradient;
  .pools-gradient('gray', 90deg);
}

.strip-green {
  composes: strip-with-gradient;
  .pools-gradient('green', 90deg);
}

.strip-sky {
  composes: strip-with-gradient;
  .pools-gradient('sky', 90deg);
}

.strip-pink {
  composes: strip-with-gradient;
  .pools-gradient('pink', 90deg);
}

.strip-orange {
  composes: strip-with-gradient;
  .pools-gradient('orange', 90deg);
}

.alternative-image {
  visibility: hidden;
}
