@-ms-viewport {
  width: device-width;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 699px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 699px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 699px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 699px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 700px) and (max-width: 899px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 700px) and (max-width: 899px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 700px) and (max-width: 899px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 700px) and (max-width: 899px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 900px) and (max-width: 750px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 900px) and (max-width: 750px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 900px) and (max-width: 750px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 900px) and (max-width: 750px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1160px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1160px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1160px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1160px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 699px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 700px) and (max-width: 899px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 900px) and (max-width: 750px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1160px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
.container {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 700px) {
  .container {
    width: 744px;
  }
}
@media (min-width: 900px) {
  .container {
    width: 964px;
  }
}
@media (min-width: 1160px) {
  .container {
    width: 1164px;
  }
}
.container-fluid {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  margin-right: -12px;
  margin-left: -12px;
}
.row-no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.row-no-gutters [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
}
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 700px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 900px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1160px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}
/**
 * @fileOverview Contains type treatment for PayPal's new VX Patterns
 * @name type-vxPtrn
 * @author jlowery
 * @notes The below styles are mobile first
 */
body {
  font-size: inherit !important;
  font-family: 'PayPal-Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}
.vx_text-xl {
  font-size: 3.75rem;
  line-height: 1.1;
  font-weight: 300;
  text-transform: none;
  font-family: 'PayPal-Sans-Big', sans-serif;
}
h1,
.vx_text-1 {
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 300;
  text-transform: none;
  font-family: 'PayPal-Sans-Big', sans-serif;
}
h2,
.vx_text-2 {
  font-size: 1.875rem;
  line-height: 1.4;
  font-weight: 300;
  text-transform: none;
  font-family: 'PayPal-Sans-Big', sans-serif;
}
h3,
.vx_text-3 {
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans-Big', sans-serif;
}
.vx_text-3_lighter {
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 300;
  text-transform: none;
  font-family: 'PayPal-Sans-Big', sans-serif;
}
h4,
.vx_text-4 {
  font-size: 1.125rem;
  line-height: 1.33333;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
h5,
.vx_text-5 {
  font-size: 0.9375rem;
  line-height: 1.6;
  font-weight: 500;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_text-5_lighter {
  font-size: 0.9375rem;
  line-height: 1.6;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
h6,
.vx_text-6 {
  font-size: 0.8125rem;
  line-height: 1.84615385;
  font-weight: 500;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_text-6_lighter {
  font-size: 0.8125rem;
  line-height: 1.84615385;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_text-body,
p,
li,
dd,
dt,
label,
input,
textarea,
pre,
code {
  font-size: 0.9375rem;
  line-height: 1.6;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_text-body_secondary {
  font-size: 0.8125rem;
  line-height: 1.84615385;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_text-legal {
  font-size: 0.8125rem;
  line-height: 1.38461538;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
  color: #6C7378;
}
.vx_h1,
.vx_h2,
.vx_h3,
.vx_h4 {
  -webkit-font-smoothing: antialiased;
}
.vx_h1 {
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 300;
  text-transform: none;
  font-family: 'PayPal-Sans-Big', sans-serif;
}
.vx_h2 {
  /* Yes, the below H3 vars are intended
	 * this is the mobile sizing, so it's this first.
	 * Desktop is below.
	 */
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 300;
  text-transform: none;
  font-family: 'PayPal-Sans-Big', sans-serif;
  /* Mobile First, aka Desktop Later */
}
@media (min-width: 768px) {
  .vx_h2 {
    font-size: 1.875rem;
    line-height: 1.4;
    font-weight: 300;
    text-transform: none;
    font-family: 'PayPal-Sans-Big', sans-serif;
  }
}
.vx_h3 {
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans-Big', sans-serif;
}
.vx_h4 {
  font-size: 1.125rem;
  line-height: 1.33333;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_h5 {
  font-size: 0.9375rem;
  line-height: 1.6;
  font-weight: 500;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_h6 {
  font-size: 0.8125rem;
  line-height: 1.84615385;
  font-weight: 500;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_h7 {
  font-size: 0.8125rem;
  line-height: 1.84615385;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_base-text {
  font-size: 0.9375rem;
  line-height: 1.6;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_small-text {
  font-size: 0.8125rem;
  line-height: 1.84615385;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_legal-text {
  font-size: 0.8125rem;
  line-height: 1.38461538;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
  color: #6C7378;
}
.vx_secondary-text {
  color: #9DA3A6;
}
.vx_text-block {
  padding: 1.6em 1.6em 0.8em;
}
.vx_reverse-contrast {
  background: #2c2e2f;
  color: #FFFFFF;
}
.vx_reverse-contrast .vx_secondary-text {
  color: #CBD2D6;
}
.vx_reverse-contrast .small-text {
  font-weight: 500;
}
ul,
ol {
  padding: 0;
  margin: 0 0 0.8rem 25px;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}
li {
  line-height: 1.6;
}
dl {
  margin-bottom: 1.6rem;
}
dt,
dd {
  line-height: 1.6;
}
dt {
  font-weight: 500;
}
dd {
  margin-left: 0.8rem;
}
.vx_unstyled-list {
  list-style: none;
  padding: 0;
}
a,
a:visited {
  color: #0070BA;
  text-decoration: none;
  font-weight: 500;
  font-family: 'PayPal-Sans', sans-serif;
}
a:active,
a:focus,
a:hover {
  color: #005EA6;
  text-decoration: underline;
}
p > a,
li > a {
  font-weight: 500;
  font-family: 'PayPal-Sans', sans-serif;
}
/**
 * DO NOT EDIT THIS FILE AS IT'S GENERATED CODE.
 * UED: cgrigson, adustin, nvandewater
 * UIE: jlowery
 * Note: If modifications are needed, don't modify this file! Either override or make a PR to:
 * https://github.paypal.com/jlowery/paypal-vxpattern-lib
*/
/* Icons */
/* Z-index list */
/* user for a bird's eye view of components based on z-axis */
/* for example, if you want to put a button in header, just assign z-index of that button to @zindexHeader */
/* these will overwrite the bootstrap pre defined variables */
/* TODO: check the clean the non-needed modal styles in bootstrap */
/* the same as bootstrap one, just overwrite */
/* Setting for Sidepanel */
/* This is not used by all side panels, only for the default one */
/* what about larger phones like gs3? what about phablets like the note?
/* responsive breakpoints */
/* Navigation */
/* Spinner */
/**
 * THIS ICONS COMPONENT IS BEING DEPRECATED. USE THE ICONOGRAPHY COMPONENT INSTEAD.
 * DO NOT EDIT THIS FILE AS IT'S GENERATED CODE.
 * UED: cgrigson, adustin, nvandewater
 * UIE: jlowery
 * Note: If modifications are needed, don't modify this file! Either override or make a PR to:
 * https://github.paypal.com/jlowery/paypal-vxpattern-lib
*/
@font-face {
  font-family: 'paypal-vx-icons';
  src: url('https://www.paypalobjects.com/ui-web/vx-icons/2-0-1/PayPalVXIcons-Regular.eot');
  /* IE9 Compat Modes */
  src: url('https://www.paypalobjects.com/ui-web/vx-icons/2-0-1/PayPalVXIcons-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://www.paypalobjects.com/ui-web/vx-icons/2-0-1/PayPalVXIcons-Regular.woff') format('woff'), /* Modern Browsers */ url('https://www.paypalobjects.com/ui-web/vx-icons/2-0-1/PayPalVXIcons-Regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://www.paypalobjects.com/ui-web/vx-icons/2-0-1/PayPalVXIcons-Regular.svg#69ac2c9fc1e0803e59e06e93859bed03') format('svg');
  /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
}
.icon {
  font-family: 'paypal-vx-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 0;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-small:before {
  font-size: 1.58rem;
}
.icon-medium:before {
  font-size: 2.6rem;
}
.icon-large:before {
  font-size: 7.8rem;
}
.icon-slider-small:before {
  content: "\205D";
}
.icon-slider-half-small:before {
  content: "\205E";
}
.icon-close-small:before {
  content: "\274E";
}
.icon-add-small:before {
  content: "+";
}
.icon-arrow-down-small:before {
  content: "\2193";
}
.icon-arrow-up-small:before {
  content: "\2191";
}
.icon-arrow-left-small:before {
  content: "\2190";
}
.icon-arrow-right-small:before {
  content: "\2192";
}
.icon-arrow-down-half-small:before {
  content: "\21E3";
}
.icon-arrow-up-half-small:before {
  content: "\21E1";
}
.icon-arrow-left-half-small:before {
  content: "\21E0";
}
.icon-arrow-right-half-small:before {
  content: "\21E2";
}
.icon-arrow-back-small:before {
  content: "\21E6";
}
.icon-clear-half-small:before {
  content: "\2716";
}
.icon-checkmark-small:before {
  content: "\2705";
}
.icon-checkmark-small-bold:before {
  content: "\2611";
}
.icon-circled-checkmark-small:before {
  content: "\2714";
}
.icon-circled-checkmark-large:before {
  content: "\2713";
}
.icon-action-arrow-half-small:before {
  content: "\25BA";
}
.icon-action-arrow-right-half-small:before {
  content: "\25BA";
}
.icon-action-arrow-down-half-small:before {
  content: "\25BC";
}
.icon-action-arrow-up-half-small:before {
  content: "\25B2";
}
.icon-tooltip-small:before {
  content: "\2753";
}
.icon-info-small:before {
  content: "\2139";
}
.icon-positive-small:before {
  content: "+";
}
.icon-attention-small:before {
  content: "\2755";
}
.icon-critical-small:before {
  content: "\26A0";
}
.icon-info-large:before {
  content: "\24D8";
}
.icon-positive-large:before {
  content: "+";
}
.icon-attention-large:before {
  content: "\2762";
}
.icon-critical-large:before {
  content: "\2757";
}
.icon-pp-logo:before {
  content: "\E498";
}
.icon-pp-logo-half:before {
  content: "\E499";
}
.icon-gear:before {
  content: "\2699";
}
.icon-magnifying-glass:before {
  content: "\1F50D";
}
.icon-bell:before {
  content: "\1F514";
}
.icon-chain-link:before {
  content: "\1F517";
}
.icon-calendar:before {
  content: "\1F4C5";
}
.icon-camera-add:before {
  content: "\1F4F7";
}
.icon-avatar:before {
  content: "\1F471";
}
/*
* @name buttons
* @description All defined button styles and states belong here, including any helper class button style options, like `vx_btn-secondary` or `vx_btn-medium` etc.
*
* @group elements
*/
.vx_btn,
.vx_btn-small,
.vx_btn-medium {
  display: inline-block;
  min-width: 6rem;
  padding: 0.75rem 1.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid #0070BA;
  border-radius: 1.5rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  font-family: 'PayPal-Sans', sans-serif;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  background-color: #0070BA;
  transition: all 250ms ease;
  -webkit-font-smoothing: antialiased;
}
.vx_btn:visited,
.vx_btn-small:visited,
.vx_btn-medium:visited,
.vx_btn:active,
.vx_btn-small:active,
.vx_btn-medium:active,
.vx_btn:hover,
.vx_btn-small:hover,
.vx_btn-medium:hover,
.vx_btn:focus,
.vx_btn-small:focus,
.vx_btn-medium:focus {
  color: #fff;
}
.vx_btn:visited,
.vx_btn-small:visited,
.vx_btn-medium:visited {
  text-decoration: none;
}
.vx_btn:hover,
.vx_btn-small:hover,
.vx_btn-medium:hover,
.vx_btn:focus,
.vx_btn-small:focus,
.vx_btn-medium:focus {
  background-color: #003087;
  border: 1px solid #003087;
  box-shadow: none;
  text-decoration: none;
}
.vx_btn:focus,
.vx_btn-small:focus,
.vx_btn-medium:focus {
  outline: none;
  text-decoration: underline;
}
.vx_btn[disabled],
.vx_btn-small[disabled],
.vx_btn-medium[disabled],
.vx_btn[disabled]:hover,
.vx_btn-small[disabled]:hover,
.vx_btn-medium[disabled]:hover,
.vx_btn[disabled]:active,
.vx_btn-small[disabled]:active,
.vx_btn-medium[disabled]:active,
.vx_btn.vx_is-disabled,
.vx_btn-small.vx_is-disabled,
.vx_btn-medium.vx_is-disabled,
.vx_btn.vx_is-disabled:hover,
.vx_btn-small.vx_is-disabled:hover,
.vx_btn-medium.vx_is-disabled:hover,
.vx_btn.vx_is-disabled:active,
.vx_btn-small.vx_is-disabled:active,
.vx_btn-medium.vx_is-disabled:active {
  color: #CBD2D6;
  background: #F5F6F8;
  box-shadow: none;
  border: 1px solid #CBD2D6;
  text-decoration: none;
  cursor: not-allowed;
  outline: none;
  transform: none;
}
.vx_btn + .vx_btn,
.vx_btn + .vx_btn-small,
.vx_btn + .vx_btn-medium,
.vx_btn-small + .vx_btn,
.vx_btn-small + .vx_btn-small,
.vx_btn-small + .vx_btn-medium,
.vx_btn-medium + .vx_btn,
.vx_btn-medium + .vx_btn-small,
.vx_btn-medium + .vx_btn-medium {
  margin-left: 0.75rem;
}
.vx_btn.vx_btn-block,
.vx_btn-small.vx_btn-block,
.vx_btn-medium.vx_btn-block {
  margin-right: 0;
  margin-left: 0;
  width: 100%;
}
.vx_btn-secondary {
  background-color: transparent;
  border-color: #0070BA;
  color: #0070BA;
}
.vx_btn-secondary:hover,
.vx_btn-secondary:focus {
  color: #003087;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: #003087;
  box-shadow: 0 0 0 1px #003087 inset;
}
.vx_btn-secondary:visited {
  text-decoration: none;
  color: #0070BA;
}
.vx_btn_reversed {
  border-color: #fff;
  background-color: #fff;
  color: #0070BA;
}
.vx_btn_reversed:hover,
.vx_btn_reversed:focus {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #003087;
}
.vx_btn_reversed:visited {
  color: #0070BA;
}
.vx_btn_reversed:visited:hover {
  color: #003087;
}
.vx_btn-secondary_reversed {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}
.vx_btn-secondary_reversed:hover,
.vx_btn-secondary_reversed:focus {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
  border-color: #fff;
  box-shadow: 0 0 0 1px #fff inset;
}
.vx_btn-medium {
  font-size: 0.8125rem;
  line-height: 1.38461538;
  min-width: 4.5rem;
  padding: 0.5625rem 1.125rem;
}
.vx_btn-small {
  font-size: 0.8125rem;
  line-height: 1.36363636;
  min-width: 3.75rem;
  padding: 0.46875rem 0.9375rem;
}
.vx_btn-icon {
  display: inline-block;
  width: 1.5rem;
  position: relative;
  margin-right: 0.625rem;
}
.vx_btn-icon svg {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: -1.125rem;
  left: 0;
}
.vx_btn-icon .svg-stroke {
  stroke: #fff;
}
.vx_btn-icon .svg-fill {
  fill: #fff;
}
.vx_btn-secondary .vx_btn-icon .svg-stroke {
  stroke: #0070BA;
}
.vx_btn-secondary .vx_btn-icon .svg-fill {
  fill: #0070BA;
}
.vx_btn-secondary:hover .vx_btn-icon .svg-stroke,
.vx_btn-secondary:focus .vx_btn-icon .svg-stroke {
  stroke: #003087;
}
.vx_btn-secondary:hover .vx_btn-icon .svg-fill,
.vx_btn-secondary:focus .vx_btn-icon .svg-fill {
  fill: #003087;
}
[disabled] .vx_btn-icon .svg-stroke,
[disabled]:hover .vx_btn-icon .svg-stroke,
[disabled]:active .vx_btn-icon .svg-stroke,
.vx_is-disabled .vx_btn-icon .svg-stroke,
.vx_is-disabled:hover .vx_btn-icon .svg-stroke,
.vx_is-disabled:active .vx_btn-icon .svg-stroke {
  stroke: #CBD2D6;
}
[disabled] .vx_btn-icon .svg-fill,
[disabled]:hover .vx_btn-icon .svg-fill,
[disabled]:active .vx_btn-icon .svg-fill,
.vx_is-disabled .vx_btn-icon .svg-fill,
.vx_is-disabled:hover .vx_btn-icon .svg-fill,
.vx_is-disabled:active .vx_btn-icon .svg-fill {
  fill: #CBD2D6;
}
.vx_btn-medium .vx_btn-icon {
  width: 1.125rem;
  margin-right: 0.5rem;
}
.vx_btn-medium .vx_btn-icon svg {
  width: 1.125rem;
  height: 1.125rem;
  top: -0.8125rem;
}
.vx_btn-small .vx_btn-icon {
  width: 1.10795454rem;
  margin-right: 0.375rem;
}
.vx_btn-small .vx_btn-icon svg {
  width: 1.10795454rem;
  height: 1.10795454rem;
  top: -0.75rem;
}
.vx_btn-group,
.vx_btn-group_stacked {
  text-align: center;
}
.vx_btn-group_stacked .vx_btn,
.vx_btn-group_stacked .vx_btn-small,
.vx_btn-group_stacked .vx_btn-medium {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
.vx_addBtn-primary,
.vx_addBtn-secondary {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  background: none;
  border: none;
  color: #0070BA;
  padding-left: 0.125rem;
}
.vx_addBtn-primary:before,
.vx_addBtn-secondary:before {
  content: "+";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'paypal-vx-icons';
  line-height: 0;
  font-size: 1.25rem;
}
.vx_addBtn-primary:hover,
.vx_addBtn-secondary:hover {
  color: #005EA6;
  text-decoration: none;
}
.vx_addBtn-primary:before {
  color: #FFFFFF;
  background: #0070BA;
  border-radius: 100%;
  padding: 0 5px 3px;
  box-shadow: 0 1px 2px #2C2E2F;
  margin-right: 12px;
}
.vx_addBtn-secondary:before {
  margin-right: 5px;
}
.vx_backBtn {
  background: none;
  border: none;
  color: #0070BA;
  font-weight: normal;
  padding: 0;
}
.vx_backBtn:focus,
.vx_backBtn:active,
.vx_backBtn:hover {
  color: #005EA6;
  text-decoration: none;
}
.vx_backBtn:focus span,
.vx_backBtn:active span,
.vx_backBtn:hover span {
  text-decoration: underline;
}
.vx_backBtn:before {
  font-family: 'paypal-vx-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\2190';
  display: inline;
  font-size: 1.5em;
  vertical-align: -0.1em;
  padding-right: 0.1em;
}
.vx_btn-twoUp .vx_btn {
  display: inline-block;
  width: 48%;
  margin-left: 0;
  margin-right: 0;
}
.vx_btn-twoUp .vx_btn:first-of-type {
  margin-right: 1.5%;
}
.vx_btn-twoUp .vx_btn:last-of-type {
  margin-left: 1.5%;
}
@media (max-width: 768px) {
  .vx_blocks-for-mobile .vx_btn {
    display: block;
    width: 100%;
  }
  .vx_blocks-for-mobile .vx_btn + .vx_btn {
    margin: 5px 0 0;
  }
}
.vx_btn-link {
  background: none;
  border: none;
  color: #0070BA;
  font-weight: 500;
  padding: 0;
}
.vx_btn-link:hover {
  color: #005EA6;
  text-decoration: underline;
}
.vx_tag,
.vx_tag-pending,
.vx_tag-warning,
.vx_tag-critical,
.vx_tag-success {
  display: inline-block;
  height: 0.8125rem;
  line-height: 0.625rem;
  padding: 0 0.1875rem 0.0625rem 0.1875rem;
  border-radius: 3px;
  font-size: 0.625rem;
  font-weight: 500;
  text-transform: uppercase;
  border: 1px solid #2c2e2f;
  color: #2c2e2f;
}
.vx_tag-pending {
  border: 1px solid #640487;
  color: #640487;
}
.vx_tag-warning {
  border: 1px solid #D64003;
  color: #D64003;
}
.vx_tag-critical {
  border: 1px solid #D20000;
  color: #D20000;
}
.vx_tag-success {
  border: 1px solid #018065;
  color: #018065;
}
.vx_spinner-small,
.vx_has-spinner-small:after {
  height: 18px;
  opacity: 0.56;
  width: 18px;
  animation: vx_spin 620ms infinite linear;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'100%25'%20height%3D'100%25'%20viewBox%3D'0%200%2038%2038'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%0A%09%3Cdefs%3E%0A%09%09%3ClinearGradient%20id%3D'gradient'%20x1%3D'0'%20y1%3D'0'%20x2%3D'1'%20y2%3D'0'%3E%0A%09%09%09%3Cstop%20offset%3D'0'%20stop-color%3D'%23000000'%20stop-opacity%3D'0'%2F%3E%0A%09%09%09%3Cstop%20offset%3D'1'%20stop-color%3D'%23000000'%20stop-opacity%3D'1'%2F%3E%0A%09%09%3C%2FlinearGradient%3E%0A%09%3C%2Fdefs%3E%0A%09%3Cpath%20stroke%3D'url(%23gradient)'%20vector-effect%3D'non-scaling-stroke'%20stroke-width%3D'1px'%20fill%3D'none'%20fill-rule%3D'evenodd'%20d%3D'M2%2C19a17%2C17%200%201%2C1%2034%2C0'%3E%3C%2Fpath%3E%0A%09%3Cpath%20stroke%3D'%23000000'%20vector-effect%3D'non-scaling-stroke'%20stroke-width%3D'1px'%20fill%3D'none'%20fill-rule%3D'evenodd'%20d%3D'M2%2C19a17%2C17%200%201%2C0%2034%2C0'%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A");
  content: '';
  display: block;
}
.vx_spinner-medium,
.vx_has-spinner-medium:after {
  height: 24px;
  opacity: 0.56;
  width: 24px;
  animation: vx_spin 620ms infinite linear;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'100%25'%20height%3D'100%25'%20viewBox%3D'0%200%2038%2038'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%0A%09%3Cdefs%3E%0A%09%09%3ClinearGradient%20id%3D'gradient'%20x1%3D'0'%20y1%3D'0'%20x2%3D'1'%20y2%3D'0'%3E%0A%09%09%09%3Cstop%20offset%3D'0'%20stop-color%3D'%23000000'%20stop-opacity%3D'0'%2F%3E%0A%09%09%09%3Cstop%20offset%3D'1'%20stop-color%3D'%23000000'%20stop-opacity%3D'1'%2F%3E%0A%09%09%3C%2FlinearGradient%3E%0A%09%3C%2Fdefs%3E%0A%09%3Cpath%20stroke%3D'url(%23gradient)'%20vector-effect%3D'non-scaling-stroke'%20stroke-width%3D'2px'%20fill%3D'none'%20fill-rule%3D'evenodd'%20d%3D'M2%2C19a17%2C17%200%201%2C1%2034%2C0'%3E%3C%2Fpath%3E%0A%09%3Cpath%20stroke%3D'%23000000'%20vector-effect%3D'non-scaling-stroke'%20stroke-width%3D'2px'%20fill%3D'none'%20fill-rule%3D'evenodd'%20d%3D'M2%2C19a17%2C17%200%201%2C0%2034%2C0'%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A");
  content: '';
  display: block;
}
.vx_spinner-large,
.vx_has-spinner-large:after {
  height: 128px;
  opacity: 0.56;
  width: 128px;
  animation: vx_spin 620ms infinite linear;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'100%25'%20height%3D'100%25'%20viewBox%3D'0%200%2038%2038'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%0A%09%3Cdefs%3E%0A%09%09%3ClinearGradient%20id%3D'gradient'%20x1%3D'0'%20y1%3D'0'%20x2%3D'1'%20y2%3D'0'%3E%0A%09%09%09%3Cstop%20offset%3D'0'%20stop-color%3D'%2300CF92'%20stop-opacity%3D'0'%2F%3E%0A%09%09%09%3Cstop%20offset%3D'1'%20stop-color%3D'%2300CF92'%20stop-opacity%3D'1'%2F%3E%0A%09%09%3C%2FlinearGradient%3E%0A%09%3C%2Fdefs%3E%0A%09%3Cpath%20stroke%3D'url(%23gradient)'%20vector-effect%3D'non-scaling-stroke'%20stroke-width%3D'2px'%20fill%3D'none'%20fill-rule%3D'evenodd'%20d%3D'M2%2C19a17%2C17%200%201%2C1%2034%2C0'%3E%3C%2Fpath%3E%0A%09%3Cpath%20stroke%3D'%2300CF92'%20vector-effect%3D'non-scaling-stroke'%20stroke-width%3D'2px'%20fill%3D'none'%20fill-rule%3D'evenodd'%20d%3D'M2%2C19a17%2C17%200%201%2C0%2034%2C0'%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A");
  content: '';
  display: block;
}
.vx_has-spinner-small,
.vx_has-spinner-medium,
.vx_has-spinner-large {
  position: relative;
}
.vx_has-spinner-small:before,
.vx_has-spinner-medium:before,
.vx_has-spinner-large:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1000;
  animation: vx_fade-in 0.3s ease-out 0s both;
}
.vx_has-spinner-small:after,
.vx_has-spinner-medium:after,
.vx_has-spinner-large:after {
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1001;
}
@keyframes vx_spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes vx_fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes vx_fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* Styles for checkbox widget */
.vx_checkbox,
.vx_radio {
  width: 100%;
  position: relative;
}
.vx_checkbox input,
.vx_radio input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}
.vx_checkbox input:focus + label:before,
.vx_radio input:focus + label:before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.vx_checkbox input:checked + label:before,
.vx_radio input:checked + label:before {
  content: "\2611";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-color: #0092d1;
  color: #FFFFFF;
  background-color: #0092d1;
}
.vx_checkbox label,
.vx_radio label {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  line-height: 2em;
}
.vx_checkbox label:before,
.vx_radio label:before {
  font-family: 'paypal-vx-icons';
  display: inline-block;
  vertical-align: top;
  height: 1.2em;
  width: 1.2em;
  margin: 0 12px 0 0;
  padding: 0.4em 0.1em;
  border: 1px solid #2c2e2f;
  content: '';
  font-size: 25px;
  line-height: 0;
  border-radius: 2px;
  overflow: hidden;
}
.vx_checkbox.vx_has-error input:checked + label:before,
.vx_radio.vx_has-error input:checked + label:before {
  border-color: #D20000;
  background: #D20000;
}
.vx_checkbox.vx_has-error label:before,
.vx_radio.vx_has-error label:before {
  border-color: #D20000;
}
.vx_checkbox.vx_has-error:after,
.vx_radio.vx_has-error:after {
  font-family: 'paypal-vx-icons';
  display: inline-block;
  vertical-align: middle;
  content: "\26A0";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.75em;
  line-height: 0;
  position: relative;
  top: -3px;
  padding-left: 5px;
}
.vx_checkbox.vx_is-disabled,
.vx_radio.vx_is-disabled {
  cursor: not-allowed;
}
.vx_checkbox.vx_is-disabled input:checked + label:before,
.vx_radio.vx_is-disabled input:checked + label:before {
  border-color: #6C7378;
  background: #6C7378;
}
.vx_checkbox.vx_is-disabled label:before,
.vx_radio.vx_is-disabled label:before {
  border-color: #6C7378;
  cursor: not-allowed;
}
.vx_checkbox.vx_is-disabled label,
.vx_radio.vx_is-disabled label {
  color: #6C7378;
  cursor: not-allowed;
}
.vx_radio input:checked + label:before {
  content: '';
  border-color: #0092d1;
  background-color: #0092d1;
  box-shadow: inset 0 0 0 5px #FFFFFF;
}
.vx_radio label:before {
  border-radius: 100%;
}
/* Container used for styling the custom select */
.vx_select {
  position: relative;
  display: block;
}
/* This is the native select, we're making everything but the text invisible so
* we can see the button styles in the wrapper */
.vx_select select {
  width: 100%;
  margin: 0;
}
.vx_select select:focus {
  outline: none;
}
/* Custom arrow sits on top of the select - could be an image, SVG, icon font,
* etc. or the arrow could just baked into the bg image on the select. */
.vx_select:after {
  font-family: 'paypal-vx-icons';
  speak: none;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 0;
  /* Better Font Rendering =========== */
  content: "\21E3";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 12.5px;
  z-index: 2;
  /* These hacks make the select behind the arrow clickable in some browsers */
  pointer-events: none;
  display: none;
}
@supports ((-webkit-appearance: none) or (-moz-appearance: none) or (appearance: none))
	
	or ( ( -moz-appearance: none ) and ( mask-type: alpha ) ) {
  /* Show custom arrow */
  .vx_select:after {
    display: block;
  }
  /* Remove select styling */
  .vx_select select {
    padding-right: 2.75rem;
    /* inside @supports so that iOS <= 8 display the native arrow */
    background: #FFFFFF;
    /* inside @supports so that Android <= 4.3 display the native arrow */
    border: 1px solid #9DA3A6;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .vx_select select:focus {
    border-color: #009CDE;
  }
  .vx_has-error .vx_selectIcon_error:before {
    right: 2.2rem;
  }
  .vx_has-error select {
    padding-right: 4.5rem;
  }
  /*********************************
	*       FLOATING LABEL          *
	*********************************/
  .vx_floatingLabel select {
    position: relative;
    z-index: 1;
    height: 50px;
    padding: 18px 15px 0;
    background: transparent;
    color: #2C2E2F;
  }
  .vx_floatingLabel .vx_select:before {
    position: absolute;
    top: -10px;
    left: 0;
    font-size: 12px;
    font-weight: 500;
    content: attr(data-label-content);
    padding: 0 15px;
    line-height: 50px;
    margin: 1px;
    z-index: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    color: #6C7378;
  }
  .vx_floatingLabel.vx_has-error .vx_select:before {
    color: #D20000;
  }
  .vx_floatingLabel.hasFocus .vx_select:before,
  .vx_floatingLabel.hasFocus .vx_select:after {
    color: #0070BA;
  }
}
/**
 * Hacks to adjust alignment for Firefox and Edge
 */
@supports ( ( -moz-appearance: none ) and ( mask-type: alpha ) )
	or (-ms-ime-align: auto) {
  .vx_floatingLabel select {
    padding-left: 11px;
  }
}
/**
 * DO NOT EDIT THIS FILE AS IT'S GENERATED CODE.
 * UED: cgrigson, adustin, nvandewater
 * UIE: jlowery
 * Note: If modifications are needed, don't modify this file! Either override or make a PR to:
 * https://github.paypal.com/jlowery/paypal-vxpattern-lib
*/
.vx_icon {
  font-family: 'paypal-vx-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 0;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.vx_icon-small:before {
  font-size: 1.58rem;
}
.vx_icon-medium:before {
  font-size: 2.6rem;
}
.vx_icon-large:before {
  font-size: 7.8rem;
}
.vx_icon-slider-small:before {
  content: "\205D";
}
.vx_icon-slider-half-small:before {
  content: "\205E";
}
.vx_icon-close-small:before {
  content: "\274E";
}
.vx_icon-add-small:before {
  content: "+";
}
.vx_icon-arrow-down-small:before {
  content: "\2193";
}
.vx_icon-arrow-up-small:before {
  content: "\2191";
}
.vx_icon-arrow-left-small:before {
  content: "\2190";
}
.vx_icon-arrow-right-small:before {
  content: "\2192";
}
.vx_icon-arrow-down-half-small:before {
  content: "\21E3";
}
.vx_icon-arrow-up-half-small:before {
  content: "\21E1";
}
.vx_icon-arrow-left-half-small:before {
  content: "\21E0";
}
.vx_icon-arrow-right-half-small:before {
  content: "\21E2";
}
.vx_icon-arrow-back-small:before {
  content: "\21E6";
}
.vx_icon-clear-half-small:before {
  content: "\2716";
}
.vx_icon-checkmark-small:before {
  content: "\2705";
}
.vx_icon-checkmark-small-bold:before {
  content: "\2611";
}
.vx_icon-circled-checkmark-small:before {
  content: "\2714";
}
.vx_icon-circled-checkmark-large:before {
  content: "\2713";
}
.vx_icon-action-arrow-half-small:before {
  content: "\25BA";
}
.vx_icon-action-arrow-right-half-small:before {
  content: "\25BA";
}
.vx_icon-action-arrow-down-half-small:before {
  content: "\25BC";
}
.vx_icon-action-arrow-up-half-small:before {
  content: "\25B2";
}
.vx_icon-tooltip-small:before {
  content: "\2753";
}
.vx_icon-info-small:before {
  content: "\2139";
}
.vx_icon-positive-small:before {
  content: "+";
}
.vx_icon-attention-small:before {
  content: "\2755";
}
.vx_icon-critical-small:before {
  content: "\26A0";
}
.vx_icon-info-large:before {
  content: "\24D8";
}
.vx_icon-positive-large:before {
  content: "+";
}
.vx_icon-attention-large:before {
  content: "\2762";
}
.vx_icon-critical-large:before {
  content: "\2757";
}
.vx_icon-pp-logo:before {
  content: "\E498";
}
.vx_icon-pp-logo-half:before {
  content: "\E499";
}
.vx_icon-gear:before {
  content: "\2699";
}
.vx_icon-magnifying-glass:before {
  content: "\1F50D";
}
.vx_icon-bell:before {
  content: "\1F514";
}
.vx_icon-chain-link:before {
  content: "\1F517";
}
.vx_icon-calendar:before {
  content: "\1F4C5";
}
.vx_icon-camera-add:before {
  content: "\1F4F7";
}
.vx_icon-avatar:before {
  content: "\1F471";
}
/* Mixin for placeholder */
.vx_form-control {
  width: 100%;
  height: 50px;
  padding: 12.5px;
  border: 1px solid #9DA3A6;
  border-radius: 5px;
  background: #FFFFFF;
  transition: border-color 0.15s;
}
.vx_form-control:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.vx_form-control:hover,
.vx_form-control:focus,
.vx_form-control:active,
.vx_form-control.vx_is-active {
  border-color: #009CDE;
}
.vx_form-control[disabled],
.vx_form-control[disabled]:hover,
.vx_form-control[disabled]:active,
.vx_form-control.vx_is-disabled,
.vx_form-control.vx_is-disabled:hover,
.vx_form-control.vx_is-disabled:active {
  border-color: #CBD2D6;
  background: #F5F7FA;
  color: #6C7378;
  cursor: default;
}
.vx_form-group,
.vx_form-group_hiddenLabel {
  padding-top: 1em;
  text-align: left;
}
.vx_form-group label,
.vx_form-group_hiddenLabel label {
  font-weight: 500;
}
.vx_form-group_hiddenLabel label,
.vx_form-group-nolabel label {
  position: absolute;
  left: -999em;
}
input.vx_has-error {
  background: #FFFFFF;
  border-color: #D20000;
}
.vx_has-error,
.vx_has-error input,
.vx_has-error label,
input.vx_has-error,
label.vx_has-error {
  color: #D20000;
}
.vx_has-error input,
.vx_has-error select,
.vx_has-error input:hover,
.vx_has-error input:active,
input:focus input.vx_has-error,
input.vx_has-error:hover,
input.vx_has-error:active,
input.vx_has-error:focus {
  background: #FFFFFF;
  border-color: #D20000;
}
.vx_has-error .vx_form-control_complex {
  border-color: #D20000;
}
.vx_has-error .vx_form-control-icon {
  color: #D20000;
}
.vx_has-error .vx_selectIcon_error {
  position: relative;
  display: block;
  text-align: right;
  height: 0;
  font-family: 'paypal-vx-icons';
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  line-height: 0;
  -webkit-font-smoothing: antialiased;
}
.vx_has-error .vx_selectIcon_error:before {
  content: "\26A0";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.58rem;
  position: absolute;
  right: 1.25em;
  top: -1.25em;
}
.vx_selectIcon_error {
  display: none;
}
.vx_form-control_complex {
  padding: 0;
  display: table;
  table-layout: fixed;
  height: auto;
}
.vx_form-control_complex input {
  background: none;
  border: none;
  display: table-cell;
  width: 100%;
  padding: 12px;
  height: 48px;
}
.vx_form-control_complex input:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.vx_form-control_complex input:hover {
  background: none;
}
.vx_form-control-icon,
.vx_form-control-error-icon {
  display: table-cell;
  width: 2.5em;
  min-width: 2.5em;
  color: #9DA3A6;
  text-align: right;
  vertical-align: middle;
  position: relative;
  top: -2px;
}
.vx_form-control-error-icon {
  width: 1px;
  min-width: 1px;
  visibility: hidden;
}
.vx_form-control-icon + input {
  padding-left: 0.5em;
}
label.vx_has-error-with-message,
input.vx_has-error-with-message,
.vx_form-control_complex.vx_has-error-with-message {
  color: #D20000;
}
input.vx_has-error-with-message,
.vx_form-control_complex.vx_has-error-with-message {
  background: #FFFFFF;
  border-color: #D20000;
  margin-bottom: 0 !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.vx_has-message > input,
.vx_has-error-with-message > input,
.vx_has-message > .vx_form-control_complex,
.vx_has-error-with-message > .vx_form-control_complex {
  margin-bottom: 0 !important;
  border: 1px solid #0092d1;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.vx_has-message.hasFocus:after,
.vx_has-error-with-message.hasFocus:after {
  color: #D20000;
}
.vx_has-error-with-message > label,
.vx_has-error-with-message > .vx_form-control_complex {
  color: #D20000;
}
.vx_has-error-with-message > input,
.vx_has-error-with-message > .vx_form-control_complex {
  background: #FFFFFF;
  border-color: #D20000;
  margin-bottom: 0 !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.vx_has-error-with-message .vx_form-control-message {
  background: #FEFAFA;
  border: 1px solid #D20000;
  border-top-width: 0;
}
.vx_has-error-with-message .vx_form-control-icon {
  color: #D20000;
}
.vx_has-error-with-message.hasFocus:after {
  color: #D20000;
}
.vx_has-error .vx_form-control_complex .vx_form-control-error-icon,
.vx_has-error-with-message .vx_form-control_complex .vx_form-control-error-icon {
  display: table-cell;
  visibility: visible;
  width: 2.25em;
  color: #D20000;
  text-align: left;
}
.vx_form-control-message {
  display: block;
  background: #FDFEFF;
  padding: 0.5em 1em;
  border: 1px solid #0092d1;
  border-top-width: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
/*********************************
 *       FLOATING LABEL          *
 *********************************/
.vx_floatingLabel,
.vx_floatingLabel_complex,
.vx_floatingLabel_active {
  position: relative;
  padding: 0;
  margin-top: 15px;
  background: white;
  border-radius: 5px;
  text-align: left;
}
.vx_floatingLabel.vx_floatingLabel_complex > .vx_form-control,
.vx_floatingLabel_complex.vx_floatingLabel_complex > .vx_form-control,
.vx_floatingLabel_active.vx_floatingLabel_complex > .vx_form-control {
  position: relative;
  display: table-cell;
  height: 48px;
}
.vx_floatingLabel.vx_floatingLabel_complex > .vx_form-control input,
.vx_floatingLabel_complex.vx_floatingLabel_complex > .vx_form-control input,
.vx_floatingLabel_active.vx_floatingLabel_complex > .vx_form-control input {
  height: 48px;
}
.vx_floatingLabel:before,
.vx_floatingLabel_complex:before,
.vx_floatingLabel_active:before,
.vx_floatingLabel:after,
.vx_floatingLabel_complex:after,
.vx_floatingLabel_active:after,
.vx_floatingLabel.vx_floatingLabel_complex > .vx_form-control:before,
.vx_floatingLabel_complex.vx_floatingLabel_complex > .vx_form-control:before,
.vx_floatingLabel_active.vx_floatingLabel_complex > .vx_form-control:before,
.vx_floatingLabel.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_complex.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_active.vx_floatingLabel_complex > .vx_form-control:after {
  content: attr(data-label-content);
  position: absolute;
  padding: 0 15px;
  line-height: 48px;
  margin: 1px;
  transition: opacity 0.25s ease-out;
  z-index: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  color: #6C7378;
}
.vx_floatingLabel.vx_floatingLabel > .vx_form-control:before,
.vx_floatingLabel_complex.vx_floatingLabel > .vx_form-control:before,
.vx_floatingLabel_active.vx_floatingLabel > .vx_form-control:before {
  line-height: 50px;
}
.vx_floatingLabel:after,
.vx_floatingLabel_complex:after,
.vx_floatingLabel_active:after,
.vx_floatingLabel.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_complex.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_active.vx_floatingLabel_complex > .vx_form-control:after {
  top: -10px;
  left: 0;
  font-size: 12px;
  color: #6C7378;
  font-weight: 500;
  transition: opacity 0.4s ease-in-out, top 0.3s ease-out;
}
.vx_floatingLabel.hasFocus:after,
.vx_floatingLabel_complex.hasFocus:after,
.vx_floatingLabel_active.hasFocus:after,
.vx_floatingLabel.hasFocus.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_complex.hasFocus.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_active.hasFocus.vx_floatingLabel_complex > .vx_form-control:after {
  color: #0070BA;
}
.vx_floatingLabel label,
.vx_floatingLabel_complex label,
.vx_floatingLabel_active label {
  position: absolute;
  left: -999em;
}
.vx_floatingLabel input,
.vx_floatingLabel_complex input,
.vx_floatingLabel_active input {
  position: relative;
  z-index: 2;
  padding: 18px 15px 0;
  background: transparent;
  color: #2C2E2F;
}
.vx_floatingLabel input.vx_form-control,
.vx_floatingLabel_complex input.vx_form-control,
.vx_floatingLabel_active input.vx_form-control {
  height: 50px;
}
.vx_floatingLabel .vx_form-control-icon,
.vx_floatingLabel_complex .vx_form-control-icon,
.vx_floatingLabel_active .vx_form-control-icon,
.vx_floatingLabel .vx_form-control-error-icon,
.vx_floatingLabel_complex .vx_form-control-error-icon,
.vx_floatingLabel_active .vx_form-control-error-icon {
  vertical-align: middle;
  position: relative;
  top: -2px;
}
.vx_floatingLabel .vx_form-control-error-icon,
.vx_floatingLabel_complex .vx_form-control-error-icon,
.vx_floatingLabel_active .vx_form-control-error-icon {
  top: -3px;
}
.vx_floatingLabel .vx_form-control-error-icon:before,
.vx_floatingLabel_complex .vx_form-control-error-icon:before,
.vx_floatingLabel_active .vx_form-control-error-icon:before {
  display: none;
}
.vx_floatingLabel .vx_form-control-icon,
.vx_floatingLabel_complex .vx_form-control-icon,
.vx_floatingLabel_active .vx_form-control-icon {
  vertical-align: middle;
}
.vx_floatingLabel.vx_has-error .vx_form-control-error-icon:before,
.vx_floatingLabel_complex.vx_has-error .vx_form-control-error-icon:before,
.vx_floatingLabel_active.vx_has-error .vx_form-control-error-icon:before,
.vx_floatingLabel.vx_has-error-with-message .vx_form-control-error-icon:before,
.vx_floatingLabel_complex.vx_has-error-with-message .vx_form-control-error-icon:before,
.vx_floatingLabel_active.vx_has-error-with-message .vx_form-control-error-icon:before {
  display: inline;
}
.vx_floatingLabel.vx_has-error,
.vx_floatingLabel_complex.vx_has-error,
.vx_floatingLabel_active.vx_has-error,
.vx_floatingLabel.vx_has-error-with-message,
.vx_floatingLabel_complex.vx_has-error-with-message,
.vx_floatingLabel_active.vx_has-error-with-message {
  border-color: #D20000;
}
.vx_floatingLabel.vx_has-error .vx_form-control,
.vx_floatingLabel_complex.vx_has-error .vx_form-control,
.vx_floatingLabel_active.vx_has-error .vx_form-control,
.vx_floatingLabel.vx_has-error-with-message .vx_form-control,
.vx_floatingLabel_complex.vx_has-error-with-message .vx_form-control,
.vx_floatingLabel_active.vx_has-error-with-message .vx_form-control {
  border-color: #D20000;
}
.vx_floatingLabel.vx_has-error .vx_form-control:hover,
.vx_floatingLabel_complex.vx_has-error .vx_form-control:hover,
.vx_floatingLabel_active.vx_has-error .vx_form-control:hover,
.vx_floatingLabel.vx_has-error-with-message .vx_form-control:hover,
.vx_floatingLabel_complex.vx_has-error-with-message .vx_form-control:hover,
.vx_floatingLabel_active.vx_has-error-with-message .vx_form-control:hover {
  background: transparent;
}
.vx_floatingLabel.vx_has-error.hasFocus:after,
.vx_floatingLabel_complex.vx_has-error.hasFocus:after,
.vx_floatingLabel_active.vx_has-error.hasFocus:after,
.vx_floatingLabel.vx_has-error-with-message.hasFocus:after,
.vx_floatingLabel_complex.vx_has-error-with-message.hasFocus:after,
.vx_floatingLabel_active.vx_has-error-with-message.hasFocus:after,
.vx_floatingLabel.vx_has-error.hasFocus.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_complex.vx_has-error.hasFocus.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_active.vx_has-error.hasFocus.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel.vx_has-error-with-message.hasFocus.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_complex.vx_has-error-with-message.hasFocus.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_active.vx_has-error-with-message.hasFocus.vx_floatingLabel_complex > .vx_form-control:after {
  color: #D20000;
}
.vx_floatingLabel.vx_has-error .vx_form-control-error-icon,
.vx_floatingLabel_complex.vx_has-error .vx_form-control-error-icon,
.vx_floatingLabel_active.vx_has-error .vx_form-control-error-icon,
.vx_floatingLabel.vx_has-error-with-message .vx_form-control-error-icon,
.vx_floatingLabel_complex.vx_has-error-with-message .vx_form-control-error-icon,
.vx_floatingLabel_active.vx_has-error-with-message .vx_form-control-error-icon {
  display: table-cell;
  visibility: visible;
  width: inherit;
  min-width: 2.25em;
  color: #D20000;
  text-align: left;
}
.vx_floatingLabel.vx_has-error:before,
.vx_floatingLabel_complex.vx_has-error:before,
.vx_floatingLabel_active.vx_has-error:before,
.vx_floatingLabel.vx_has-error-with-message:before,
.vx_floatingLabel_complex.vx_has-error-with-message:before,
.vx_floatingLabel_active.vx_has-error-with-message:before,
.vx_floatingLabel.vx_has-error:after,
.vx_floatingLabel_complex.vx_has-error:after,
.vx_floatingLabel_active.vx_has-error:after,
.vx_floatingLabel.vx_has-error-with-message:after,
.vx_floatingLabel_complex.vx_has-error-with-message:after,
.vx_floatingLabel_active.vx_has-error-with-message:after {
  color: #D20000;
}
.vx_floatingLabel.vx_has-error.hasValue input,
.vx_floatingLabel_complex.vx_has-error.hasValue input,
.vx_floatingLabel_active.vx_has-error.hasValue input,
.vx_floatingLabel.vx_has-error-with-message.hasValue input,
.vx_floatingLabel_complex.vx_has-error-with-message.hasValue input,
.vx_floatingLabel_active.vx_has-error-with-message.hasValue input,
.vx_floatingLabel.vx_has-error.hasFocus input,
.vx_floatingLabel_complex.vx_has-error.hasFocus input,
.vx_floatingLabel_active.vx_has-error.hasFocus input,
.vx_floatingLabel.vx_has-error-with-message.hasFocus input,
.vx_floatingLabel_complex.vx_has-error-with-message.hasFocus input,
.vx_floatingLabel_active.vx_has-error-with-message.hasFocus input {
  color: #2c2e2f;
}
.vx_floatingLabel_complex {
  display: block;
  padding: 0;
  height: auto;
  border: 1px solid #9da3a6;
  border-radius: 5px;
  text-align: left;
  transition: border-color 0.15s;
}
.vx_floatingLabel_complex .vx_form-control {
  display: table;
  table-layout: fixed;
  padding: 0;
  border: none;
  border-radius: 0;
  background: transparent;
}
.vx_floatingLabel_complex .vx_form-control input {
  display: block;
  width: 100%;
  border: none;
  background: none;
}
.vx_floatingLabel_complex .vx_form-control-icon + .vx_form-control input,
.vx_floatingLabel_complex .vx_form-control-icon + .vx_form-control:before,
.vx_floatingLabel_complex .vx_form-control-icon + .vx_form-control:after {
  padding-left: 8px;
  padding-right: 8px;
}
.vx_floatingLabel_complex label {
  max-width: 1px;
}
.vx_floatingLabel_complex:before,
.vx_floatingLabel_complex:after {
  content: '';
  display: none;
}
.vx_floatingLabel_complex.vx_has-message {
  border-color: #0092d1;
}
.vx_floatingLabel_complex.vx_has-error .vx_form-control:before,
.vx_floatingLabel_complex.vx_has-error-with-message .vx_form-control:before,
.vx_floatingLabel_complex.vx_has-error .vx_form-control:after,
.vx_floatingLabel_complex.vx_has-error-with-message .vx_form-control:after {
  color: #D20000;
}
.vx_floatingLabel_complex .vx_form-control-message {
  border-width: 0;
  border-top-width: 1px;
}
.vx_floatingLabel:before {
  color: #2C2E2F;
  opacity: 0;
}
.vx_floatingLabel input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 1;
}
.vx_floatingLabel input:-moz-placeholder {
  /* FF 4-18 */
  opacity: 1;
}
.vx_floatingLabel input::-moz-placeholder {
  /* FF 19+ */
  opacity: 1;
}
.vx_floatingLabel input:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 1;
}
.vx_floatingLabel input:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 1;
}
.vx_floatingLabel input:focus:-moz-placeholder {
  /* FF 4-18 */
  opacity: 1;
}
.vx_floatingLabel input:focus::-moz-placeholder {
  /* FF 19+ */
  opacity: 1;
}
.vx_floatingLabel input:focus:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 1;
}
.vx_floatingLabel.hasFocus:before,
.vx_floatingLabel.hasValue:before {
  opacity: 0;
}
.vx_floatingLabel.hasFocus:before input,
.vx_floatingLabel.hasValue:before input {
  color: inherit;
}
.vx_floatingLabel.hasFocus:after,
.vx_floatingLabel.hasValue:after {
  opacity: 1;
  top: -10px;
}
.vx_floatingLabel.hasValue:after {
  color: #2C2E2F;
}
.vx_floatingLabel_active input {
  color: transparent;
}
.vx_floatingLabel_active input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 0;
}
.vx_floatingLabel_active input:-moz-placeholder {
  /* FF 4-18 */
  opacity: 0;
}
.vx_floatingLabel_active input::-moz-placeholder {
  /* FF 19+ */
  opacity: 0;
}
.vx_floatingLabel_active input:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0;
}
.vx_floatingLabel_active input:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 1;
}
.vx_floatingLabel_active input:focus:-moz-placeholder {
  /* FF 4-18 */
  opacity: 1;
}
.vx_floatingLabel_active input:focus::-moz-placeholder {
  /* FF 19+ */
  opacity: 1;
}
.vx_floatingLabel_active input:focus:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 1;
}
.vx_floatingLabel_active:before,
.vx_floatingLabel_active.vx_floatingLabel_complex > .vx_form-control:before {
  opacity: 1;
}
.vx_floatingLabel_active:after,
.vx_floatingLabel_active.vx_floatingLabel_complex > .vx_form-control:after {
  opacity: 0;
}
.vx_floatingLabel_active.hasFocus input,
.vx_floatingLabel_active.hasValue input {
  color: inherit;
}
.vx_floatingLabel_active.hasFocus:before,
.vx_floatingLabel_active.hasFocus.vx_floatingLabel_complex > .vx_form-control:before,
.vx_floatingLabel_active.hasValue:before,
.vx_floatingLabel_active.hasValue.vx_floatingLabel_complex > .vx_form-control:before {
  opacity: 0;
}
.vx_floatingLabel_active.hasFocus:after,
.vx_floatingLabel_active.hasFocus.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_active.hasValue:after,
.vx_floatingLabel_active.hasValue.vx_floatingLabel_complex > .vx_form-control:after {
  opacity: 1;
}
.vx_floatingLabel_active.hasFocus:after,
.vx_floatingLabel_active.hasFocus.vx_floatingLabel_complex > .vx_form-control:after {
  color: #0070BA;
}
.vx_floatingLabel_active.vx_is-disabled {
  background: #F5F7FA;
}
.vx_floatingLabel_active.vx_is-disabled.hasFocus:after,
.vx_floatingLabel_active.vx_is-disabled.hasFocus.vx_floatingLabel_complex > .vx_form-control:after {
  color: #6C7378;
}
.vx_floatingLabel_active.vx_is-disabled input[disabled],
.vx_floatingLabel_active.vx_is-disabled input[disabled]:hover,
.vx_floatingLabel_active.vx_is-disabled input[disabled]:active,
.vx_floatingLabel_active.vx_is-disabled input.vx_is-disabled,
.vx_floatingLabel_active.vx_is-disabled input.vx_is-disabled:hover,
.vx_floatingLabel_active.vx_is-disabled input.vx_is-disabled:active {
  background: transparent;
  color: #6C7378;
}
.vx_has-spinner-small,
.vx_has-spinner-medium,
.vx_has-spinner-large {
  overflow: hidden;
}
.vx_has-spinner-small.vx_has-spinner-bottom::after,
.vx_has-spinner-medium.vx_has-spinner-bottom::after,
.vx_has-spinner-large.vx_has-spinner-bottom::after {
  top: auto;
}
.vx_btn {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.vx_btn[disabled] {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.2);
  border-style: none;
}
.vx_btn[disabled]:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.2);
  border-style: none;
}
.vx_btn[disabled].vx_btn-secondary_reversed {
  color: rgba(255, 255, 255, 0.4);
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.4);
}
@media (max-width: 700px) {
  .vx_globalNav-listItem_logout {
    margin-right: 1em;
  }
}
.vx_globalNav-container {
  max-width: 1256px !important;
  padding-left: 48px !important;
  padding-right: 48px !important;
}
@media (max-width: 1160px) {
  .vx_globalNav-container {
    max-width: none !important;
  }
}
@media (max-width: 900px) {
  .vx_globalNav-container {
    max-width: none !important;
  }
}
@media (max-width: 700px) {
  .vx_globalNav-container {
    max-width: none !important;
  }
}
.vx_globalNav-link_settings {
  padding-top: 1.5rem;
}
.vx_globalFooter-content {
  padding-right: 48px;
  padding-left: 48px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1256px;
}
@media (max-width: 700px) {
  .vx_globalFooter-content {
    padding-left: 36px;
    padding-right: 36px;
  }
}
.footerSmallText:empty {
  display: none;
}
.vx_mainContent {
  max-width: none !important;
  padding-bottom: 0 !important;
  margin: 0 !important;
}
.vx_floatingLabel .vx_select::before {
  font-weight: lighter;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
html {
  font-size: 16px;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
  background-image: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2C2E2F;
}
body div[role='tooltip'] {
  z-index: 2002;
}
header.pp-header {
  z-index: 2001;
}
body.menu-open-dw {
  transform: none !important;
}
footer.vx_globalFooter {
  margin-top: 0 !important;
}
.pp-header.dw-pp-header.pp-header--menu-open .main-menu .main-menu-list a.pypl-header__subnav-control::after {
  border: 0.125rem solid transparent;
}
.global-footer.rebrand-footer hr.hidden-lg-down.primary {
  border: 1px solid #cbd2d6;
  border-bottom: 0;
}
.global-footer .footer-main,
.global-footer .footer-secondary,
.global-footer .footer-tertiary {
  margin: 0 !important;
}
#ccpaCookieContent_wrapper {
  width: 100%;
}

.modal__modal-overflow-container___3ougJ {
  position: fixed;
}
@media (min-width: 700px) {
  .modal__modal-overflow-container___3ougJ {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 2000;
  }
}
@media (max-width: 700px) {
  .modal__modal-overflow-container___3ougJ.modal-enter-active {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 2000;
  }
  .modal__modal-overflow-container___3ougJ:not(.modal-enter-active):not(.modal-enter) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .modal__modal-overflow-container___3ougJ:not(.modal-enter-active):not(.modal-enter) ~ * {
    display: none;
  }
}
.modal__modal-overflow-container___3ougJ .modal__modal-wrapper___KjzEp {
  min-height: 100vh;
  background-color: #f7f5f0;
}
.modal__modal-overflow-container___3ougJ .modal__modal-wrapper___KjzEp.modal__glimpse___1hwIv {
  background-color: rgba(244, 246, 249, 0.9);
}
@media (min-width: 700px) {
  .limit-scrollability {
    height: 100vh;
    overflow-y: hidden;
  }
}
@media (min-width: 700px) and (-webkit-min-device-pixel-ratio: 0) {
  .limit-scrollability {
    overflow-y: scroll;
  }
}
.modal__white-part___23boo {
  min-height: 100%;
  margin: 0 auto;
  background-color: #FFFFFF;
  max-width: 612px;
  width: 100%;
  position: relative;
}
@media (max-width: 700px) {
  .modal__white-part___23boo {
    max-width: none;
    min-height: none;
  }
}
.modal__outer-wide-view-container___Si1Qi {
  max-width: 720px;
}
.modal__modal-header___ouD8L {
  width: 100%;
  height: 58px;
  padding: 12px 16px;
}
.modal__modal-header___ouD8L .modal__left-header___3fgqR {
  float: left;
  position: relative;
  z-index: 1;
}
.modal__modal-header___ouD8L .modal__x-button___34RUF {
  display: block;
  float: right;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.modal__contents___2XqTe {
  margin: 0 auto;
  width: 100%;
  max-width: 450px;
  min-height: calc(100vh -  90px);
  overflow: hidden;
}
.modal__contents___2XqTe form {
  margin-bottom: 0;
}
.modal__inner-wide-view-container___2CwqO {
  max-width: 448px;
}
.modal__modal-progress___3gMuF {
  width: 100%;
}
.modal__modal-progress___3gMuF .modal__progress-bar___3updS {
  padding-bottom: 0.25rem !important;
}
@media (max-width: 700px) {
  .modal__stepName___D7P4i {
    padding: 90px 16px !important;
  }
}

.back_button__back-button___25hPo {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  line-height: 1;
}

@media (max-width: 700px) {
}
.modal_wizard__step-animation-container___1BDQt {
  position: relative;
  min-height: calc(100% - 180px);
}
@media (max-width: 700px) {
  .modal_wizard__step-animation-container___1BDQt {
    min-height: calc(100% - 170px);
  }
}
.modal_wizard__step-enter-forward___3rqJx {
  position: absolute;
  width: 100%;
  transform: translateX(120%);
  will-change: transform;
}
.modal_wizard__step-enter-forward___3rqJx.modal_wizard__step-enter-forward-active___2N5tT {
  transform: translateX(0%);
  transition: all 250ms ease-out;
}
.modal_wizard__step-leave-forward___3La1F {
  position: absolute;
  width: 100%;
  transform: translateX(0%);
  will-change: transform;
}
.modal_wizard__step-leave-forward___3La1F.modal_wizard__step-leave-forward-active___3dZPz {
  transform: translateX(-120%);
  transition: all 250ms ease-out;
}
.modal_wizard__step-enter-backward___2O0q_ {
  position: absolute;
  width: 100%;
  transform: translateX(-120%);
  will-change: transform;
}
.modal_wizard__step-enter-backward___2O0q_.modal_wizard__step-enter-backward-active___KsJYL {
  transform: translateX(0%);
  transition: all 250ms ease-out;
}
.modal_wizard__step-leave-backward___3WeHM {
  position: absolute;
  width: 100%;
  transform: translateX(0%);
  will-change: transform;
}
.modal_wizard__step-leave-backward___3WeHM.modal_wizard__step-leave-backward-active___37vlA {
  transform: translateX(120%);
  transition: all 250ms ease-out;
}

.manage_cf__container___2kANE {
  width: 100%;
}
.manage_cf__edit-link___1L_Gg {
  margin-bottom: 44px;
  display: block;
}
.manage_cf__no-one___mCPk4 {
  margin-top: 48px;
  text-align: center;
}
.manage_cf__add-currency___25-Pm {
  display: inline-block;
}
.manage_cf__progress-bar___D5Jsa {
  margin-bottom: 92px;
}
.manage_cf__manage-icons___2z5W6 {
  color: #0070ba;
  background-color: #f5f7fa;
  padding: 6px;
  border-radius: 50%;
}
.manage_cf__manage-icon-row___25MgE {
  margin-bottom: 30px;
  margin-left: 6%;
  margin-right: 6%;
}
.manage_cf__manage-icon-col___1nvvc {
  text-align: center;
  margin-bottom: 30px;
}
.manage_cf__manage-icon-text-align___3RkQj {
  margin-top: 0.8rem;
  text-align: center;
}
a.manage_cf__manage-link___2cAza:hover {
  text-decoration: none;
}
.manage_cf__manage-icon-row___25MgE > div.manage_cf__manage-icon-col___1nvvc > a.manage_cf__manage-link___2cAza:focus {
  display: inline-block;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.manage_cf__contributors___lzyEE {
  text-align: center;
}
.manage_cf__btn___3YEEY {
  clear: both;
  margin: 0 auto 24px;
}
.manage_cf__text-center___1IvUi {
  text-align: center;
}
.manage_cf__text-left___1sWpf {
  text-align: left;
}
.manage_cf__pool-header___2Y1Ls {
  margin-bottom: 36px;
}
.manage_cf__pool-header___2Y1Ls > :nth-child(2),
.manage_cf__pool-header___2Y1Ls :nth-child(3) {
  -ms-flex-pack: center;
      justify-content: center;
}
.manage_cf__pool-header-left___331W5 {
  margin-bottom: 1.5rem;
}
.manage_cf__pool-header-left___331W5 > :nth-child(2),
.manage_cf__pool-header-left___331W5 :nth-child(3) {
  -ms-flex-pack: start;
      justify-content: start;
}
.manage_cf__activityLoader___2HRsu {
  -ms-flex-pack: center;
      justify-content: center;
  display: -ms-flexbox;
  display: flex;
}

.flat_progress__flat-progress___2hCBJ div.flat_progress__amount___2A_At {
  margin-bottom: 8px;
}
.flat_progress__flat-progress___2hCBJ div.flat_progress__amount--mb___2wgds {
  margin-bottom: 12px;
}
.flat_progress__flat-progress___2hCBJ div.flat_progress__title___2c9H8 {
  margin-bottom: 12px;
}
.flat_progress__flat-progress___2hCBJ .flat_progress__meter-component____X_qM {
  padding: 0;
}

.no_currency_alert__add-currency___29uOV {
  display: inline-block;
}

.alert__alert___1SGjJ {
  display: table;
  width: 100%;
  margin-bottom: 5px;
  padding: 1rem;
  border: 1px solid #9DA3A6;
  border-left-width: 0;
  border-right-width: 0;
  color: #9DA3A6;
  border-left-width: 1px;
  border-right-width: 1px;
  border-radius: 5px;
}
.alert__alert-help___2CAVD {
  border-color: #009CDE;
  color: #009CDE;
}
.alert__alert-help___2CAVD:before {
  display: inline;
  width: 3em;
  padding-right: 12px;
  font-size: 1.58rem;
  line-height: 0;
  font-family: 'paypal-vx-icons';
}
.alert__alert-help___2CAVD:before {
  content: "\2139";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.alert__alert-warning___2ko_N {
  border-color: #FF9600;
  color: #FF9600;
}
.alert__alert-warning___2ko_N:before {
  display: inline;
  width: 3em;
  padding-right: 12px;
  font-size: 1.58rem;
  line-height: 0;
  font-family: 'paypal-vx-icons';
}
.alert__alert-warning___2ko_N:before {
  content: "\2755";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.alert__alert-critical___3ClW2 {
  border-color: #D20000;
  color: #D20000;
}
.alert__alert-critical___3ClW2:before {
  display: inline;
  width: 3em;
  padding-right: 12px;
  font-size: 1.58rem;
  line-height: 0;
  font-family: 'paypal-vx-icons';
}
.alert__alert-critical___3ClW2:before {
  content: "\26A0";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.alert__alert-success___Itvps {
  border-color: #00CF92;
  color: #00CF92;
}
.alert__alert-success___Itvps:before {
  display: inline;
  width: 3em;
  padding-right: 12px;
  font-size: 1.58rem;
  line-height: 0;
  font-family: 'paypal-vx-icons';
}
.alert__alert-success___Itvps:before {
  content: "\2714";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.alert__alert-text___UdNJH {
  margin: 0 0 0 2.5em;
  color: #2c2e2f;
  display: table-cell;
  width: 100%;
}

.campaign_balance__balance___1-8hj .campaign_balance__pledge-row___3L0LY {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  margin-top: 14px;
}
.campaign_balance__balance___1-8hj .campaign_balance__amount-row___2FXGB {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
}
.campaign_balance__balance___1-8hj .campaign_balance__label___ZV0l8 {
  -ms-flex-positive: 2;
      flex-grow: 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.campaign_balance__balance___1-8hj .campaign_balance__value___bkxke {
  -ms-flex-positive: 1;
      flex-grow: 1;
  text-align: right;
}
.campaign_balance__balance___1-8hj .campaign_balance__bold-value___2wRuN {
  -ms-flex-positive: 1;
      flex-grow: 1;
  text-align: right;
}
.campaign_balance__balance-wrapper___2PvAJ {
  margin-bottom: 76px;
}
.campaign_balance__donation-title___1G1YK {
  margin: 22px auto;
}
.campaign_balance__balance-wrapper___2PvAJ {
  padding-right: 24px;
}

.activity__title___1FdbK {
  margin-bottom: 12px;
}
.activity__subtitle___TycZM {
  margin-bottom: 24px;
}
.activity__balance___33HIC {
  margin-bottom: 24px;
}
.activity__list-container___2Sxx6 {
  overflow-y: auto;
}
.activity__list-container___2Sxx6:not(:last-child) {
  margin-bottom: 30px;
}
.activity__activity-title___19Ca_ {
  margin-bottom: 32px;
}

.reveal__reveal-enter___1-LPk {
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
}
.reveal__reveal-enter___1-LPk.reveal__reveal-enter-active___mRhvD {
  transform: scaleY(1);
  opacity: 1;
  transition: all 300ms ease-out;
}
.reveal__reveal-leave___2sm7R {
  transform: scaleY(1);
  transform-origin: top;
  opacity: 1;
}
.reveal__reveal-leave___2sm7R.reveal__reveal-leave-active___39k1f {
  transform: scaleY(0);
  opacity: 0;
  transition: all 300ms ease-out;
}

.contributions_list__title___1rZvc {
  padding-bottom: 4px;
  margin-bottom: 15px;
  border-bottom: 1px solid #B7BCBF;
}
.contributions_list__row___3NYvO {
  display: none;
}
.contributions_list__more-link___LNoVN {
  padding-top: 0.75em;
  display: block;
}
.contributions_list__date-row___3-s7p {
  color: #687173;
  text-transform: uppercase;
  margin-bottom: 4px;
}
.contributions_list__date-row___3-s7p:not(:first-child) {
  padding-top: 0.75em;
}
.contributions_list__activity-link___Xk-2N {
  padding: 0;
  position: static;
  text-decoration: none !important;
}
.contributions_list__show-more___3EzOJ {
  text-align: center;
  padding: 10px 0 20px;
}

.avatar__initials___144FT {
  width: 100%;
  text-align: center;
  color: white;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 400;
}
.avatar__small-initials___2xdAF {
  font-weight: 700;
}
.avatar__anonymous-avatar___31pG5 {
  background: #25694f !important;
  color: #6fc400 !important;
}

.activity_row__activity-row___3MKf7 {
  margin: 16px 8px;
  -ms-flex-pack: start;
      justify-content: start;
  padding: 16px;
}
.activity_row__avatar-wrapper___2SdlL {
  width: 48px;
  height: 48px;
}
.activity_row__details-wrapper___30LU3 {
  margin-left: 16px;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.activity_row__details-content___jHUxq {
  display: inline-block;
}
.activity_row__subject-name___2aTDH {
  word-break: break-word;
}
.activity_row__activity-status___31ghF {
  color: #545d68 !important;
  font-size: 0.9rem !important;
}
.activity_row__activity-owner-details___1SHJ6 {
  display: -ms-flexbox;
  display: flex;
}
.activity_row__details-note___2BC5D {
  display: inline-block;
  margin-top: 5px;
  margin-left: 8%;
  width: 92%;
}
@media (max-width: 1160px) {
  .activity_row__details-note___2BC5D {
    margin-left: 11%;
    width: 89% !important;
  }
}
@media (max-width: 900px) {
  .activity_row__details-note___2BC5D {
    margin-left: 16%;
    width: 84% !important;
  }
}
@media (max-width: 700px) {
  .activity_row__details-note___2BC5D {
    margin-left: 23%;
    width: 77% !important;
  }
}

.public_contribution_row__row___3OM2F {
  padding: 2rem 0;
  position: relative;
}
.public_contribution_row__row___3OM2F:last-child {
  border: none;
  padding-bottom: 0;
}
.public_contribution_row__avatar-wrapper___2nOio {
  width: 56px;
  height: 56px;
  display: inline-block;
  margin-right: 14px;
  vertical-align: top;
}
.public_contribution_row__details-wrapper___25Efs {
  display: inline-block;
  width: 60%;
  margin-top: 3px;
}
.public_contribution_row__details-note___1nCRg {
  display: inline-block;
  margin-top: 3px;
  margin-left: 10%;
  width: 90%;
}
@media (max-width: 1160px) {
  .public_contribution_row__details-note___1nCRg {
    margin-left: 13%;
    width: 87% !important;
  }
}
@media (max-width: 900px) {
  .public_contribution_row__details-note___1nCRg {
    margin-left: 17%;
    width: 83% !important;
  }
}
@media (max-width: 700px) {
  .public_contribution_row__details-note___1nCRg {
    margin-left: 20%;
    width: 80% !important;
  }
}
.public_contribution_row__details-wrapper-preview___3gW_R {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0;
}
.public_contribution_row__details-wrapper-preview___3gW_R p {
  margin-top: 0;
  margin-bottom: 0;
}
.public_contribution_row__amount___17L-0 {
  display: inline-block;
  width: 150px;
  position: absolute;
  text-align: right;
  right: 0;
  margin-top: 10px;
  color: #2C2E2F;
}
@media (max-width: 370px) {
  .public_contribution_row__amount___17L-0 {
    margin-top: 15px;
  }
}
.public_contribution_row__amount-preview___M3_ej {
  color: #cecfd0;
}
.public_contribution_row__contributor-name___3zP0i {
  color: #2C2E2F;
}
.public_contribution_row__days-ago___2q87c,
.public_contribution_row__note___3hoh1 {
  color: #6C7378;
}
.public_contribution_row__days-ago___2q87c {
  line-height: 1;
  margin-bottom: 12px;
  color: #687173 !important;
}
.public_contribution_row__note___3hoh1 {
  line-height: 1.3;
  background-color: #f7f5f0 !important;
  border-radius: 8px;
  padding: 12px;
}
.public_contribution_row__pledge-contribution-row___1bAWx {
  padding-top: 0.5rem;
}

.updates__title___iEoGL {
  margin-bottom: 12px;
}
.updates__list-container___2PKR- {
  overflow: scroll;
}
.updates__list-container___2PKR-:not(:last-child) {
  margin-bottom: 30px;
}
.updates__activity-title___3jNtM {
  margin-bottom: 32px;
}
.updates__show-more___34sbd {
  text-align: center;
  padding: 10px 0 20px;
}
.updates__update-heading___1NQ93 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
}
.updates__activity-empty___2b4SL {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.7rem !important;
  margin: 20px 0;
  border: none !important;
}
@media (max-width: 700px) {
  .updates__activity-empty___2b4SL {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    padding: 0 !important;
  }
}
.updates__empty-post-button___3Ztv_ {
  width: 195px !important;
}
@media (max-width: 1160px) {
  .updates__empty-post-button___3Ztv_ {
    width: 275px !important;
  }
}
@media (max-width: 900px) {
  .updates__empty-post-button___3Ztv_ {
    width: 320px !important;
  }
}
@media (max-width: 700px) {
  .updates__empty-post-button___3Ztv_ {
    width: 137px !important;
  }
}
a.updates__postupdate-link___12C3X:focus {
  display: inline-block;
  border-radius: 50%;
}
.updates__postupdate-icon___1svkE {
  border: 1px solid #142c8e !important;
  padding: 5px !important;
}
.updates__empty-update-right-side___Wcl06 {
  padding: 16px;
}
.updates__empty_text___3h_oY {
  padding: 0 16px;
}
@media (max-width: 1160px) {
  .updates__empty_text___3h_oY {
    padding: 0 16px;
  }
}
@media (max-width: 900px) {
  .updates__empty_text___3h_oY {
    margin: 15px 0;
    padding: 0 16px;
  }
}
@media (max-width: 700px) {
  .updates__empty_text___3h_oY {
    margin: 15px 0;
    padding: 0;
  }
}

.update_row__activity-row___99Mjt {
  margin: 16px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: start;
  padding: 16px !important;
}
.update_row__update-tile___2L9Ya {
  text-decoration: none !important;
  display: block;
}

.organizer_update_detail__activity-row___3jyxF {
  margin: 16px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: start;
  padding: 16px !important;
}
.organizer_update_detail__details-content___2nori {
  display: inline-block;
  width: 100%;
  outline: none;
}
@media (max-width: 700px) {
  .organizer_update_detail__details-content-mweb___1P2sl {
    padding: 16px;
  }
}
.organizer_update_detail__truncate-subject___3L8Z4 {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2rem !important;
}
.organizer_update_detail__subject-name___26gXE {
  width: 80%;
}
@media (max-width: 700px) {
  .organizer_update_detail__subject-name___26gXE {
    width: 100%;
  }
}
a.organizer_update_detail__row-edit-link___3h7P1:focus {
  display: inline-block;
  border-radius: 50%;
}
.organizer_update_detail__subject-timestamp____obBz {
  color: #687173 !important;
}
.organizer_update_detail__edit-button___pFleS {
  padding: 0.25rem !important;
  margin-top: 5px;
}
.organizer_update_detail__subject-description___VrWpt {
  margin-top: 12px;
  margin-bottom: 5px;
}
.organizer_update_detail__update-button___1FJga {
  margin-top: 2rem;
  -ms-flex-pack: center;
      justify-content: center;
}
.organizer_update_detail__parentheses___1uveH::before {
  content: ' (';
}
.organizer_update_detail__parentheses___1uveH::after {
  content: ')';
}
.organizer_update_detail__truncate-description___3vdO7 {
  grid-area: body_text;
  display: -webkit-box;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow: hidden;
  line-height: 1.3rem;
  max-height: 4.5rem;
  -webkit-line-clamp: 3;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  position: relative;
}
.organizer_update_detail__organizer-update___XgqjJ {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
}
@media (max-width: 700px) {
  .organizer_update_detail__organizer-update___XgqjJ {
    display: block;
  }
}

.pictograms__empty-image___3culh {
  height: 96px;
  width: 96px;
}
.pictograms__category-selection-image___28RqE {
  height: 160px;
  width: 160px;
}
@media (max-width: 700px) {
  .pictograms__category-selection-image___28RqE {
    height: 136px;
    width: 136px;
  }
}
.pictograms__creation-share-image___4yjOt {
  height: 160px;
  width: 160px;
}
@media (max-width: 700px) {
  .pictograms__creation-share-image___4yjOt {
    height: 136px;
    width: 136px;
  }
}
.pictograms__state-image___1nUy0 {
  width: 96px;
}

.crowdfunding_campaign_header__headerCaptionText___16yv7 {
  margin: 0 0 0 0.4rem;
}
@media (max-width: 700px) {
  .crowdfunding_campaign_header__headerCaptionText___16yv7 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
@media (max-width: 320px) {
  .crowdfunding_campaign_header__headerCaptionText___16yv7 {
    padding-right: 1.5rem;
  }
}
.crowdfunding_campaign_header__iconCaptionContainer___3lxLS {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding-right: 0;
  max-width: 100% !important;
}
@media (max-width: 700px) {
  .crowdfunding_campaign_header__iconCaptionContainer___3lxLS {
    width: auto !important;
  }
}
.crowdfunding_campaign_header__iconCaptionContainer___3lxLS .crowdfunding_campaign_header__categoryBadge___1urZ0 {
  margin: 16px 8px 16px 0;
  display: inline-block !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media (max-width: 700px) {
  .crowdfunding_campaign_header__iconCaptionContainer___3lxLS .crowdfunding_campaign_header__categoryBadge___1urZ0 {
    margin: 10px 8px 5px 0;
  }
}
.crowdfunding_campaign_header__iconCaptionWrapper___9I8PM {
  -ms-flex-wrap: no-wrap;
      flex-wrap: no-wrap;
  max-width: 100%;
}
@media (max-width: 700px) {
  .crowdfunding_campaign_header__iconCaptionWrapper___9I8PM {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.crowdfunding_campaign_header__avatarImage___340a4 img {
  object-fit: scale-down;
}
.crowdfunding_campaign_header__heading___3dthe {
  margin: 0 !important;
}
@media (max-width: 479px) {
  .crowdfunding_campaign_header__heading___3dthe {
    width: 300px;
    white-space: normal;
    max-width: 100%;
  }
}
.crowdfunding_campaign_header__charity-header___26_2h {
  margin: 8px 0;
}
.crowdfunding_campaign_header__top-alignment___1lTbT {
  margin-top: 16px;
}
.crowdfunding_campaign_header__charity-name-link___1_YAL {
  margin-left: 5px;
}
.crowdfunding_campaign_header__campaign_padding___1iMsW {
  margin: 6px 0 0;
}
@media (max-width: 700px) {
  .crowdfunding_campaign_header__mwebheaderCaptionText___18RPA {
    padding: 5px;
  }
}

.charity_card__charity-tile-logo___2lIl- {
  width: 72px !important;
  height: 72px !important;
  margin-right: 12px;
}
.charity_card__charity-tile-logo___2lIl- img {
  object-fit: contain;
}
.charity_card__charity-wrap___17lpB {
  margin-bottom: 16px;
  background-color: #f7f5f0 !important;
  border: none;
  border-radius: 4px;
}
.charity_card__charity-title___3dBaB {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: start;
  -ms-flex-align: center;
      align-items: center;
  padding: 16px;
  height: 104px;
}

.crowdfunding_cover_image__done-btn___3PnHQ {
  margin-top: 40px;
}
.crowdfunding_cover_image__done-btn___3PnHQ,
.crowdfunding_cover_image__title___M8nAr,
.crowdfunding_cover_image__tip___2_owM,
.crowdfunding_cover_image__remove-image___rcexY {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.crowdfunding_cover_image__title___M8nAr {
  margin-bottom: 24px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.crowdfunding_cover_image__title_cf___1vOdq {
  margin-bottom: 22px;
}
.crowdfunding_cover_image__image-container___3BSlj {
  margin-bottom: 40px;
}
.crowdfunding_cover_image__upload-info___1QXIY {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  height: 130px;
  cursor: pointer;
}
.crowdfunding_cover_image__upload-info___1QXIY .crowdfunding_cover_image__upload-info-text___29Z-M {
  text-align: center;
}
.crowdfunding_cover_image__upload-info___1QXIY .crowdfunding_cover_image__upload-info-text___29Z-M span {
  color: #1072eb;
  font-size: 16px;
}
.crowdfunding_cover_image__upload-info___1QXIY .crowdfunding_cover_image__upload-info-icon___2X3jU {
  color: #515354;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.crowdfunding_cover_image__tip___2_owM {
  margin: 0;
}
.crowdfunding_cover_image__tip___2_owM span {
  color: #515354;
  font-family: 'PayPal-Sans-Big', sans-serif;
}
.crowdfunding_cover_image__tip___2_owM span a,
.crowdfunding_cover_image__tip___2_owM span a:visited,
.crowdfunding_cover_image__tip___2_owM span a:hover {
  color: #515354;
  -webkit-text-decoration-color: #515354;
          text-decoration-color: #515354;
}
.crowdfunding_cover_image__remove-image___rcexY {
  margin-bottom: 36px;
  display: inline-block;
}
.crowdfunding_cover_image__choose-background___23Ksg {
  text-align: center;
  width: 100%;
  margin-bottom: 48px;
}
.crowdfunding_cover_image__choose-color___Xloid {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 27px;
}
.crowdfunding_cover_image__color-form-container___3ptmQ {
  width: 300px;
  margin: 0 auto 53px;
}
.crowdfunding_cover_image__color-form___1oRNI {
  text-align: center;
}

.cross_fade__crossfade-container___3glsY {
  position: relative;
  width: 100%;
}
.cross_fade__crossfade-enter___1rdn3 {
  opacity: 0;
  position: absolute;
}
.cross_fade__crossfade-enter___1rdn3.cross_fade__crossfade-enter-active___3jLQy {
  opacity: 1;
  transition: opacity 350ms ease-out;
  transition-delay: 150ms;
}
.cross_fade__crossfade-leave___2CEKL {
  opacity: 1;
  position: absolute;
}
.cross_fade__crossfade-leave___2CEKL.cross_fade__crossfade-leave-active___CUZkO {
  opacity: 0;
  transition: opacity 350ms ease-out;
  transition-delay: 0ms;
}

.image_upload_field__dragzone___2nzOY {
  box-sizing: border-box;
  height: 130px;
  border: 1px dashed #909191;
  border-radius: 5px;
  background-color: #fff;
}
.image_upload_field__dragover___YKSei {
  background-color: #f5f7fa;
}
.image_upload_field__not-supported___34Kr9 {
  cursor: not-allowed;
  background-color: #fff;
}
.image_upload_field__dragzone___2nzOY:hover {
  background-color: #f5f7fa;
}

.crowdfunding_image_adjust__preview-container___c5POv {
  padding: 0 12.5;
}
.crowdfunding_image_adjust__preview-container___c5POv .crowdfunding_image_adjust__viewer___1nBYf {
  height: -moz-max-content;
  height: max-content;
}

.cropper__image-cropper___14oez .cropper__icon-panel___2TZDJ {
  -ms-flex-pack: justify;
      justify-content: space-between;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}
.cropper__image-cropper___14oez .cropper__action-button-row___2mCiL {
  padding: 1rem 0;
}
.cropper__image-cropper___14oez .cropper__cropper-wrapper___2Ofbn {
  width: 100%;
  background: #666;
  -ms-flex-pack: center;
      justify-content: center;
}

.crowdfunding_image_preview__preview-container___2o34w {
  cursor: move;
  box-sizing: border-box;
  border: 1px solid #b7bcbf;
  border-radius: 4px;
  background-color: #fff;
  padding: 0 12.5;
}
.crowdfunding_image_preview__preview-container___2o34w .crowdfunding_image_preview__drag___1JSWz {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  color: #7c888a;
  height: 34.5px;
  -ms-flex-align: center;
      align-items: center;
}
.crowdfunding_image_preview__preview-container___2o34w .crowdfunding_image_preview__actions___3Rjbn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex-align: center;
      align-items: center;
}
.crowdfunding_image_preview__preview-container___2o34w .crowdfunding_image_preview__actions___3Rjbn .crowdfunding_image_preview__action___1O0Ui {
  color: #1072eb;
  margin-left: 16px;
}

.strip__strip___2ZIG4 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate3d(0, 0, 0);
  border-radius: 12px 12px 0 0;
}
@media (max-width: 479px) {
  .strip__strip___2ZIG4 {
    border-radius: 5px;
  }
}
.strip__static___2XOR_ {
  height: 100%;
}
.strip__strip-image___x2vdA {
  border-radius: 0 12px 12px 0;
}
.strip__strip-with-image___hM8gO {
}
.strip__strip-with-image___hM8gO.strip__responsive___IKuHm {
  height: 0;
  padding-bottom: 33%;
}
@media (max-width: 700px) {
  .strip__strip-with-image___hM8gO.strip__responsive___IKuHm {
    padding-bottom: 53%;
  }
}
@media (min-width: 1360px) {
  .strip__strip-with-image___hM8gO.strip__responsive___IKuHm {
    padding-bottom: 28%;
  }
}
.strip__strip-with-image___hM8gO.strip__responsive-fullscreen___2hFqo {
  height: 33vw;
  max-height: 500px;
}
@media (max-width: 700px) {
  .strip__strip-with-image___hM8gO.strip__responsive-fullscreen___2hFqo {
    height: 53vw;
  }
}
@media (min-width: 1360px) {
  .strip__strip-with-image___hM8gO.strip__responsive-fullscreen___2hFqo {
    height: 28vw;
  }
}
.strip__strip-with-gradient___3KMlf {
  height: 100%;
}
@media (max-width: 479px) {
  .strip__strip-with-gradient___3KMlf {
    min-height: 151px;
  }
}
.strip__strip-blue___3SMy0 {
  background-image: linear-gradient(90deg, #009CDE 0%, #003087 100%);
}
.strip__strip-purple___OTBkC {
  background-image: linear-gradient(90deg, #640487 0%, #003087 100%);
}
.strip__strip-fuchsia___1EFrs {
  background-image: linear-gradient(90deg, #de0063 0%, #640487 100%);
}
.strip__strip-red___2R-mq {
  background-image: linear-gradient(90deg, #FF9600 0%, #D20000 100%);
}
.strip__strip-teal___17ozk {
  background-image: linear-gradient(90deg, #00CF92 0%, #009CDE 100%);
}
.strip__strip-gray___3G2HH {
  background-image: linear-gradient(90deg, #7f7f7f 0%, #323232 100%);
}
.strip__strip-green___MxXTf {
  background-image: linear-gradient(90deg, #abe456 0%, #397d1e 100%);
}
.strip__strip-sky___2rRAU {
  background-image: linear-gradient(90deg, #4be3f5 0%, #0059b1 100%);
}
.strip__strip-pink___2RDQD {
  background-image: linear-gradient(90deg, #faa0ac 0%, #ff182b 100%);
}
.strip__strip-orange___lh6un {
  background-image: linear-gradient(90deg, #ffd44b 0%, #ff9600 100%);
}
.strip__alternative-image___iSxTo {
  visibility: hidden;
}

.campaign_details__charityOrganiserDetailsContainer___1CrzH {
  margin: 32 0 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: start;
  -ms-flex-align: center;
      align-items: center;
}
.campaign_details__charityTextLight___3WZYz {
  color: #6c7378;
}
.campaign_details__charityOrganiserDetails___3_kcl {
  padding-left: 5px;
}
.campaign_details__charityOrganiserDetailsCaption___2TrOO {
  margin-left: 4px;
  -ms-flex: 1;
      flex: 1;
}
.campaign_details__details-margin___3P0r9 {
  margin: 38px 0 0;
}
.campaign_details__details-margin___3P0r9 .campaign_details__expandable-content___3163V {
  margin-top: 28px;
}
.campaign_details__strong___17KiU {
  font-weight: bold;
}

.collapsible_text__collapsible___1CeN1 {
  display: contents;
  margin-bottom: 12px;
  white-space: pre-line;
  word-wrap: break-word;
}
.collapsible_text__collapsed___2_X6Z {
  overflow: hidden;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}
.collapsible_text__header___37V-f {
  margin-bottom: 24px;
}
.collapsible_text__footer___3zcnR {
  margin-bottom: calc(11.25px);
}
.collapsible_text__button___3Nwfq {
  text-align: center;
}

.crowdfunding_tab_layout__tab_layout___1ZDEM button {
  font-weight: bold;
}
.crowdfunding_tab_layout__tab_content___HrhVX {
  margin: 0;
}
.crowdfunding_tab_layout__contribution_content___1hEPw {
  margin: -29px 0 0;
}
.crowdfunding_tab_layout__organizer-update-doner___TlKZJ {
  margin-top: 2rem;
}
.crowdfunding_tab_layout__show-more___25kSt {
  text-align: center;
}

.crowdfunding_detail_view__campaign_container_row___IU1n5 {
  margin: 1rem 0;
}
.crowdfunding_detail_view__donate-button___1T_HM {
  margin-top: 30px;
}
.crowdfunding_detail_view__manage-button___2Dgyb {
  display: block;
  margin-top: 30px;
  width: 100%;
}
@media (max-width: 479px) {
  .crowdfunding_detail_view__manage-button___2Dgyb {
    margin-top: 16px;
  }
}
.crowdfunding_detail_view__text-center___2e-6M {
  text-align: center;
}
.crowdfunding_detail_view__social-share-container___1Sadw {
  border: 1px solid #dbd8d0;
  margin-top: 56px;
  border-radius: 4px;
  padding: 16px 12px;
  box-sizing: border-box;
}
.crowdfunding_detail_view__btn___is7sz {
  float: right;
  padding: 15px;
}
.crowdfunding_detail_view__donateDisabledWrapper___3uQsM {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2rem;
}
.crowdfunding_detail_view__donateDisabledText___2-KSp {
  padding: 2px 5px;
}
.crowdfunding_detail_view__campaign_container___3Xe5v {
  padding: 3rem 2rem 2rem;
  max-width: 1024px;
  display: -ms-grid;
  display: grid;
  grid-column-gap: 5.86%;
  grid-row-gap: 32px;
  -ms-grid-columns: 57.07% 37.07%;
      grid-template-columns: 57.07% 37.07%;
  grid-template-areas: 'campaignHeader campaignHeader' 'campaignCover campaignProgress' 'campaignTabs campaignSharing' 'charity emptyDiv' 'donations campaignReporting';
}
.crowdfunding_detail_view__share-caption___1CV1R {
  margin-top: 16px;
}
.crowdfunding_detail_view__campaignHeader___3TAUE {
  grid-area: campaignHeader;
}
@media (max-width: 479px) {
  .crowdfunding_detail_view__campaignHeader___3TAUE {
    padding-left: 15px;
  }
}
.crowdfunding_detail_view__campaignManage___1a-NU {
  padding-top: 5px;
  grid-area: campaignManage;
}
.crowdfunding_detail_view__campaignCover___N0D19 {
  grid-area: campaignCover;
  height: 300px;
  position: relative;
  margin-bottom: 50px;
}
@media (max-width: 479px) {
  .crowdfunding_detail_view__campaignCover___N0D19 {
    height: 228px;
  }
}
.crowdfunding_detail_view__campaignProgress___NtqKA {
  grid-area: campaignProgress;
}
@media (max-width: 479px) {
  .crowdfunding_detail_view__campaignProgress___NtqKA {
    padding-left: 15px;
  }
}
.crowdfunding_detail_view__progress-bar___3ZxmG {
  margin-bottom: 32px;
}
.crowdfunding_detail_view__campaignTabs___3EbJZ {
  grid-area: campaignTabs;
}
.crowdfunding_detail_view__campaignSharing___3Rdod {
  grid-area: campaignSharing;
}
.crowdfunding_detail_view__campaignReporting___2qTD0 {
  grid-area: campaignReporting;
}
.crowdfunding_detail_view__charity___2ZrPg {
  grid-area: charity;
}
.crowdfunding_detail_view__emptyDiv___2COME {
  grid-area: emptyDiv;
}
.crowdfunding_detail_view__campaignReporting___2qTD0.crowdfunding_detail_view__gnc___2T5KP {
  margin-top: 2rem;
}
.crowdfunding_detail_view__emptyColumn___1iM3q {
  grid-area: emptyColumn;
}
.crowdfunding_detail_view__donations___1kHUf {
  margin: 16px 0;
  grid-area: donations;
}
.crowdfunding_detail_view__pageLoading___2ENMb {
  -ms-flex-align: center;
      align-items: center;
  background-color: rba(0, 0, 0, 0);
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -ms-flex-pack: center;
      justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 9999;
}
.crowdfunding_detail_view__share-button___1NlQA {
  display: inherit;
  margin-top: 16px;
}
.crowdfunding_detail_view__share-button___1NlQA button {
  width: 100%;
}
@media (max-width: 479px) {
  .crowdfunding_detail_view__campaign_container___3Xe5v {
    padding: 2rem 1rem;
    grid-column-gap: inherit;
    max-width: 100%;
    grid-row-gap: 15px;
    -ms-grid-columns: 100%;
        grid-template-columns: 100%;
    grid-template-areas: 'campaignCover' 'campaignHeader' 'campaignProgress' 'campaignManage' 'campaignTabs' 'charity' 'donations' 'campaignSharing' 'campaignReporting';
  }
  .crowdfunding_detail_view__campaignManage___1a-NU > a {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .crowdfunding_detail_view__findMoreLink___2Kbs6 {
    display: none;
  }
}
.crowdfunding_detail_view__gncDisclaimerContainer___1dd2P {
  max-width: 1040px;
  margin: 0 auto;
  padding: 48px 16px;
}
@media (min-width: 700px) {
  .crowdfunding_detail_view__fundraiser_header___iaAFV {
    margin-top: 1rem;
  }
}
.crowdfunding_detail_view__charity___2ZrPg {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 24px;
  background-color: #faf8f5;
  margin-top: 68px;
}
.crowdfunding_detail_view__p-15___1B6dL {
  padding: 15px;
}

.count_down__count_down_container___2cH8Y {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  margin-top: 8px;
}
.count_down__unit_container___yklnT {
  display: -ms-flexbox;
  display: flex;
}
.count_down__unit_container___yklnT > div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.count_down__unit_value___1M2C- {
  text-align: center;
}
.count_down__unit_text___3eg6m {
  margin-top: 7px;
}
.count_down__unit_separator___Rba8b {
  margin: 0 11px;
  line-height: 1;
}

.share_buttons__sub-text___NkpL3 {
  color: #001435;
  text-align: center;
}
@media (min-width: 768px) {
  .share_buttons__sub-text___NkpL3 {
    margin-top: 10;
  }
}
@media (min-width: 320px) and (max-width: 750px) {
  .share_buttons__sub-text___NkpL3 {
    margin-left: 10px;
    font-size: 20px !important;
  }
}

.share_button-modules__buttonContainer___3Lp_r {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
}
.share_button-modules__buttonContainer___3Lp_r div:nth-child(2) > span {
  margin-left: 18px;
  font-family: PayPalSansBig-Regular;
  font-size: 20px;
  line-height: 28px;
  width: 100%;
}
.share_button-modules__listContainer___2k5QD {
  margin: 0 0.375rem;
}
.share_button-modules__listContainer___2k5QD li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: 87px;
}

.donate_button__donate-button___3tDcl {
  margin-top: 0;
  margin-bottom: 0;
  display: block;
  min-width: 0;
  border: none;
  color: black;
  font-weight: bold;
  width: 100%;
}
.donate_button__donate-button___3tDcl.donate_button__preview___2cFqo {
  cursor: pointer !important;
  pointer-events: none !important;
  width: 100% !important;
}
.donate_button__donate-disabled-wrapper___2dSK3 {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 100px;
}
.donate_button__donate-disabled-text___1SsfW {
  padding: 2px 5px;
}

.share_campaign_compact__share-icons___1PGbw {
  text-align: center;
  margin: 22px 0 0;
}
.share_campaign_compact__share-icons___1PGbw:empty {
  display: none;
}
.share_campaign_compact__share-icons___1PGbw .share_campaign_compact__share-icon___35fu4 {
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
  color: #142c8e;
  cursor: pointer;
  background-color: #f7f5f0 !important;
  padding: 10px;
  border-radius: 50%;
}
.share_campaign_compact__share-icons___1PGbw .share_campaign_compact__share-icon___35fu4:first-child {
  margin-left: 0;
}
.share_campaign_compact__share-icons___1PGbw .share_campaign_compact__share-icon___35fu4:last-child {
  margin-right: 0;
}
@media (min-width: 700px) and (max-width: 1160px) {
  .share_campaign_compact__share-icons___1PGbw .share_campaign_compact__share-icon___35fu4 {
    margin-left: 3px;
    margin-right: 3px;
  }
  .share_campaign_compact__share-icons___1PGbw .share_campaign_compact__share-icon___35fu4 .share_campaign_compact__sub-text___3ydkU {
    display: none;
  }
}
.share_campaign_compact__sub-text___3ydkU {
  color: #9DA3A6;
}
.share_campaign_compact__row___3F-Nh {
  padding: 0 0 1.5em;
  position: relative;
}

.copy_box__copy-box-wrapper___3NE47 {
  position: relative;
}
.copy_box__copy-box___t4btt {
  width: 100%;
  border-radius: 4px;
  padding: 16px 0;
  text-overflow: ellipsis;
  text-align: left;
  color: #2c2e2f;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: baseline;
      align-items: baseline;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-positive: 1;
      flex-grow: 1;
  background-color: #f7f5f0;
}
.copy_box__copy-box___t4btt input[type='text'] {
  border: 0;
  margin: 0;
  padding: 0;
  outline: none;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 1.2;
  font-size: 16px;
  background-color: #f7f5f0;
  color: black;
}
.copy_box__copy-box-disabled___1j0l8 {
}
.copy_box__copy-box-disabled___1j0l8 input[type='text'] {
  color: #B7BCBF;
}
.copy_box__copy-link___sEyx7 {
  margin-left: 9px;
  position: relative;
}
.copy_box__copy-link___sEyx7 a {
  font-size: 0.9375rem;
  line-height: 1.6;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
  color: #142c8e;
  padding: 4px 12px 6px;
  border: 1px solid #142c8e;
  border-radius: 15px;
  font-size: 14px;
  background-color: transparent;
  font-family: 'PayPal-Sans-Big', sans-serif;
}
.copy_box__copied-tooltip___3Hn_t {
  background-color: #e1e7eb;
  color: #2c2e2f;
  border-radius: 2px;
  position: absolute;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  padding: 6px;
  bottom: -32px;
  /* @noflip */
  right: 0 /*rtl:ignore*/;
}
.copy_box__copied-tooltip___3Hn_t:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  margin-left: -6px;
  right: 18px;
  border-bottom: 6px solid #e1e7eb;
  top: -6px;
}
.copy_box__tooltip-enter___Qp8Xo {
  opacity: 0.01;
}
.copy_box__tooltip-enter___Qp8Xo.copy_box__tooltip-enter-active___2u5Ce {
  opacity: 1;
  transition: opacity 250ms ease-out;
}
.copy_box__tooltip-leave___3B3PY {
  opacity: 1;
}
.copy_box__tooltip-leave___3B3PY.copy_box__tooltip-leave-active___3EXNP {
  opacity: 0.01;
  transition: opacity 250ms ease-out;
}

.report_link__report-link-container___2tmkR {
  margin-top: 1rem;
  text-align: center;
}
.report_link__report-link-container___2tmkR > a {
  color: #1072eb;
}

.modal-enter {
  transform: translateY(100%);
  will-change: transform;
}
.modal-enter-active {
  transform: translateY(0%);
  transition: transform 250ms;
}
.modal-enter-active ~ * {
  pointer-events: none;
}
.modal-leave {
  transform: translateY(0%);
  will-change: transform;
}
.modal-leave.modal-leave-active {
  transform: translateY(100%);
  transition: transform 250ms;
}

.ppgf_inset__margin-bottom-12___23IQ4 {
  margin-bottom: 12px;
}
.ppgf_inset__ppgf-all-items-container___1dOvi {
  overflow-x: auto;
  overflow-y: hidden;
}
.ppgf_inset__ppgf-flex-container___3uI5p {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: auto;
  overflow-x: auto;
  overflow-y: hidden;
}
@media (max-width: 700px) {
  .ppgf_inset__ppgf-flex-container___3uI5p {
    display: block;
    max-width: 100%;
    margin: auto;
    height: auto;
  }
}
.ppgf_inset__ppgf-step-detail___gjBOh {
  display: block;
}
.ppgf_inset__ppgf-step-detail___gjBOh img {
  height: 117.33px;
  margin: 0 auto;
  display: block;
}
@media (max-width: 700px) {
  .ppgf_inset__ppgf-step-detail___gjBOh {
    display: -ms-flexbox;
    display: flex;
    max-height: auto;
    text-align: center;
    margin-bottom: 35px;
  }
  .ppgf_inset__ppgf-step-detail___gjBOh img {
    height: 50px;
    width: 50px;
  }
}
.ppgf_inset__ppgf-right-arrow-container___1gwXm {
  text-align: center;
  max-height: 173px;
  -ms-flex: 0 0 72px;
      flex: 0 0 72px;
}
.ppgf_inset__more-details-button___1TU8j {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 30px;
  overflow-x: auto;
}
.ppgf_inset__ppgf-container___1POeK {
  max-width: 1112px;
  margin: 0 auto;
  padding: 0 16px;
}
.ppgf_inset__ppgf-container-title___1mQ02 {
  padding-top: 71.5px;
  text-align: center;
  margin-bottom: 24px;
}
@media (max-width: 700px) {
  .ppgf_inset__ppgf-container-title___1mQ02 {
    padding-top: 48px;
    margin-bottom: 25px;
    text-align: center;
  }
}
.ppgf_inset__sub-text___1psL9 {
  padding: 10px 24px;
}
@media (max-width: 700px) {
  .ppgf_inset__sub-text___1psL9 {
    padding-top: 0;
    text-align: left;
  }
}
.ppgf_inset__ppgf-carousel-container___1rg54 {
  width: 92%;
  max-width: 1300px;
  text-align: center;
  margin: 5px auto 24px;
}
@media (max-width: 1200px) {
  .ppgf_inset__ppgf-carousel-container___1rg54 {
    margin: 10px auto 5px;
  }
}
@media (max-width: 767px) {
  .ppgf_inset__ppgf-carousel-container___1rg54 {
    margin: 10px auto 5px;
    width: 320px;
  }
}
.ppgf_inset__ppgf-carousel-title___20pG9 {
  padding: 48px 0 30px;
  text-align: center;
}
@media (max-width: 767px) {
  .ppgf_inset__ppgf-carousel-title___20pG9 {
    padding: 24px 0 30px;
    font-size: 28px;
    line-height: 1.2;
  }
}
.ppgf_inset__ppgf-carousel-image___1KsXe {
  margin: auto;
  padding: 0 20px 24px;
}
@media (max-width: 1063px) {
  .ppgf_inset__ppgf-carousel-image___1KsXe {
    padding: 0 10px 20px;
    width: 136px;
  }
}
.ppgf_inset__ppgf-carousel-slide___jc0Fi {
  width: 50%;
  text-align: center;
  margin: auto;
}
@media (max-width: 479px) {
  .ppgf_inset__ppgf-carousel-slide___jc0Fi {
    width: 90%;
  }
}
.ppgf_inset__ppgf-container-title-small___2C0YE {
  padding-top: 48px;
  margin-bottom: 0;
  text-align: center;
}
.ppgf_inset__ppgf-horizontal-scroll___NfqND {
  max-height: 366px;
}
.ppgf_inset__ppgf-step-text___27q9a {
  text-align: left;
  padding-top: unset;
}
.ppgf_inset__ppgf-step-text___27q9a a {
  color: #1072eb;
}
.ppgf_inset__ppgf-button-container___1X_na {
  text-align: center;
  margin: 48px 0;
}
@media (max-width: 1063px) {
  .ppgf_inset__ppgf-button-container___1X_na {
    margin: 24px 0;
  }
}

.charity_details_modal__margin-bottom-24___o6j3l {
  margin-bottom: 24px;
}
.charity_details_modal__charity-modal-content-header___1KQup {
  margin-bottom: 16px;
}
.charity_details_modal__charity-modal-content___3VRry {
  padding: 0 6%;
}
@media (min-width: 426px) {
  .charity_details_modal__charity-modal-content___3VRry {
    padding: 0 12%;
  }
}
@media (min-width: 768px) {
  .charity_details_modal__charity-modal-content___3VRry {
    padding: 0 19%;
  }
}
.charity_details_modal__charity-heart-image___14sme {
  margin-bottom: 24px;
}
.charity_details_modal__charity-heart-image___14sme img {
  width: 106px;
  height: 98px;
}
.charity_details_modal__charity-details-modal-body___3Ob1_ > ol {
  list-style-type: none;
  margin: 0;
}
.charity_details_modal__charity-details-modal-body___3Ob1_ > ol li {
  width: 100%;
  margin: 16px 0;
}

.standard_error__large-icon___iKWcY {
  height: 98px;
  width: 98px;
  display: block;
  margin: 0 auto 26px;
  line-height: 98px;
}
.standard_error__large-icon___iKWcY::before {
  position: relative;
  top: -25px;
  left: -17px;
}
.standard_error__large-icon___iKWcY.icon-attention-large {
  color: #FF9600;
}
.standard_error__large-icon___iKWcY.icon-critical-large {
  color: #D20000;
}
.standard_error__standardError___hndaU {
  text-align: center;
}
.standard_error__standardError___hndaU .standard_error__error-icon___1g5Vx {
  width: 160px;
  margin-bottom: 30px;
}
.standard_error__standardError___hndaU .standard_error__custom-icon___365AX {
  margin-bottom: 30px;
}
@media (max-width: 426px) {
  .standard_error__standardError___hndaU {
    padding: 0 16px;
  }
}
.standard_error__title___3oBrF {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 12px;
}
.standard_error__subtitle___3c6CF {
  text-align: center;
  margin-bottom: 36px;
}
.standard_error__subtitle___3c6CF:last-child {
  margin-bottom: 0;
}
.standard_error__iconAlign___2wVY2 {
  margin-bottom: 17px;
}

.payments_token_sheet__error-wrapper___2Zpju {
  padding: 2rem;
  margin: auto;
}
.payments_token_sheet__back-button___2vX3s {
  width: 90%;
}
.payments_token_sheet__container_class___16dWU {
  max-width: 295px;
  height: 365px;
}
@media (min-width: 426px) {
  .payments_token_sheet__container_class___16dWU {
    max-width: 452px;
  }
}

.mock_text__mock-text-container___3gSqM {
  height: 1em;
  display: inline-block;
}
.mock_text__mock-text-aligner___2f1S0 {
  height: 1em;
  vertical-align: middle;
  display: inline-block;
}
.mock_text__mock-text___3-He- {
  display: inline-block;
  height: 0.5em;
  background-color: #eff1f5;
}

.enrich_charity_card__charity-row-one___2u2oC {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  width: 100%;
  -ms-flex-align: center;
      align-items: center;
}
@media (max-width: 479px) {
  .enrich_charity_card__charity-row-one___2u2oC {
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: start;
        align-items: start;
  }
}
@media (max-width: 700px) {
  .enrich_charity_card__charity-row-one___2u2oC {
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: start;
        align-items: start;
  }
}
.enrich_charity_card__charity-row-one___2u2oC div.enrich_charity_card__charity-name-section___2KKYp {
  margin-left: 8px;
}
@media (max-width: 479px) {
  .enrich_charity_card__charity-row-one___2u2oC div.enrich_charity_card__charity-name-section___2KKYp {
    margin-left: 0;
    margin-top: 8px;
  }
}
@media (max-width: 700px) {
  .enrich_charity_card__charity-row-one___2u2oC div.enrich_charity_card__charity-name-section___2KKYp {
    margin-left: 0;
    margin-top: 8px;
  }
}
.enrich_charity_card__charity-row-one___2u2oC div > img {
  object-fit: scale-down;
}
.enrich_charity_card__charity-row-two___3f2Vf {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  margin-top: 26px;
}
.enrich_charity_card__web-icon___1yTmD {
  margin-left: 35px;
  cursor: pointer;
}
.enrich_charity_card__alert___3xKQ8 {
  background-color: #faf8f5 !important;
  margin-top: 10px;
}
.enrich_charity_card__un-enrolled-link___3fuCe {
  margin-top: 40px;
}

.paypal_page__content-inside___1vVPu {
  padding-bottom: 210px;
  background: #f7f5f0;
}
.paypal_page__content-inside___1vVPu.paypal_page__white-bg___1wDM3 {
  background: #ffffff;
}
.paypal_page__content-inside___1vVPu.paypal_page__no-header___1psOo {
  padding-top: 0;
}
.paypal_page__content-inside___1vVPu.paypal_page__no-footer___1hU7b {
  padding-bottom: 0;
}
.paypal_page__clear___jyj-e {
  clear: both;
}
.paypal_page__marketing-header-background___z8HKG {
  background: white;
}
.paypal_page__marketing-header-background___z8HKG header::before {
  height: 72px !important;
}
@media (max-width: 768px) {
  .paypal_page__marketing-header-background___z8HKG {
    height: 72px;
  }
}
div.paypal_page__background___2oWZv {
  background: #ffffff;
  padding-bottom: 16px;
  z-index: 0 !important;
}
.paypal_page__sticky-footer-container___1DBEn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.paypal_page__sticky-footer___25Q75 {
  margin-top: auto;
}

.index__header__container___J46pc > div {
  height: 3.5rem;
}
@media (min-width: 768px) {
  .index__header__container___J46pc {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 901;
  }
}

.crowdfunding_banner__crowdfunding-banner___1UpPE {
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  min-height: 4.2rem;
  padding: 1.3rem;
}
.crowdfunding_banner__crowdfunding-banner-icon___3ZyTs {
  margin-right: 28px;
}
@media (max-width: 479px) {
  .crowdfunding_banner__crowdfunding-banner-icon___3ZyTs {
    margin-right: 12px;
  }
}
@media (max-width: 479px) {
  .crowdfunding_banner__crowdfunding-banner-button___EogfS {
    margin-top: 1rem;
    margin-left: 29px;
  }
}
.crowdfunding_banner__banner-text___390Bk {
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
@media (max-width: 479px) {
  .crowdfunding_banner__banner-text___390Bk {
    padding: 0;
  }
}
@media (min-width: 1024px) {
  .crowdfunding_banner__entryBannerContainer___mziEd {
    margin-top: 4.5rem;
  }
}

svg.icon__icon___E40AR {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
svg.icon__icon___E40AR.icon__stroke-color-black___3FTeH,
svg.icon__icon___E40AR.icon__stroke-color-black___3FTeH * {
  fill: #2c2e2f;
  stroke: #2c2e2f;
}
svg.icon__icon___E40AR.icon__stroke-color-blue___2cWKT,
svg.icon__icon___E40AR.icon__stroke-color-blue___2cWKT * {
  fill: #0070BA;
  stroke: #0070BA;
}
svg.icon__icon___E40AR.icon__stroke-color-white___TqD_t,
svg.icon__icon___E40AR.icon__stroke-color-white___TqD_t * {
  fill: white;
  stroke: white;
}
svg.icon__icon___E40AR.icon__disable-fill___1XBGp,
svg.icon__icon___E40AR.icon__disable-fill___1XBGp * {
  fill: none;
}
svg.icon__icon___E40AR:not(.icon__disable-fill___1XBGp),
svg.icon__icon___E40AR:not(.icon__disable-fill___1XBGp) * {
  stroke: none;
}

.profile_cover__profile-cover__creator-name___1b5oS {
  margin: 0 0 0 0.4rem;
}
@media (max-width: 700px) {
  .profile_cover__profile-cover__creator-name___1b5oS {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
@media (max-width: 320px) {
  .profile_cover__profile-cover__creator-name___1b5oS {
    padding-right: 1.5rem;
  }
}
.profile_cover__profile-cover___2x6kC {
  position: absolute;
  bottom: -65px;
  left: 10px;
}
.profile_cover__profile-avatar___3hJ5W {
  margin: 'auto';
  margin-bottom: 10px;
}

.avatar_collection__avatar-container___3_TeD {
  margin-bottom: 10px;
}

.standard_dialog__title___20Wpm {
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 10px;
}
.standard_dialog__subtitle___1uldV {
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 16px;
}
.standard_dialog__close-button___2Qscn {
  margin-top: 12px;
}
.standard_dialog__ok-button___2YcDu {
  margin-bottom: 0;
}
.standard_dialog__delete-button___1S2RZ {
  margin-bottom: 0;
  width: 100%;
  color: #fff !important;
}
.standard_dialog__dialog-content___z_n6M {
  padding: 0 70px 72px;
}
@media (max-width: 700px) {
  .standard_dialog__dialog-content___z_n6M {
    padding: 0 22px 22px;
  }
}
.standard_dialog__icon___1p7Bq {
  text-align: center;
  margin-bottom: 17px;
}

.dialog__dialog-container___10lBh {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(244, 246, 249, 0.4);
  z-index: 10000;
}
.dialog__dialog___3WB3L {
  position: absolute !important;
  top: 50%;
  left: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  max-height: 474px;
  min-height: 300px;
  border-radius: 6px;
}
.dialog__dialog___3WB3L section {
  padding: 0;
}
@media (max-width: 700px) {
  .dialog__dialog___3WB3L section {
    padding: 57px 18px 40px;
  }
}
.dialog__small-dialog___2ePtF {
  width: 340px !important;
}
.dialog__medium-dialog___3iU5n {
  width: 452px !important;
}
.dialog__large-dialog___Lvnhn {
  width: 612px !important;
}
@media (max-width: 700px) {
  .dialog__small-dialog___2ePtF,
  .dialog__medium-dialog___3iU5n,
  .dialog__large-dialog___Lvnhn {
    width: 340px !important;
  }
}
.dialog__dialog-contents___1J8VL {
  padding: 63px 70px 72px;
}
@media (max-width: 700px) {
  .dialog__dialog-contents___1J8VL {
    padding: 57px 40px 40px;
  }
}
.dialog__x-button___1VjCa {
  line-height: 1;
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1;
}

.edit_main_cf__ended-title___cu7VR {
  margin-bottom: 12px;
}
.edit_main_cf__title___gprp2 {
  word-wrap: break-word;
  margin-bottom: 36px;
}
.edit_main_cf__goal-cover-title___3gGk_ {
  font-weight: bold;
  margin-bottom: 6px;
}
.edit_main_cf__textbox-wrapper___3wDrm {
  margin: 12px 0;
}
.edit_main_cf__textbox-wrapper___3wDrm:last-child {
  margin-bottom: 20px;
}
.edit_main_cf__category___2v9sv {
  margin-bottom: 16px;
}
.edit_main_cf__fs-option___BXg0h {
}
.edit_main_cf__description-container___3DgaM {
  width: 100%;
  margin-bottom: 36px;
}
.edit_main_cf__textbox-wrapper___3wDrm {
  position: relative;
}
.edit_main_cf__char-count___3Js5b {
  text-align: right;
  position: absolute;
  bottom: 8px;
  right: 16px;
}
.edit_main_cf__uploader___UyFWe {
  margin-bottom: 40px;
}
.edit_main_cf__delete-link___h5Z8d {
  display: -ms-flexbox;
  display: flex;
}
.edit_main_cf__delete-icon___13Mag {
  font-size: 16px;
  margin-top: 3px;
  margin-right: 3px;
  cursor: pointer;
}
.edit_main_cf__save-button___3l7Lu {
  margin-top: 36px;
}
.edit_main_cf__modal___H847p {
  z-index: 2000;
  width: 936px;
}
@media (max-width: 414px) {
  .edit_main_cf__modal___H847p {
    width: 100%;
  }
}
.edit_main_cf__modal_title___na1xS {
  text-align: center;
  padding-left: 48px;
}
.edit_main_cf__modal_title___na1xS {
  position: absolute;
  width: calc(90%);
  -ms-flex-align: center;
      align-items: center;
}
@media (max-width: 414px) {
  .edit_main_cf__modal_title___na1xS {
    width: 75%;
  }
}
.edit_main_cf__btn___2fXEm {
  width: 100%;
  display: block;
  margin-bottom: 16px;
}
.edit_main_cf__charity-header___2vxBH {
  color: #2c2e2f;
  margin-bottom: 8px;
  font-weight: bold;
}
.edit_main_cf__charity-subtitle___N_Vpq {
  color: 687173;
  font-size: 14px;
  margin-bottom: 16px;
}
.edit_main_cf__detail-title___aodIf {
  margin-top: 36px;
  margin-bottom: 21px;
}

.transfer_review_box__detail-name___1VHnH {
  margin-top: 12px;
}
.transfer_review_box__detail-name___1VHnH:first-child {
  margin-top: 0;
}

.delete_review__title___UNSzF {
  margin-bottom: 24px;
}
.delete_review__delete-button___384_e {
  margin: 36px auto 0;
}

.pledge__pledge__success___uVLM8 {
  text-align: center;
  margin-top: 133px;
}
@media (max-width: 760px) {
  .pledge__pledge__success___uVLM8 {
    margin-top: 0;
  }
}
.pledge__form__title___3u9i7 {
  margin-bottom: 1.5rem;
}
.pledge__form__currency-input___rovaI {
  margin-bottom: 0.5rem;
}
.pledge__success__icon___2kKxq {
  width: 120px;
}
.pledge__success__title___1bNQ2 {
  margin: 2.5rem 0 2.25rem;
}
.pledge__cta___1nrJm {
  margin-top: 60px;
  text-align: center;
}

.crowdfunding_share__spinner-title___PUQXo {
  text-align: center;
  margin-bottom: 10px;
}
.crowdfunding_share__spinner-title___PUQXo:last-child {
  margin-bottom: 0;
}
.crowdfunding_share__mb-16___ji_Uw {
  margin-bottom: 16px;
}
.crowdfunding_share__mb-24___1Lmok {
  margin-bottom: 24px;
}
.crowdfunding_share__mb-2rem___pfKmF {
  margin-bottom: 2rem;
}
.crowdfunding_share__create-indicator-container___2Qzmn {
  width: 100%;
}
.crowdfunding_share__go-to-pool-button___uxo_f {
  width: 100%;
}
.crowdfunding_share__feedback___2jy7g {
  text-align: center;
}
.crowdfunding_share__feedback-button___3abkv {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  margin-bottom: 32px;
  margin-top: 24px;
}
.crowdfunding_share__create-indicator___3uxuB {
  height: 98px;
  width: 98px;
  display: block;
  margin: 0 auto 26px;
  color: #00CF92;
  line-height: 98px;
}
.crowdfunding_share__create-indicator___3uxuB::before {
  position: relative;
  top: -25px;
  left: -17px;
}
.crowdfunding_share__title___32UPS {
  text-align: center;
  margin-bottom: 8px;
}
.crowdfunding_share__subtitle___Iy7PZ {
  margin-bottom: 32px;
  text-align: left;
}
.crowdfunding_share__textarea-wrapper___2OilI {
  margin-bottom: 32px;
  margin-top: 24px;
}
.crowdfunding_share__success___2D48U {
  background-image: url('https://www.paypalobjects.com/paypal-ui/illustrations/svg/success.svg');
  background-position: top center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 100%;
  height: 128px;
  vertical-align: top;
  margin-bottom: 30px;
  margin-top: -148px;
}
.crowdfunding_share__img-container-header___1s0Ag {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  width: 100%;
}
.crowdfunding_share__img-container-header___1s0Ag img {
  height: 180px;
  width: 180px;
}
@media (max-width: 426px) {
  .crowdfunding_share__publish-container___WwJ3A {
    padding: 0 16px;
  }
}
.crowdfunding_share__mshare-container___2NcS4 {
  height: auto !important;
}

.campaign_eligibility__eligibilty-errors___2Q0K7 {
  text-align: center;
}
.campaign_eligibility__icon-container___1vYF5 {
  width: 116px;
  height: 116px;
  display: inline-block;
  margin-bottom: 35px;
}
.campaign_eligibility__title___zFTit {
  margin-bottom: 8px;
}
.campaign_eligibility__description___1qliN {
  margin-bottom: 36px;
}
.campaign_eligibility__go-to-account-button___1SB9z {
  margin-top: 10px;
}

.share_update_activity__errorMsg___NWsGD {
  margin-bottom: 16px;
  margin-left: 6px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
}
.share_update_activity__sub-text___29zvP {
  margin-left: 8px;
  color: #9DA3A6;
}
.share_update_activity__copy-box-wrapper___3SNoM {
  position: relative;
}
.share_update_activity__copy-box-wrapper___3SNoM span {
  vertical-align: initial !important;
}
a.share_update_activity__copy-wrapper___1giRy {
  text-decoration: none;
}
a.share_update_activity__copy-wrapper___1giRy span {
  color: #142c8e;
}
a.share_update_activity__copy-wrapper___1giRy:focus {
  outline: -webkit-focus-ring-color auto 1px;
}
.share_update_activity__copied-tooltip___3Pwgm {
  background-color: #e1e7eb;
  color: #2c2e2f;
  border-radius: 2px;
  position: absolute;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  padding: 6px;
  bottom: -32px;
  /* @noflip */
  right: 0 /*rtl:ignore*/;
}
.share_update_activity__copied-tooltip___3Pwgm:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  margin-left: -6px;
  right: 18px;
  border-bottom: 6px solid #e1e7eb;
  top: -6px;
}
.share_update_activity__tooltip-enter___rIw7Z {
  opacity: 0.01;
}
.share_update_activity__tooltip-enter___rIw7Z.share_update_activity__tooltip-enter-active___3fgMR {
  opacity: 1;
  transition: opacity 250ms ease-out;
}
.share_update_activity__tooltip-leave___WKa2p {
  opacity: 1;
}
.share_update_activity__tooltip-leave___WKa2p.share_update_activity__tooltip-leave-active___Hzwsn {
  opacity: 0.01;
  transition: opacity 250ms ease-out;
}
.share_update_activity__share-icon-color___3PaZH {
  color: #142c8e;
}
.share_update_activity__share-button-container___cD2rV {
  font-weight: normal;
  margin-bottom: 60px;
}
@media (min-width: 320px) and (max-width: 750px) {
  .share_update_activity__share-button-container___cD2rV {
    margin-bottom: 36px;
  }
}
.share_update_activity__share-button-container___cD2rV .share_update_activity__share-icon___9HIUJ {
  color: #142c8e;
}
.share_update_activity__share-button-container___cD2rV .share_update_activity__share-label___2YiOn {
  margin-top: 8px;
  text-align: center;
}
.share_update_activity__share-button-container___cD2rV .share_update_activity__share-label-cursor___CElNV {
  cursor: pointer;
  margin-top: 5px !important;
  color: #001435;
}
@media (min-width: 320px) and (max-width: 750px) {
  .share_update_activity__share-button-container___cD2rV .share_update_activity__share-label-cursor___CElNV {
    margin-top: 0 !important;
  }
}
@media (min-width: 768px) {
  .share_update_activity__share-button-container___cD2rV .share_update_activity__share-icon___9HIUJ {
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    background-color: #f7f5f0;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    -ms-flex-align: center;
        align-items: center;
    margin: auto !important;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: justify;
        justify-content: space-between;
  }
  .share_update_activity__share-button-container___cD2rV .share_update_activity__share-icon___9HIUJ span {
    margin-top: 5px;
  }
}
@media (min-width: 320px) and (max-width: 750px) {
  .share_update_activity__share-button-container___cD2rV {
    display: -ms-flexbox;
    display: flex;
  }
  .share_update_activity__share-button-container___cD2rV .share_update_activity__share-icon___9HIUJ {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: justify;
        justify-content: space-between;
  }
  .share_update_activity__share-button-container___cD2rV .share_update_activity__share-label___2YiOn {
    margin-left: 10px;
    margin-top: 2px;
    font-size: 18px !important;
  }
}

.fundraiser_share_info__mb24___2nFJ6 {
  margin-bottom: 24px;
}
.fundraiser_share_info__title___3biuh {
  text-align: center;
  margin-bottom: 8px;
}
.fundraiser_share_info__subtitle___1d0fh {
  margin-bottom: 32px;
  text-align: left;
}
.fundraiser_share_info__footerInfo___Z7S6P {
  margin-bottom: 24px;
  font-size: 14px !important;
}

.transfer__title___2ords {
  margin-bottom: 36px;
}
.transfer__amount-container___2Wnjn {
  margin-top: 24px;
  margin-bottom: 36px;
}
.transfer__amount-description___hn4C- {
  margin-top: 4px;
}
.transfer__transfer-btn___1tfc9 {
  margin-top: 33px;
  text-align: center;
}

.transfer_complete__title___ZmINz {
  text-align: center;
}
.transfer_complete__done-button___226y0 {
  margin: 0 auto 12px;
}
.transfer_complete__sub-title___1YJa5 {
  text-align: center;
  margin-top: 12px;
}
.transfer_complete__actions___pO2Ag {
  margin-top: 48px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.transfer_complete__withdraw-button___7MC2u {
  width: 343px;
}
.transfer_complete__success-image___YxyUr {
  margin-bottom: 36px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.transfer_complete__transfer-container___3Eb_9 {
  padding: 0 16px;
}

.post_update_cf__ended-title___1bHoP {
  margin-bottom: 12px;
}
.post_update_cf__title___195pB {
  margin-bottom: 20px;
  text-align: center;
}
.post_update_cf__subtitle___10e56 {
  margin-bottom: 20px;
}
.post_update_cf__textbox-wrapper___1JY2B {
  margin: 12px 0;
}
.post_update_cf__textbox-wrapper___1JY2B:last-child {
  margin-bottom: 20px;
}
.post_update_cf__description-container___38F5c {
  width: 100%;
}
.post_update_cf__textbox-wrapper___1JY2B {
  position: relative;
}
.post_update_cf__delete-link___3n0oZ {
  display: -ms-flexbox;
  display: flex;
}
.post_update_cf__delete-icon___14cFo {
  font-size: 16px;
  margin-top: 3px;
  margin-right: 3px;
  cursor: pointer;
}
.post_update_cf__save-button___2pe6C {
  margin-top: 36px;
  width: 100%;
}
.post_update_cf__delete-update___3lzzL {
  margin-top: 36px;
}

.delete_update_activity__delete-update___19Ifl {
  margin-top: 36px;
}

.postupdate_share__title___2IDaC {
  text-align: center;
  margin-bottom: 8px;
}
.postupdate_share__subtitle___Dszew {
  margin-bottom: 32px;
  text-align: center;
}
.postupdate_share__textarea-wrapper___317pc {
  margin-bottom: 32px;
}
.postupdate_share__img-container-header___anf-n {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  width: 100%;
}
.postupdate_share__img-container-header___anf-n img {
  height: 160px;
  width: 160px;
}
@media (max-width: 700px) {
  .postupdate_share__img-container-header___anf-n img {
    height: 136px;
    width: 136px;
  }
}
@media (max-width: 700px) {
  .postupdate_share__publish-container___2kQIx {
    padding: 0 16px;
  }
}

.post_update_error__topAlign___r1X-r {
  margin-top: 40px;
}
.post_update_error__extraContent___rynOa {
  margin-top: 26px;
}

.view_updates__title___2oiqV {
  margin-bottom: 12px;
}
.view_updates__list-container___3Adoq {
  overflow: scroll;
}
.view_updates__list-container___3Adoq:not(:last-child) {
  margin-bottom: 30px;
}
.view_updates__activity-title___3RpP4 {
  margin-bottom: 32px;
}
.view_updates__show-more___3KpVv {
  text-align: center;
  padding: 10px 0 20px;
  cursor: pointer;
}
.view_updates__update-heading___37WbG {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
}
.view_updates__activity-empty___3QaE3 {
  background-color: #f7f5f0 !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.7rem !important;
  margin: 20px 0;
}
@media (max-width: 700px) {
  .view_updates__activity-empty___3QaE3 {
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.view_updates__empty-image___1Drku {
  height: 42px;
  width: 43px;
  padding-right: 11px;
}
@media (max-width: 700px) {
  .view_updates__empty-update-text___rIigE {
    width: 200px;
  }
}
.view_updates__empty-post-button___30GIQ {
  width: 47% !important;
}
@media (max-width: 700px) {
  .view_updates__empty-post-button___30GIQ {
    width: 50% !important;
    margin-top: 10px;
  }
}
@media (max-width: 900px) {
  .view_updates__empty-post-button___30GIQ {
    width: 77% !important;
  }
}
a.view_updates__postupdate-link___1CMA9:focus {
  display: inline-block;
  border-radius: 50%;
}
.view_updates__postupdate-icon___3XjTB {
  border: 1px solid #142c8e !important;
  padding: 5px !important;
}
@media (max-width: 700px) {
  .view_updates__list-updates___2yC0v {
    padding: 0 16px;
  }
}

.campaign_not_found__campaign-not-found___Q78tj {
  text-align: center;
}
.campaign_not_found__icon-container___3BSjb {
  width: 116px;
  height: 116px;
  display: inline-block;
  margin-bottom: 35px;
}
.campaign_not_found__title___1yDZ7 {
  margin-bottom: 8px;
}
.campaign_not_found__description___2xqIq {
  margin-bottom: 36px;
}
.campaign_not_found__cta-button___1qzHA {
  margin-top: 10px;
}

.manage_all_page__container___dMkfG {
  width: 100%;
}
.manage_all_page__container__top___3LKDE {
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  max-width: 1320px;
  padding: 32px 24px;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.manage_all_page__container__body___3Q2rN {
  width: 100%;
  background-color: #fff;
}
.manage_all_page__body__content___2_K2c {
  margin: 0 auto;
  max-width: 1320px;
  display: -ms-flexbox;
  display: flex;
}
.manage_all_page__content__sidebar___v6Sws {
  z-index: 1;
  width: 440px;
  padding: 32px 16px 0;
  box-shadow: 20px 0 10px -8px rgba(0, 0, 0, 0.08);
}
.manage_all_page__content__details___3zjmK {
  float: none;
  overflow: hidden;
  -ms-flex: 1;
      flex: 1;
  background-color: #fff;
  padding: 32px 24px 0 49px;
}
.manage_all_page__sidebar__cards___2b4Sl {
  height: 100%;
  padding: 15px 12px 0 0;
  overflow-y: auto;
}
.manage_all_page__cards__start-fundraiser-btn___N5Bd3 {
  display: none;
}
.manage_all_page__visible-md___1C29I {
  display: none !important;
}
@media (max-width: 72rem) {
  .manage_all_page__container__top___3LKDE {
    padding-left: 44px;
    padding-right: 44px;
  }
  .manage_all_page__content__sidebar___v6Sws {
    width: 407px;
    z-index: 0;
  }
  .manage_all_page__content__details___3zjmK {
    padding-left: 24px;
  }
}
@media (max-width: 64rem) {
  .manage_all_page__content__sidebar___v6Sws {
    width: 364px;
  }
}
@media (max-width: 47rem) {
  .manage_all_page__container__top___3LKDE {
    padding: 24px 16px;
  }
  .manage_all_page__top__heading-text___1NSVa {
    font-size: 1.75rem !important;
  }
  .manage_all_page__content__sidebar___v6Sws {
    width: 100%;
    padding: 16px;
  }
  .manage_all_page__content__details___3zjmK {
    position: absolute;
    width: 100%;
    top: 0;
    left: 100%;
    transition: left 0.25s;
    height: 100%;
    overflow-y: auto;
    padding: 21px 48px 0;
  }
  .manage_all_page__cards__start-fundraiser-btn___N5Bd3 {
    display: block;
    text-align: center;
    margin-left: 10px;
  }
  .manage_all_page__visible-md___1C29I {
    display: block !important;
  }
  .manage_all_page__hidden-md___2lCin {
    display: none !important;
  }
}
@media (max-width: 36rem) {
  .manage_all_page__content__details___3zjmK {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.manage_all_page__content__details--slide-in___1zwen {
  left: 0;
}

.left_side_hub_campaign__card--selected___KnRhn::after {
  content: '';
  position: absolute;
  top: -0.0625rem;
  left: -0.0625rem;
  border: 0.1875rem solid #0070e0;
  box-shadow: 0 0 0 0.375rem rgba(209, 229, 249, 0.9);
  border-radius: inherit;
  width: calc(100.125%);
  height: calc(100.125%);
}
.left_side_hub_campaign__campaign__icon___29Jdv {
  color: #003087;
  vertical-align: middle;
}
.left_side_hub_campaign__card__content___3-V1O {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  margin: 0 !important;
  padding: 0;
  height: 200px;
}
.left_side_hub_campaign__content__column-left___2kih7 {
  -ms-flex: 2;
      flex: 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 16px 21px 14px 16px;
}
.left_side_hub_campaign__content__column-right___-9_q- {
  -ms-flex: 1;
      flex: 1;
  overflow: hidden;
  -ms-flex-preferred-size: 20px;
      flex-basis: 20px;
}
.left_side_hub_campaign__column-left__top___1PmFE,
.left_side_hub_campaign__column-left__bottom___3zSPe {
  -ms-flex-positive: 0;
      flex-grow: 0;
}
.left_side_hub_campaign__column-left__top___1PmFE,
.left_side_hub_campaign__middle__formatted-amount___Rd0HH {
  margin-bottom: 8px;
}
.left_side_hub_campaign__column-left__middle___2jBXE {
  -ms-flex-positive: 1;
      flex-grow: 1;
}
.left_side_hub_campaign__card___3YuXf {
  padding: 0 !important;
  margin: 0 0 16px;
}

.empty_hub__zero-fundraiser___3IzHl {
  margin-top: 48px;
  text-align: center;
}
.empty_hub__zero-fundraiser__illustration-image___2I0h8 {
  width: 153px;
  margin-bottom: 24px;
}
.empty_hub__zero-fundraiser__title___362ok,
.empty_hub__zero-fundraiser__subtitle___3gJEp {
  margin-bottom: 16px;
}
.empty_hub__state-pictograms___2PeEi {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
}

.fundraiser_skeleton__zero-fundraiser___tjS6y {
  margin: 0 auto;
  max-width: 448px;
}

@media (max-width: 700px) {
}
.create__preview-animation-container___ZNS-f {
  position: relative;
}
.create__create-modal-enter___3Qxa1 {
  opacity: 0;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
.create__create-modal-enter-active___30myP {
  opacity: 1;
  transition: all 500ms ease-out;
  transition-delay: 250ms;
}
.create__create-modal-leave___3uckH {
  opacity: 1;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
.create__create-modal-leave-active___1g8xV {
  opacity: 0;
  transition: all 750ms ease-out;
  transition-delay: 0;
}
.create__preview-enter___37cKz {
  opacity: 0;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  overflow: hidden !important;
  height: 100% !important;
}
.create__preview-enter___37cKz::before {
  content: '';
  background-color: #F5F7FA;
  height: 100vh;
  width: calc((100% - 612px)/2);
  position: fixed;
  top: 0;
  z-index: 50001;
  left: 0;
  transform: translate3d(0%, 0, 0);
}
.create__preview-enter___37cKz::after {
  content: '';
  background-color: #F5F7FA;
  height: 100vh;
  width: calc((100% - 612px)/2);
  position: fixed;
  top: 0;
  z-index: 50001;
  right: 0;
  transform: translate3d(0%, 0, 0);
}
.create__preview-enter-active___2TOHR {
  opacity: 1;
  transition: all 750ms ease-out;
  transition-delay: 0;
}
.create__preview-enter-active___2TOHR::before {
  transition: transform 250ms ease-out;
  transition-delay: 500ms;
  transform: translate3d(-100%, 0, 0);
}
.create__preview-enter-active___2TOHR::after {
  transition: transform 250ms ease-out;
  transition-delay: 500ms;
  transform: translate3d(100%, 0, 0);
}
.create__preview-leave___1NFFO {
  opacity: 1;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  overflow: hidden !important;
  height: 100% !important;
}
.create__preview-leave___1NFFO::before {
  content: '';
  background-color: #F5F7FA;
  height: 100vh;
  width: calc((100% - 612px)/2);
  position: fixed;
  top: 0;
  z-index: 50001;
  left: 0;
  transform: translate3d(-100%, 0, 0);
}
.create__preview-leave___1NFFO::after {
  content: '';
  background-color: #F5F7FA;
  height: 100vh;
  width: calc((100% - 612px)/2);
  position: fixed;
  top: 0;
  z-index: 50001;
  right: 0;
  transform: translate3d(100%, 0, 0);
}
.create__preview-leave-active___1rtmw {
  opacity: 0;
  transition: all 500ms ease-out;
  transition-delay: 250ms;
}
.create__preview-leave-active___1rtmw::before {
  transition: transform 250ms ease-out;
  transition-delay: 0ms;
  transform: translate3d(0%, 0, 0);
}
.create__preview-leave-active___1rtmw::after {
  transition: transform 250ms ease-out;
  transition-delay: 0ms;
  transform: translate3d(0%, 0, 0);
}

@media (max-width: 700px) {
}
.create_fundraiser_error__settings-link___2-reK {
  margin-top: 12px;
  display: block;
  text-align: center;
}
.create_fundraiser_error__mt-10___1qg4n {
  margin-top: 10px;
}
.create_fundraiser_error__mb-25___1KEWA {
  margin-bottom: 25px;
}

.create_type_small_group__header-section___3h1NM {
  text-align: center;
}
.create_type_small_group__title___1V4O2 {
  margin-bottom: 16px;
  text-align: center;
  font-style: normal;
}
@media (max-width: 700px) {
  .create_type_small_group__title___1V4O2 {
    margin-top: 15px;
  }
}
.create_type_small_group__button-cf___LonAn {
  margin-bottom: 16px;
  width: 100%;
}
.create_type_small_group__top-alignment___3xgti {
  margin-top: 20px;
}

.create_card__content-wrapper___33NJx {
  display: -ms-flexbox;
  display: flex;
  margin: 0 4px 24px;
  cursor: pointer;
  justify-self: start;
  -ms-flex-align: center;
      align-items: center;
  padding: 1rem;
}
.create_card__content-wrapper___33NJx .create_card__content-icon___3cBar {
  margin: auto 16px auto -8px !important;
}
.create_card__content-wrapper___33NJx .create_card__content-tile___3WYAH {
  margin: 0;
}
a.create_card__card-link___1cMmR {
  text-decoration: none;
}
.create_card__caption-text___ZQUZE {
  color: #545d68 !important;
}
.create_card__caption-text-small-group___MXYbY {
  color: #0c0c0d !important;
}

.introduction__sheet__pictogram___3yqIO {
  text-align: center;
  margin-bottom: 32px;
}
.introduction__sheet__body___1-PAY {
  margin-bottom: 32px;
}
.introduction__sheet__disclaimer___AP-Vt {
  margin-bottom: 16px;
}
.introduction__sheet__disclaimer___AP-Vt a:focus {
  text-decoration: underline !important;
}
.introduction__body__title___1NYMs {
  margin-bottom: 16px;
  text-align: center;
}
.introduction__body__subtitle___3UtzJ {
  margin-bottom: 16px;
}
.introduction__sheet__cta___-nuXR {
  text-align: center;
}
.introduction__sheet__cta___-nuXR a {
  color: #ffffff !important;
}

.fundraiser_skeleton__plaque___3CyXK,
.fundraiser_skeleton__circle___1ULJu {
  width: 100%;
  float: left;
  border-radius: 0.75rem;
  background-color: #f1efea;
  border: 0.0625rem solid transparent;
}
.fundraiser_skeleton__circle___1ULJu {
  border-radius: 50%;
}
.fundraiser_skeleton__title___38m4P {
  height: 48px;
  margin-bottom: 16px;
}
.fundraiser_skeleton__badge___kgq6l {
  float: left;
  width: 136px;
  height: 28px;
  margin-right: 16px;
  margin-bottom: 32px;
}
.fundraiser_skeleton__amount___qoK5p {
  float: left;
  clear: both;
  width: 243px;
  height: 48px;
  margin-bottom: 8px;
}
.fundraiser_skeleton__progress-bar___2Sx36 {
  height: 20px;
  margin-bottom: 8px;
}
.fundraiser_skeleton__amount-raised___3PLjU {
  height: 20px;
  margin-bottom: 92px;
}
.fundraiser_skeleton__actions___1dAhS {
  float: left;
  margin-bottom: 92px;
}
.fundraiser_skeleton__actions__action___2sgAC {
  float: left;
  width: 64px;
  height: 92px;
  margin-right: 62px;
}
.fundraiser_skeleton__actions__action___2sgAC .fundraiser_skeleton__circle___1ULJu {
  width: 64px;
  height: 64px;
  margin-bottom: 8px;
}
.fundraiser_skeleton__actions__action___2sgAC .fundraiser_skeleton__plaque___3CyXK {
  height: 20px;
}
.fundraiser_skeleton__activity___3GvHv {
  height: 24px;
  margin-bottom: 24px;
}

.crowdfunding_basics__title___38lhg {
  text-align: center;
  margin-bottom: 32px;
}
.crowdfunding_basics__category___3M8Eh {
  margin-bottom: 16px;
}
.crowdfunding_basics__category-dropdown___1PNE2 select {
  height: 64px;
}
.crowdfunding_basics__textbox-wrapper___3eQzU {
  margin-bottom: 16px;
}
.crowdfunding_basics__textbox-wrapper___3eQzU:last-child {
  margin-bottom: 32px;
}
.crowdfunding_basics__textbox-wrapper___3eQzU.crowdfunding_basics__zipcode___21ZeA {
  margin-bottom: 12px;
}
.crowdfunding_basics__tip-wrapper___LC-EU {
  margin-bottom: 32px;
}
.crowdfunding_basics__charity-header___3Uy3D {
  color: #687173;
}
.crowdfunding_basics__gn-disclaimer___1cGYF {
  margin-top: 24px;
  margin-bottom: 24px;
}
.crowdfunding_basics__gn-disclaimer___1cGYF a {
  font-weight: 600;
}
.crowdfunding_basics__gn-disclaimer___1cGYF a:focus {
  text-decoration: underline !important;
}
.crowdfunding_basics__form__button___ix3Jk {
  text-align: center;
}

.crowdfunding_nonprofit__title____Vb-e {
  text-align: center;
  margin-bottom: 48px;
}
.crowdfunding_nonprofit__legend___3jy-l {
  margin-bottom: 1rem;
}
.crowdfunding_nonprofit__textbox-wrapper___vlbgt {
  margin-bottom: 62px;
  margin-top: 30px;
}
@media screen and (max-width: 540px) {
  .crowdfunding_nonprofit__textbox-wrapper___vlbgt {
    width: 95%;
    margin: 30px auto;
  }
}
.crowdfunding_nonprofit__thumbnail___2orwt {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  min-height: 1.875rem;
  margin-right: 1rem;
  width: 45px;
  min-width: 45px;
  height: auto;
}
.crowdfunding_nonprofit__item-text___eZFw0 {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: 2.125rem;
}
@media screen and (max-width: 592px) {
  .crowdfunding_nonprofit__item-text___eZFw0 {
    margin-right: 1rem;
  }
}

.type_ahead_search__suggestions___3HJ2- {
  border-radius: 0 0 5px 5px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  list-style: none;
  margin: 0;
  overflow-y: auto;
  padding: 0 0.25rem;
  width: 100%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
  background-color: #fff;
  max-height: 400px;
  border: solid 1px #cbd2d6;
}
.type_ahead_search__suggestionborder___1gCJL {
  border: none;
}
.type_ahead_search__suggestions___3HJ2- li {
  padding: 0.5rem 1rem;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -ms-flex-align: center;
      align-items: center;
}
.type_ahead_search__suggestions___3HJ2- li:hover {
  background-color: #f5f7fa;
}
.type_ahead_search__highlightedSuggestion___M2AEm {
  background-color: #f5f7fa;
}
.type_ahead_search__loadingSpinner___1hSS8 {
  padding-left: 45%;
  padding-top: 5%;
}
.type_ahead_search__search-input-border-radius___2LJKG input {
  border-radius: 36px !important;
}

.horizontal_scroller__card__content___eslCC {
  display: -ms-flexbox;
  display: flex;
  height: 160px;
  text-align: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 !important;
}
.horizontal_scroller__content__top___1N3DQ {
  -ms-flex-positive: 1;
      flex-grow: 1;
}
.horizontal_scroller__content__bottom___8qFMV {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.horizontal_scroller__top__image___MpAZv {
  height: 84px;
  max-width: 99%;
  display: none;
}
.horizontal_scroller__top__image--visible___XzoBD {
  display: block;
}

.crowdfunding_story__form__title___40AxL {
  text-align: center;
  margin-bottom: 8px;
  width: 384px;
}
.crowdfunding_story__form__subtitle___2a9Z5,
.crowdfunding_story__form__buttom_margin___3EVUG {
  margin-bottom: 32px;
}
.crowdfunding_story__form__button___3aAl2 {
  text-align: center;
  margin-top: 40px;
}

.tip_box__tip-wrapper___YYeAO {
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
}
.tip_box__tip-wrapper___YYeAO .tip_box__tip-avatar-wrapper___adRJQ {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}
.tip_box__tip-wrapper___YYeAO .tip_box__tip-icon___2u9Ed {
  background-color: #f7f5f0 !important;
}
.tip_box__tip-wrapper___YYeAO .tip_box__tip-icon___2u9Ed span {
  color: #515354;
}
.tip_box__tip-wrapper___YYeAO .tip_box__tip-text___qGH2R {
  margin-left: 8px;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}
.tip_box__tip-wrapper___YYeAO .tip_box__tip-text___qGH2R span {
  color: #515354;
}

.tip_box_personal__continer___2xsVi {
  border: 1px solid #f1efea !important;
  box-shadow: none;
  background: #f1efea !important;
  margin-top: 1rem;
  padding: 0 1rem !important;
}
.tip_box_personal__header___2fn-Y {
  margin-bottom: 1rem;
}

.crowdfunding_cover__title___1jJSv {
  text-align: center;
  margin-bottom: 4px;
}
.crowdfunding_cover__sub-title___39Wp6 {
  color: #0c0c0d;
  margin-bottom: 32px;
}
.crowdfunding_cover__sub-title___39Wp6 li {
  color: #0c0c0d;
  font-family: 'PayPalSansBig-Regular', Helvetica Neue, Arial, sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: normal;
  list-style-type: disc;
}
.crowdfunding_cover__uploader___2RunY {
  margin-bottom: 0;
}
.crowdfunding_cover__disclaimer___VWTBh {
  margin-top: 32px;
  margin-bottom: 16px;
}
.crowdfunding_cover__disclaimer___VWTBh a {
  font-weight: 600;
}
.crowdfunding_cover__disclaimer___VWTBh a:focus {
  text-decoration: underline !important;
}
.crowdfunding_cover__modal___3bPm7 {
  z-index: 2000;
  width: 936px;
}
@media (max-width: 414px) {
  .crowdfunding_cover__modal___3bPm7 {
    width: 100%;
  }
}
.crowdfunding_cover__modal_title___3jiMf {
  text-align: center;
  padding-left: 48px;
  position: absolute;
  width: calc(90%);
  -ms-flex-align: center;
      align-items: center;
}
@media (max-width: 414px) {
  .crowdfunding_cover__modal_title___3jiMf {
    width: 75%;
  }
}
div[role='dialog'] {
  z-index: 2001;
}
.crowdfunding_cover__no_photo_row___2QUX1 {
  margin-top: 0.7rem;
}
.crowdfunding_cover__no_photo-button___1U1Dn {
  width: 100%;
}
@media (max-width: 425px) {
  .crowdfunding_cover__no_photo-button___1U1Dn span {
    font-size: 14px;
  }
}
.crowdfunding_cover__button_offset___39ORY {
  margin-top: 2.25rem;
}
.crowdfunding_cover__container_class___3StXa {
  max-width: 295px;
  height: 400px;
}
@media (min-width: 426px) {
  .crowdfunding_cover__container_class___3StXa {
    max-width: 452px;
  }
}
form {
  padding: 0 16px;
}
.crowdfunding_cover__icon-alignment___2gAtd,
.crowdfunding_cover__text-alignment___LLqzN {
  text-align: center;
}
.crowdfunding_cover__buttons___30bH1 {
  display: -ms-inline-grid;
  display: inline-grid;
  -ms-grid-column-align: center;
      justify-items: center;
  width: 100%;
}
.crowdfunding_cover__buttons___30bH1 button {
  margin-bottom: 16px;
}

.crowdfunding_select_cover__title___3T4Ea {
  text-align: center;
  margin-bottom: 4px;
}
.crowdfunding_select_cover__sub-title___2c5b_ {
  color: #0c0c0d;
  margin-bottom: 32px;
}
.crowdfunding_select_cover__row-margin___1Yc_O {
  margin-bottom: 10px;
}
.crowdfunding_select_cover__upload-card___dkS3d {
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  color: #005ea6;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 100%;
  cursor: pointer;
}
.crowdfunding_select_cover__image-checkbox-container___Am3ME {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
.crowdfunding_select_cover__image-checkbox-container___Am3ME span {
  border-radius: 50% !important;
}
.crowdfunding_select_cover__image-checkbox-container___Am3ME span::after {
  border-radius: 50% !important;
}
.crowdfunding_select_cover__image-delete-container___2CRqF {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  height: 100%;
  -ms-flex-align: center;
      align-items: center;
  color: #fff;
  background-color: rgba(44, 44, 44, 0.55);
  -ms-flex-direction: column;
      flex-direction: column;
}

.session_timeout__sheet__description___3ggJu {
  color: #515354 !important;
  margin-bottom: 24px;
}

