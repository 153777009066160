@import (reference) '~stylesheets/colors';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~stylesheets/gradient_colors';
@import (reference) '~stylesheets/twbs';
@import (reference) '~stylesheets/modules/type';

@add-cover-height: 48px;
@padding: 11px;

.crowdfunding-banner {
  justify-content: center;
  align-items: center;
  min-height: 4.2rem;
  padding: 1.3rem;
}

.crowdfunding-banner-icon {
  margin-right: 28px;

  @media (max-width: @phonePortraitMax) {
    margin-right: 12px;
  }
}

.crowdfunding-banner-button {
  @media (max-width: @phonePortraitMax) {
    margin-top: 1rem;
    margin-left: 29px;
  }
}

.banner-text {
  display: flex;
  position: relative;

  @media (max-width: @phonePortraitMax) {
    padding: 0;
  }
}

.entryBannerContainer {
  @media (min-width: @tabletLandscapeMax) {
    margin-top: 4.5rem;
  }
}
