@import '~vxpattern-lib-paypal/dist/paypal-sans.css';
@-ms-viewport {
  width: device-width;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 699px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 699px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 699px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 699px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 700px) and (max-width: 899px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 700px) and (max-width: 899px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 700px) and (max-width: 899px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 700px) and (max-width: 899px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 900px) and (max-width: 750px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 900px) and (max-width: 750px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 900px) and (max-width: 750px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 900px) and (max-width: 750px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1160px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1160px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1160px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1160px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 699px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 700px) and (max-width: 899px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 900px) and (max-width: 750px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1160px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
.container {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 700px) {
  .container {
    width: 744px;
  }
}
@media (min-width: 900px) {
  .container {
    width: 964px;
  }
}
@media (min-width: 1160px) {
  .container {
    width: 1164px;
  }
}
.container-fluid {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  margin-right: -12px;
  margin-left: -12px;
}
.row-no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.row-no-gutters [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
}
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 700px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 900px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1160px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}
/**
 * @fileOverview Contains type treatment for PayPal's new VX Patterns
 * @name type-vxPtrn
 * @author jlowery
 * @notes The below styles are mobile first
 */
body {
  font-size: inherit !important;
  font-family: 'PayPal-Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}
.vx_text-xl {
  font-size: 3.75rem;
  line-height: 1.1;
  font-weight: 300;
  text-transform: none;
  font-family: 'PayPal-Sans-Big', sans-serif;
}
h1,
.vx_text-1 {
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 300;
  text-transform: none;
  font-family: 'PayPal-Sans-Big', sans-serif;
}
h2,
.vx_text-2 {
  font-size: 1.875rem;
  line-height: 1.4;
  font-weight: 300;
  text-transform: none;
  font-family: 'PayPal-Sans-Big', sans-serif;
}
h3,
.vx_text-3 {
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans-Big', sans-serif;
}
.vx_text-3_lighter {
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 300;
  text-transform: none;
  font-family: 'PayPal-Sans-Big', sans-serif;
}
h4,
.vx_text-4 {
  font-size: 1.125rem;
  line-height: 1.33333;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
h5,
.vx_text-5 {
  font-size: 0.9375rem;
  line-height: 1.6;
  font-weight: 500;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_text-5_lighter {
  font-size: 0.9375rem;
  line-height: 1.6;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
h6,
.vx_text-6 {
  font-size: 0.8125rem;
  line-height: 1.84615385;
  font-weight: 500;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_text-6_lighter {
  font-size: 0.8125rem;
  line-height: 1.84615385;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_text-body,
p,
li,
dd,
dt,
label,
input,
textarea,
pre,
code {
  font-size: 0.9375rem;
  line-height: 1.6;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_text-body_secondary {
  font-size: 0.8125rem;
  line-height: 1.84615385;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_text-legal {
  font-size: 0.8125rem;
  line-height: 1.38461538;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
  color: #6C7378;
}
.vx_h1,
.vx_h2,
.vx_h3,
.vx_h4 {
  -webkit-font-smoothing: antialiased;
}
.vx_h1 {
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 300;
  text-transform: none;
  font-family: 'PayPal-Sans-Big', sans-serif;
}
.vx_h2 {
  /* Yes, the below H3 vars are intended
	 * this is the mobile sizing, so it's this first.
	 * Desktop is below.
	 */
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 300;
  text-transform: none;
  font-family: 'PayPal-Sans-Big', sans-serif;
  /* Mobile First, aka Desktop Later */
}
@media (min-width: 768px) {
  .vx_h2 {
    font-size: 1.875rem;
    line-height: 1.4;
    font-weight: 300;
    text-transform: none;
    font-family: 'PayPal-Sans-Big', sans-serif;
  }
}
.vx_h3 {
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans-Big', sans-serif;
}
.vx_h4 {
  font-size: 1.125rem;
  line-height: 1.33333;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_h5 {
  font-size: 0.9375rem;
  line-height: 1.6;
  font-weight: 500;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_h6 {
  font-size: 0.8125rem;
  line-height: 1.84615385;
  font-weight: 500;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_h7 {
  font-size: 0.8125rem;
  line-height: 1.84615385;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_base-text {
  font-size: 0.9375rem;
  line-height: 1.6;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_small-text {
  font-size: 0.8125rem;
  line-height: 1.84615385;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
}
.vx_legal-text {
  font-size: 0.8125rem;
  line-height: 1.38461538;
  font-weight: 400;
  text-transform: none;
  font-family: 'PayPal-Sans', sans-serif;
  color: #6C7378;
}
.vx_secondary-text {
  color: #9DA3A6;
}
.vx_text-block {
  padding: 1.6em 1.6em 0.8em;
}
.vx_reverse-contrast {
  background: #2c2e2f;
  color: #FFFFFF;
}
.vx_reverse-contrast .vx_secondary-text {
  color: #CBD2D6;
}
.vx_reverse-contrast .small-text {
  font-weight: 500;
}
ul,
ol {
  padding: 0;
  margin: 0 0 0.8rem 25px;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}
li {
  line-height: 1.6;
}
dl {
  margin-bottom: 1.6rem;
}
dt,
dd {
  line-height: 1.6;
}
dt {
  font-weight: 500;
}
dd {
  margin-left: 0.8rem;
}
.vx_unstyled-list {
  list-style: none;
  padding: 0;
}
a,
a:visited {
  color: #0070BA;
  text-decoration: none;
  font-weight: 500;
  font-family: 'PayPal-Sans', sans-serif;
}
a:active,
a:focus,
a:hover {
  color: #005EA6;
  text-decoration: underline;
}
p > a,
li > a {
  font-weight: 500;
  font-family: 'PayPal-Sans', sans-serif;
}
/**
 * DO NOT EDIT THIS FILE AS IT'S GENERATED CODE.
 * UED: cgrigson, adustin, nvandewater
 * UIE: jlowery
 * Note: If modifications are needed, don't modify this file! Either override or make a PR to:
 * https://github.paypal.com/jlowery/paypal-vxpattern-lib
*/
/* Icons */
/* Z-index list */
/* user for a bird's eye view of components based on z-axis */
/* for example, if you want to put a button in header, just assign z-index of that button to @zindexHeader */
/* these will overwrite the bootstrap pre defined variables */
/* TODO: check the clean the non-needed modal styles in bootstrap */
/* the same as bootstrap one, just overwrite */
/* Setting for Sidepanel */
/* This is not used by all side panels, only for the default one */
/* what about larger phones like gs3? what about phablets like the note?
/* responsive breakpoints */
/* Navigation */
/* Spinner */
/**
 * THIS ICONS COMPONENT IS BEING DEPRECATED. USE THE ICONOGRAPHY COMPONENT INSTEAD.
 * DO NOT EDIT THIS FILE AS IT'S GENERATED CODE.
 * UED: cgrigson, adustin, nvandewater
 * UIE: jlowery
 * Note: If modifications are needed, don't modify this file! Either override or make a PR to:
 * https://github.paypal.com/jlowery/paypal-vxpattern-lib
*/
@font-face {
  font-family: 'paypal-vx-icons';
  src: url('https://www.paypalobjects.com/ui-web/vx-icons/2-0-1/PayPalVXIcons-Regular.eot');
  /* IE9 Compat Modes */
  src: url('https://www.paypalobjects.com/ui-web/vx-icons/2-0-1/PayPalVXIcons-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://www.paypalobjects.com/ui-web/vx-icons/2-0-1/PayPalVXIcons-Regular.woff') format('woff'), /* Modern Browsers */ url('https://www.paypalobjects.com/ui-web/vx-icons/2-0-1/PayPalVXIcons-Regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://www.paypalobjects.com/ui-web/vx-icons/2-0-1/PayPalVXIcons-Regular.svg#69ac2c9fc1e0803e59e06e93859bed03') format('svg');
  /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
}
.icon {
  font-family: 'paypal-vx-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 0;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-small:before {
  font-size: 1.58rem;
}
.icon-medium:before {
  font-size: 2.6rem;
}
.icon-large:before {
  font-size: 7.8rem;
}
.icon-slider-small:before {
  content: "\205D";
}
.icon-slider-half-small:before {
  content: "\205E";
}
.icon-close-small:before {
  content: "\274E";
}
.icon-add-small:before {
  content: "\002B";
}
.icon-arrow-down-small:before {
  content: "\2193";
}
.icon-arrow-up-small:before {
  content: "\2191";
}
.icon-arrow-left-small:before {
  content: "\2190";
}
.icon-arrow-right-small:before {
  content: "\2192";
}
.icon-arrow-down-half-small:before {
  content: "\21E3";
}
.icon-arrow-up-half-small:before {
  content: "\21E1";
}
.icon-arrow-left-half-small:before {
  content: "\21E0";
}
.icon-arrow-right-half-small:before {
  content: "\21E2";
}
.icon-arrow-back-small:before {
  content: "\21E6";
}
.icon-clear-half-small:before {
  content: "\2716";
}
.icon-checkmark-small:before {
  content: "\2705";
}
.icon-checkmark-small-bold:before {
  content: "\2611";
}
.icon-circled-checkmark-small:before {
  content: "\2714";
}
.icon-circled-checkmark-large:before {
  content: "\2713";
}
.icon-action-arrow-half-small:before {
  content: "\25BA";
}
.icon-action-arrow-right-half-small:before {
  content: "\25BA";
}
.icon-action-arrow-down-half-small:before {
  content: "\25BC";
}
.icon-action-arrow-up-half-small:before {
  content: "\25B2";
}
.icon-tooltip-small:before {
  content: "\2753";
}
.icon-info-small:before {
  content: "\2139";
}
.icon-positive-small:before {
  content: "\002B";
}
.icon-attention-small:before {
  content: "\2755";
}
.icon-critical-small:before {
  content: "\26A0";
}
.icon-info-large:before {
  content: "\24D8";
}
.icon-positive-large:before {
  content: "\002B";
}
.icon-attention-large:before {
  content: "\2762";
}
.icon-critical-large:before {
  content: "\2757";
}
.icon-pp-logo:before {
  content: "\E498";
}
.icon-pp-logo-half:before {
  content: "\E499";
}
.icon-gear:before {
  content: "\2699";
}
.icon-magnifying-glass:before {
  content: "\1F50D";
}
.icon-bell:before {
  content: "\1F514";
}
.icon-chain-link:before {
  content: "\1F517";
}
.icon-calendar:before {
  content: "\1F4C5";
}
.icon-camera-add:before {
  content: "\1F4F7";
}
.icon-avatar:before {
  content: "\1F471";
}
/*
* @name buttons
* @description All defined button styles and states belong here, including any helper class button style options, like `vx_btn-secondary` or `vx_btn-medium` etc.
*
* @group elements
*/
.vx_btn,
.vx_btn-small,
.vx_btn-medium {
  display: inline-block;
  min-width: 6rem;
  padding: 0.75rem 1.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid #0070BA;
  border-radius: 1.5rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  font-family: 'PayPal-Sans', sans-serif;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  background-color: #0070BA;
  transition: all 250ms ease;
  -webkit-font-smoothing: antialiased;
}
.vx_btn:visited,
.vx_btn-small:visited,
.vx_btn-medium:visited,
.vx_btn:active,
.vx_btn-small:active,
.vx_btn-medium:active,
.vx_btn:hover,
.vx_btn-small:hover,
.vx_btn-medium:hover,
.vx_btn:focus,
.vx_btn-small:focus,
.vx_btn-medium:focus {
  color: #fff;
}
.vx_btn:visited,
.vx_btn-small:visited,
.vx_btn-medium:visited {
  text-decoration: none;
}
.vx_btn:hover,
.vx_btn-small:hover,
.vx_btn-medium:hover,
.vx_btn:focus,
.vx_btn-small:focus,
.vx_btn-medium:focus {
  background-color: #003087;
  border: 1px solid #003087;
  box-shadow: none;
  text-decoration: none;
}
.vx_btn:focus,
.vx_btn-small:focus,
.vx_btn-medium:focus {
  outline: none;
  text-decoration: underline;
}
.vx_btn[disabled],
.vx_btn-small[disabled],
.vx_btn-medium[disabled],
.vx_btn[disabled]:hover,
.vx_btn-small[disabled]:hover,
.vx_btn-medium[disabled]:hover,
.vx_btn[disabled]:active,
.vx_btn-small[disabled]:active,
.vx_btn-medium[disabled]:active,
.vx_btn.vx_is-disabled,
.vx_btn-small.vx_is-disabled,
.vx_btn-medium.vx_is-disabled,
.vx_btn.vx_is-disabled:hover,
.vx_btn-small.vx_is-disabled:hover,
.vx_btn-medium.vx_is-disabled:hover,
.vx_btn.vx_is-disabled:active,
.vx_btn-small.vx_is-disabled:active,
.vx_btn-medium.vx_is-disabled:active {
  color: #CBD2D6;
  background: #F5F6F8;
  box-shadow: none;
  border: 1px solid #CBD2D6;
  text-decoration: none;
  cursor: not-allowed;
  outline: none;
  -ms-transform: none;
  -webkit-transform: none;
  transform: none;
}
.vx_btn + .vx_btn,
.vx_btn + .vx_btn-small,
.vx_btn + .vx_btn-medium,
.vx_btn-small + .vx_btn,
.vx_btn-small + .vx_btn-small,
.vx_btn-small + .vx_btn-medium,
.vx_btn-medium + .vx_btn,
.vx_btn-medium + .vx_btn-small,
.vx_btn-medium + .vx_btn-medium {
  margin-left: 0.75rem;
}
.vx_btn.vx_btn-block,
.vx_btn-small.vx_btn-block,
.vx_btn-medium.vx_btn-block {
  margin-right: 0;
  margin-left: 0;
  width: 100%;
}
.vx_btn-secondary {
  background-color: transparent;
  border-color: #0070BA;
  color: #0070BA;
}
.vx_btn-secondary:hover,
.vx_btn-secondary:focus {
  color: #003087;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: #003087;
  box-shadow: 0 0 0 1px #003087 inset;
}
.vx_btn-secondary:visited {
  text-decoration: none;
  color: #0070BA;
}
.vx_btn_reversed {
  border-color: #fff;
  background-color: #fff;
  color: #0070BA;
}
.vx_btn_reversed:hover,
.vx_btn_reversed:focus {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #003087;
}
.vx_btn_reversed:visited {
  color: #0070BA;
}
.vx_btn_reversed:visited:hover {
  color: #003087;
}
.vx_btn-secondary_reversed {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}
.vx_btn-secondary_reversed:hover,
.vx_btn-secondary_reversed:focus {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
  border-color: #fff;
  box-shadow: 0 0 0 1px #fff inset;
}
.vx_btn-medium {
  font-size: 0.8125rem;
  line-height: 1.38461538;
  min-width: 4.5rem;
  padding: 0.5625rem 1.125rem;
}
.vx_btn-small {
  font-size: 0.8125rem;
  line-height: 1.36363636;
  min-width: 3.75rem;
  padding: 0.46875rem 0.9375rem;
}
.vx_btn-icon {
  display: inline-block;
  width: 1.5rem;
  position: relative;
  margin-right: 0.625rem;
}
.vx_btn-icon svg {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: -1.125rem;
  left: 0;
}
.vx_btn-icon .svg-stroke {
  stroke: #fff;
}
.vx_btn-icon .svg-fill {
  fill: #fff;
}
.vx_btn-secondary .vx_btn-icon .svg-stroke {
  stroke: #0070BA;
}
.vx_btn-secondary .vx_btn-icon .svg-fill {
  fill: #0070BA;
}
.vx_btn-secondary:hover .vx_btn-icon .svg-stroke,
.vx_btn-secondary:focus .vx_btn-icon .svg-stroke {
  stroke: #003087;
}
.vx_btn-secondary:hover .vx_btn-icon .svg-fill,
.vx_btn-secondary:focus .vx_btn-icon .svg-fill {
  fill: #003087;
}
[disabled] .vx_btn-icon .svg-stroke,
[disabled]:hover .vx_btn-icon .svg-stroke,
[disabled]:active .vx_btn-icon .svg-stroke,
.vx_is-disabled .vx_btn-icon .svg-stroke,
.vx_is-disabled:hover .vx_btn-icon .svg-stroke,
.vx_is-disabled:active .vx_btn-icon .svg-stroke {
  stroke: #CBD2D6;
}
[disabled] .vx_btn-icon .svg-fill,
[disabled]:hover .vx_btn-icon .svg-fill,
[disabled]:active .vx_btn-icon .svg-fill,
.vx_is-disabled .vx_btn-icon .svg-fill,
.vx_is-disabled:hover .vx_btn-icon .svg-fill,
.vx_is-disabled:active .vx_btn-icon .svg-fill {
  fill: #CBD2D6;
}
.vx_btn-medium .vx_btn-icon {
  width: 1.125rem;
  margin-right: 0.5rem;
}
.vx_btn-medium .vx_btn-icon svg {
  width: 1.125rem;
  height: 1.125rem;
  top: -0.8125rem;
}
.vx_btn-small .vx_btn-icon {
  width: 1.10795454rem;
  margin-right: 0.375rem;
}
.vx_btn-small .vx_btn-icon svg {
  width: 1.10795454rem;
  height: 1.10795454rem;
  top: -0.75rem;
}
.vx_btn-group,
.vx_btn-group_stacked {
  text-align: center;
}
.vx_btn-group_stacked .vx_btn,
.vx_btn-group_stacked .vx_btn-small,
.vx_btn-group_stacked .vx_btn-medium {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
.vx_addBtn-primary,
.vx_addBtn-secondary {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  background: none;
  border: none;
  color: #0070BA;
  padding-left: 0.125rem;
}
.vx_addBtn-primary:before,
.vx_addBtn-secondary:before {
  content: "\002B";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'paypal-vx-icons';
  line-height: 0;
  font-size: 1.25rem;
}
.vx_addBtn-primary:hover,
.vx_addBtn-secondary:hover {
  color: #005EA6;
  text-decoration: none;
}
.vx_addBtn-primary:before {
  color: #FFFFFF;
  background: #0070BA;
  border-radius: 100%;
  padding: 0 5px 3px;
  box-shadow: 0 1px 2px #2C2E2F;
  margin-right: 12px;
}
.vx_addBtn-secondary:before {
  margin-right: 5px;
}
.vx_backBtn {
  background: none;
  border: none;
  color: #0070BA;
  font-weight: normal;
  padding: 0;
}
.vx_backBtn:focus,
.vx_backBtn:active,
.vx_backBtn:hover {
  color: #005EA6;
  text-decoration: none;
}
.vx_backBtn:focus span,
.vx_backBtn:active span,
.vx_backBtn:hover span {
  text-decoration: underline;
}
.vx_backBtn:before {
  font-family: 'paypal-vx-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\2190';
  display: inline;
  font-size: 1.5em;
  vertical-align: -0.1em;
  padding-right: 0.1em;
}
.vx_btn-twoUp .vx_btn {
  display: inline-block;
  width: 48%;
  margin-left: 0;
  margin-right: 0;
}
.vx_btn-twoUp .vx_btn:first-of-type {
  margin-right: 1.5%;
}
.vx_btn-twoUp .vx_btn:last-of-type {
  margin-left: 1.5%;
}
@media (max-width: 768px) {
  .vx_blocks-for-mobile .vx_btn {
    display: block;
    width: 100%;
  }
  .vx_blocks-for-mobile .vx_btn + .vx_btn {
    margin: 5px 0 0;
  }
}
.vx_btn-link {
  background: none;
  border: none;
  color: #0070BA;
  font-weight: 500;
  padding: 0;
}
.vx_btn-link:hover {
  color: #005EA6;
  text-decoration: underline;
}
.vx_tag,
.vx_tag-pending,
.vx_tag-warning,
.vx_tag-critical,
.vx_tag-success {
  display: inline-block;
  height: 0.8125rem;
  line-height: 0.625rem;
  padding: 0 0.1875rem 0.0625rem 0.1875rem;
  border-radius: 3px;
  font-size: 0.625rem;
  font-weight: 500;
  text-transform: uppercase;
  border: 1px solid #2c2e2f;
  color: #2c2e2f;
}
.vx_tag-pending {
  border: 1px solid #640487;
  color: #640487;
}
.vx_tag-warning {
  border: 1px solid #D64003;
  color: #D64003;
}
.vx_tag-critical {
  border: 1px solid #D20000;
  color: #D20000;
}
.vx_tag-success {
  border: 1px solid #018065;
  color: #018065;
}
.vx_spinner-small,
.vx_has-spinner-small:after {
  height: 18px;
  opacity: 0.56;
  width: 18px;
  animation: vx_spin 620ms infinite linear;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'100%25'%20height%3D'100%25'%20viewBox%3D'0%200%2038%2038'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%0A%09%3Cdefs%3E%0A%09%09%3ClinearGradient%20id%3D'gradient'%20x1%3D'0'%20y1%3D'0'%20x2%3D'1'%20y2%3D'0'%3E%0A%09%09%09%3Cstop%20offset%3D'0'%20stop-color%3D'%23000000'%20stop-opacity%3D'0'%2F%3E%0A%09%09%09%3Cstop%20offset%3D'1'%20stop-color%3D'%23000000'%20stop-opacity%3D'1'%2F%3E%0A%09%09%3C%2FlinearGradient%3E%0A%09%3C%2Fdefs%3E%0A%09%3Cpath%20stroke%3D'url(%23gradient)'%20vector-effect%3D'non-scaling-stroke'%20stroke-width%3D'1px'%20fill%3D'none'%20fill-rule%3D'evenodd'%20d%3D'M2%2C19a17%2C17%200%201%2C1%2034%2C0'%3E%3C%2Fpath%3E%0A%09%3Cpath%20stroke%3D'%23000000'%20vector-effect%3D'non-scaling-stroke'%20stroke-width%3D'1px'%20fill%3D'none'%20fill-rule%3D'evenodd'%20d%3D'M2%2C19a17%2C17%200%201%2C0%2034%2C0'%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A");
  content: '';
  display: block;
}
.vx_spinner-medium,
.vx_has-spinner-medium:after {
  height: 24px;
  opacity: 0.56;
  width: 24px;
  animation: vx_spin 620ms infinite linear;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'100%25'%20height%3D'100%25'%20viewBox%3D'0%200%2038%2038'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%0A%09%3Cdefs%3E%0A%09%09%3ClinearGradient%20id%3D'gradient'%20x1%3D'0'%20y1%3D'0'%20x2%3D'1'%20y2%3D'0'%3E%0A%09%09%09%3Cstop%20offset%3D'0'%20stop-color%3D'%23000000'%20stop-opacity%3D'0'%2F%3E%0A%09%09%09%3Cstop%20offset%3D'1'%20stop-color%3D'%23000000'%20stop-opacity%3D'1'%2F%3E%0A%09%09%3C%2FlinearGradient%3E%0A%09%3C%2Fdefs%3E%0A%09%3Cpath%20stroke%3D'url(%23gradient)'%20vector-effect%3D'non-scaling-stroke'%20stroke-width%3D'2px'%20fill%3D'none'%20fill-rule%3D'evenodd'%20d%3D'M2%2C19a17%2C17%200%201%2C1%2034%2C0'%3E%3C%2Fpath%3E%0A%09%3Cpath%20stroke%3D'%23000000'%20vector-effect%3D'non-scaling-stroke'%20stroke-width%3D'2px'%20fill%3D'none'%20fill-rule%3D'evenodd'%20d%3D'M2%2C19a17%2C17%200%201%2C0%2034%2C0'%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A");
  content: '';
  display: block;
}
.vx_spinner-large,
.vx_has-spinner-large:after {
  height: 128px;
  opacity: 0.56;
  width: 128px;
  animation: vx_spin 620ms infinite linear;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'100%25'%20height%3D'100%25'%20viewBox%3D'0%200%2038%2038'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%0A%09%3Cdefs%3E%0A%09%09%3ClinearGradient%20id%3D'gradient'%20x1%3D'0'%20y1%3D'0'%20x2%3D'1'%20y2%3D'0'%3E%0A%09%09%09%3Cstop%20offset%3D'0'%20stop-color%3D'%2300CF92'%20stop-opacity%3D'0'%2F%3E%0A%09%09%09%3Cstop%20offset%3D'1'%20stop-color%3D'%2300CF92'%20stop-opacity%3D'1'%2F%3E%0A%09%09%3C%2FlinearGradient%3E%0A%09%3C%2Fdefs%3E%0A%09%3Cpath%20stroke%3D'url(%23gradient)'%20vector-effect%3D'non-scaling-stroke'%20stroke-width%3D'2px'%20fill%3D'none'%20fill-rule%3D'evenodd'%20d%3D'M2%2C19a17%2C17%200%201%2C1%2034%2C0'%3E%3C%2Fpath%3E%0A%09%3Cpath%20stroke%3D'%2300CF92'%20vector-effect%3D'non-scaling-stroke'%20stroke-width%3D'2px'%20fill%3D'none'%20fill-rule%3D'evenodd'%20d%3D'M2%2C19a17%2C17%200%201%2C0%2034%2C0'%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A");
  content: '';
  display: block;
}
.vx_has-spinner-small,
.vx_has-spinner-medium,
.vx_has-spinner-large {
  position: relative;
}
.vx_has-spinner-small:before,
.vx_has-spinner-medium:before,
.vx_has-spinner-large:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1000;
  animation: vx_fade-in 0.3s ease-out 0s both;
}
.vx_has-spinner-small:after,
.vx_has-spinner-medium:after,
.vx_has-spinner-large:after {
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1001;
}
@keyframes vx_spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes vx_fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes vx_fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* Styles for checkbox widget */
.vx_checkbox,
.vx_radio {
  width: 100%;
  position: relative;
}
.vx_checkbox input,
.vx_radio input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}
.vx_checkbox input:focus + label:before,
.vx_radio input:focus + label:before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.vx_checkbox input:checked + label:before,
.vx_radio input:checked + label:before {
  content: "\2611";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-color: #0092d1;
  color: #FFFFFF;
  background-color: #0092d1;
}
.vx_checkbox label,
.vx_radio label {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  line-height: 2em;
}
.vx_checkbox label:before,
.vx_radio label:before {
  font-family: 'paypal-vx-icons';
  display: inline-block;
  vertical-align: top;
  height: 1.2em;
  width: 1.2em;
  margin: 0 12px 0 0;
  padding: 0.4em 0.1em;
  border: 1px solid #2c2e2f;
  content: '';
  font-size: 25px;
  line-height: 0;
  border-radius: 2px;
  overflow: hidden;
}
.vx_checkbox.vx_has-error input:checked + label:before,
.vx_radio.vx_has-error input:checked + label:before {
  border-color: #D20000;
  background: #D20000;
}
.vx_checkbox.vx_has-error label:before,
.vx_radio.vx_has-error label:before {
  border-color: #D20000;
}
.vx_checkbox.vx_has-error:after,
.vx_radio.vx_has-error:after {
  font-family: 'paypal-vx-icons';
  display: inline-block;
  vertical-align: middle;
  content: "\26A0";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.75em;
  line-height: 0;
  position: relative;
  top: -3px;
  padding-left: 5px;
}
.vx_checkbox.vx_is-disabled,
.vx_radio.vx_is-disabled {
  cursor: not-allowed;
}
.vx_checkbox.vx_is-disabled input:checked + label:before,
.vx_radio.vx_is-disabled input:checked + label:before {
  border-color: #6C7378;
  background: #6C7378;
}
.vx_checkbox.vx_is-disabled label:before,
.vx_radio.vx_is-disabled label:before {
  border-color: #6C7378;
  cursor: not-allowed;
}
.vx_checkbox.vx_is-disabled label,
.vx_radio.vx_is-disabled label {
  color: #6C7378;
  cursor: not-allowed;
}
.vx_radio input:checked + label:before {
  content: '';
  border-color: #0092d1;
  background-color: #0092d1;
  box-shadow: inset 0 0 0 5px #FFFFFF;
}
.vx_radio label:before {
  border-radius: 100%;
}
/* Container used for styling the custom select */
.vx_select {
  position: relative;
  display: block;
}
/* This is the native select, we're making everything but the text invisible so
* we can see the button styles in the wrapper */
.vx_select select {
  width: 100%;
  margin: 0;
}
.vx_select select:focus {
  outline: none;
}
/* Custom arrow sits on top of the select - could be an image, SVG, icon font,
* etc. or the arrow could just baked into the bg image on the select. */
.vx_select:after {
  font-family: 'paypal-vx-icons';
  speak: none;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 0;
  /* Better Font Rendering =========== */
  content: "\21E3";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 12.5px;
  z-index: 2;
  /* These hacks make the select behind the arrow clickable in some browsers */
  pointer-events: none;
  display: none;
}
@supports ( -webkit-appearance: none ) or ( appearance: none )
	/* Firefox <= 34 has a false positive on @supports( -moz-appearance: none )
	* @supports ( mask-type: alpha ) is Firefox 35+
	*/
	or ( ( -moz-appearance: none ) and ( mask-type: alpha ) ) {
  /* Show custom arrow */
  .vx_select:after {
    display: block;
  }
  /* Remove select styling */
  .vx_select select {
    padding-right: 2.75rem;
    /* inside @supports so that iOS <= 8 display the native arrow */
    background: #FFFFFF;
    /* inside @supports so that Android <= 4.3 display the native arrow */
    border: 1px solid #9DA3A6;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .vx_select select:focus {
    border-color: #009CDE;
  }
  .vx_has-error .vx_selectIcon_error:before {
    right: 2.2rem;
  }
  .vx_has-error select {
    padding-right: 4.5rem;
  }
  /*********************************
	*       FLOATING LABEL          *
	*********************************/
  .vx_floatingLabel select {
    position: relative;
    z-index: 1;
    height: 50px;
    padding: 18px 15px 0;
    background: transparent;
    color: #2C2E2F;
  }
  .vx_floatingLabel .vx_select:before {
    position: absolute;
    top: -10px;
    left: 0;
    font-size: 12px;
    font-weight: 500;
    content: attr(data-label-content);
    padding: 0 15px;
    line-height: 50px;
    margin: 1px;
    z-index: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    color: #6C7378;
  }
  .vx_floatingLabel.vx_has-error .vx_select:before {
    color: #D20000;
  }
  .vx_floatingLabel.hasFocus .vx_select:before,
  .vx_floatingLabel.hasFocus .vx_select:after {
    color: #0070BA;
  }
}
/**
 * Hacks to adjust alignment for Firefox and Edge
 */
@supports ( ( -moz-appearance: none ) and ( mask-type: alpha ) )
	or (-ms-ime-align: auto) {
  .vx_floatingLabel select {
    padding-left: 11px;
  }
}
/**
 * DO NOT EDIT THIS FILE AS IT'S GENERATED CODE.
 * UED: cgrigson, adustin, nvandewater
 * UIE: jlowery
 * Note: If modifications are needed, don't modify this file! Either override or make a PR to:
 * https://github.paypal.com/jlowery/paypal-vxpattern-lib
*/
.vx_icon {
  font-family: 'paypal-vx-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 0;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.vx_icon-small:before {
  font-size: 1.58rem;
}
.vx_icon-medium:before {
  font-size: 2.6rem;
}
.vx_icon-large:before {
  font-size: 7.8rem;
}
.vx_icon-slider-small:before {
  content: "\205D";
}
.vx_icon-slider-half-small:before {
  content: "\205E";
}
.vx_icon-close-small:before {
  content: "\274E";
}
.vx_icon-add-small:before {
  content: "\002B";
}
.vx_icon-arrow-down-small:before {
  content: "\2193";
}
.vx_icon-arrow-up-small:before {
  content: "\2191";
}
.vx_icon-arrow-left-small:before {
  content: "\2190";
}
.vx_icon-arrow-right-small:before {
  content: "\2192";
}
.vx_icon-arrow-down-half-small:before {
  content: "\21E3";
}
.vx_icon-arrow-up-half-small:before {
  content: "\21E1";
}
.vx_icon-arrow-left-half-small:before {
  content: "\21E0";
}
.vx_icon-arrow-right-half-small:before {
  content: "\21E2";
}
.vx_icon-arrow-back-small:before {
  content: "\21E6";
}
.vx_icon-clear-half-small:before {
  content: "\2716";
}
.vx_icon-checkmark-small:before {
  content: "\2705";
}
.vx_icon-checkmark-small-bold:before {
  content: "\2611";
}
.vx_icon-circled-checkmark-small:before {
  content: "\2714";
}
.vx_icon-circled-checkmark-large:before {
  content: "\2713";
}
.vx_icon-action-arrow-half-small:before {
  content: "\25BA";
}
.vx_icon-action-arrow-right-half-small:before {
  content: "\25BA";
}
.vx_icon-action-arrow-down-half-small:before {
  content: "\25BC";
}
.vx_icon-action-arrow-up-half-small:before {
  content: "\25B2";
}
.vx_icon-tooltip-small:before {
  content: "\2753";
}
.vx_icon-info-small:before {
  content: "\2139";
}
.vx_icon-positive-small:before {
  content: "\002B";
}
.vx_icon-attention-small:before {
  content: "\2755";
}
.vx_icon-critical-small:before {
  content: "\26A0";
}
.vx_icon-info-large:before {
  content: "\24D8";
}
.vx_icon-positive-large:before {
  content: "\002B";
}
.vx_icon-attention-large:before {
  content: "\2762";
}
.vx_icon-critical-large:before {
  content: "\2757";
}
.vx_icon-pp-logo:before {
  content: "\E498";
}
.vx_icon-pp-logo-half:before {
  content: "\E499";
}
.vx_icon-gear:before {
  content: "\2699";
}
.vx_icon-magnifying-glass:before {
  content: "\1F50D";
}
.vx_icon-bell:before {
  content: "\1F514";
}
.vx_icon-chain-link:before {
  content: "\1F517";
}
.vx_icon-calendar:before {
  content: "\1F4C5";
}
.vx_icon-camera-add:before {
  content: "\1F4F7";
}
.vx_icon-avatar:before {
  content: "\1F471";
}
/* Mixin for placeholder */
.vx_form-control {
  width: 100%;
  height: 50px;
  padding: 12.5px;
  border: 1px solid #9DA3A6;
  border-radius: 5px;
  background: #FFFFFF;
  -webkit-transition: border-color 0.15s;
  -o-transition: border-color 0.15s;
  transition: border-color 0.15s;
}
.vx_form-control:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.vx_form-control:hover,
.vx_form-control:focus,
.vx_form-control:active,
.vx_form-control.vx_is-active {
  border-color: #009CDE;
}
.vx_form-control[disabled],
.vx_form-control[disabled]:hover,
.vx_form-control[disabled]:active,
.vx_form-control.vx_is-disabled,
.vx_form-control.vx_is-disabled:hover,
.vx_form-control.vx_is-disabled:active {
  border-color: #CBD2D6;
  background: #F5F7FA;
  color: #6C7378;
  cursor: default;
}
.vx_form-group,
.vx_form-group_hiddenLabel {
  padding-top: 1em;
  text-align: left;
}
.vx_form-group label,
.vx_form-group_hiddenLabel label {
  font-weight: 500;
}
.vx_form-group_hiddenLabel label,
.vx_form-group-nolabel label {
  position: absolute;
  left: -999em;
}
input.vx_has-error {
  background: #FFFFFF;
  border-color: #D20000;
}
.vx_has-error,
.vx_has-error input,
.vx_has-error label,
input.vx_has-error,
label.vx_has-error {
  color: #D20000;
}
.vx_has-error input,
.vx_has-error select,
.vx_has-error input:hover,
.vx_has-error input:active,
input:focus input.vx_has-error,
input.vx_has-error:hover,
input.vx_has-error:active,
input.vx_has-error:focus {
  background: #FFFFFF;
  border-color: #D20000;
}
.vx_has-error .vx_form-control_complex {
  border-color: #D20000;
}
.vx_has-error .vx_form-control-icon {
  color: #D20000;
}
.vx_has-error .vx_selectIcon_error {
  position: relative;
  display: block;
  text-align: right;
  height: 0;
  font-family: 'paypal-vx-icons';
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  line-height: 0;
  -webkit-font-smoothing: antialiased;
}
.vx_has-error .vx_selectIcon_error:before {
  content: "\26A0";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.58rem;
  position: absolute;
  right: 1.25em;
  top: -1.25em;
}
.vx_selectIcon_error {
  display: none;
}
.vx_form-control_complex {
  padding: 0;
  display: table;
  table-layout: fixed;
  height: auto;
}
.vx_form-control_complex input {
  background: none;
  border: none;
  display: table-cell;
  width: 100%;
  padding: 12px;
  height: 48px;
}
.vx_form-control_complex input:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.vx_form-control_complex input:hover {
  background: none;
}
.vx_form-control-icon,
.vx_form-control-error-icon {
  display: table-cell;
  width: 2.5em;
  min-width: 2.5em;
  color: #9DA3A6;
  text-align: right;
  vertical-align: middle;
  position: relative;
  top: -2px;
}
.vx_form-control-error-icon {
  width: 1px;
  min-width: 1px;
  visibility: hidden;
}
.vx_form-control-icon + input {
  padding-left: 0.5em;
}
label.vx_has-error-with-message,
input.vx_has-error-with-message,
.vx_form-control_complex.vx_has-error-with-message {
  color: #D20000;
}
input.vx_has-error-with-message,
.vx_form-control_complex.vx_has-error-with-message {
  background: #FFFFFF;
  border-color: #D20000;
  margin-bottom: 0 !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.vx_has-message > input,
.vx_has-error-with-message > input,
.vx_has-message > .vx_form-control_complex,
.vx_has-error-with-message > .vx_form-control_complex {
  margin-bottom: 0 !important;
  border: 1px solid #0092d1;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.vx_has-message.hasFocus:after,
.vx_has-error-with-message.hasFocus:after {
  color: #D20000;
}
.vx_has-error-with-message > label,
.vx_has-error-with-message > .vx_form-control_complex {
  color: #D20000;
}
.vx_has-error-with-message > input,
.vx_has-error-with-message > .vx_form-control_complex {
  background: #FFFFFF;
  border-color: #D20000;
  margin-bottom: 0 !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.vx_has-error-with-message .vx_form-control-message {
  background: #FEFAFA;
  border: 1px solid #D20000;
  border-top-width: 0;
}
.vx_has-error-with-message .vx_form-control-icon {
  color: #D20000;
}
.vx_has-error-with-message.hasFocus:after {
  color: #D20000;
}
.vx_has-error .vx_form-control_complex .vx_form-control-error-icon,
.vx_has-error-with-message .vx_form-control_complex .vx_form-control-error-icon {
  display: table-cell;
  visibility: visible;
  width: 2.25em;
  color: #D20000;
  text-align: left;
}
.vx_form-control-message {
  display: block;
  background: #FDFEFF;
  padding: 0.5em 1em;
  border: 1px solid #0092d1;
  border-top-width: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
/*********************************
 *       FLOATING LABEL          *
 *********************************/
.vx_floatingLabel,
.vx_floatingLabel_complex,
.vx_floatingLabel_active {
  position: relative;
  padding: 0;
  margin-top: 15px;
  background: white;
  border-radius: 5px;
  text-align: left;
}
.vx_floatingLabel.vx_floatingLabel_complex > .vx_form-control,
.vx_floatingLabel_complex.vx_floatingLabel_complex > .vx_form-control,
.vx_floatingLabel_active.vx_floatingLabel_complex > .vx_form-control {
  position: relative;
  display: table-cell;
  height: 48px;
}
.vx_floatingLabel.vx_floatingLabel_complex > .vx_form-control input,
.vx_floatingLabel_complex.vx_floatingLabel_complex > .vx_form-control input,
.vx_floatingLabel_active.vx_floatingLabel_complex > .vx_form-control input {
  height: 48px;
}
.vx_floatingLabel:before,
.vx_floatingLabel_complex:before,
.vx_floatingLabel_active:before,
.vx_floatingLabel:after,
.vx_floatingLabel_complex:after,
.vx_floatingLabel_active:after,
.vx_floatingLabel.vx_floatingLabel_complex > .vx_form-control:before,
.vx_floatingLabel_complex.vx_floatingLabel_complex > .vx_form-control:before,
.vx_floatingLabel_active.vx_floatingLabel_complex > .vx_form-control:before,
.vx_floatingLabel.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_complex.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_active.vx_floatingLabel_complex > .vx_form-control:after {
  content: attr(data-label-content);
  position: absolute;
  padding: 0 15px;
  line-height: 48px;
  margin: 1px;
  transition: opacity 0.25s ease-out;
  z-index: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  color: #6C7378;
}
.vx_floatingLabel.vx_floatingLabel > .vx_form-control:before,
.vx_floatingLabel_complex.vx_floatingLabel > .vx_form-control:before,
.vx_floatingLabel_active.vx_floatingLabel > .vx_form-control:before {
  line-height: 50px;
}
.vx_floatingLabel:after,
.vx_floatingLabel_complex:after,
.vx_floatingLabel_active:after,
.vx_floatingLabel.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_complex.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_active.vx_floatingLabel_complex > .vx_form-control:after {
  top: -10px;
  left: 0;
  font-size: 12px;
  color: #6C7378;
  font-weight: 500;
  transition: opacity 0.4s ease-in-out, top 0.3s ease-out;
}
.vx_floatingLabel.hasFocus:after,
.vx_floatingLabel_complex.hasFocus:after,
.vx_floatingLabel_active.hasFocus:after,
.vx_floatingLabel.hasFocus.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_complex.hasFocus.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_active.hasFocus.vx_floatingLabel_complex > .vx_form-control:after {
  color: #0070BA;
}
.vx_floatingLabel label,
.vx_floatingLabel_complex label,
.vx_floatingLabel_active label {
  position: absolute;
  left: -999em;
}
.vx_floatingLabel input,
.vx_floatingLabel_complex input,
.vx_floatingLabel_active input {
  position: relative;
  z-index: 2;
  padding: 18px 15px 0;
  background: transparent;
  color: #2C2E2F;
}
.vx_floatingLabel input.vx_form-control,
.vx_floatingLabel_complex input.vx_form-control,
.vx_floatingLabel_active input.vx_form-control {
  height: 50px;
}
.vx_floatingLabel .vx_form-control-icon,
.vx_floatingLabel_complex .vx_form-control-icon,
.vx_floatingLabel_active .vx_form-control-icon,
.vx_floatingLabel .vx_form-control-error-icon,
.vx_floatingLabel_complex .vx_form-control-error-icon,
.vx_floatingLabel_active .vx_form-control-error-icon {
  vertical-align: middle;
  position: relative;
  top: -2px;
}
.vx_floatingLabel .vx_form-control-error-icon,
.vx_floatingLabel_complex .vx_form-control-error-icon,
.vx_floatingLabel_active .vx_form-control-error-icon {
  top: -3px;
}
.vx_floatingLabel .vx_form-control-error-icon:before,
.vx_floatingLabel_complex .vx_form-control-error-icon:before,
.vx_floatingLabel_active .vx_form-control-error-icon:before {
  display: none;
}
.vx_floatingLabel .vx_form-control-icon,
.vx_floatingLabel_complex .vx_form-control-icon,
.vx_floatingLabel_active .vx_form-control-icon {
  vertical-align: middle;
}
.vx_floatingLabel.vx_has-error .vx_form-control-error-icon:before,
.vx_floatingLabel_complex.vx_has-error .vx_form-control-error-icon:before,
.vx_floatingLabel_active.vx_has-error .vx_form-control-error-icon:before,
.vx_floatingLabel.vx_has-error-with-message .vx_form-control-error-icon:before,
.vx_floatingLabel_complex.vx_has-error-with-message .vx_form-control-error-icon:before,
.vx_floatingLabel_active.vx_has-error-with-message .vx_form-control-error-icon:before {
  display: inline;
}
.vx_floatingLabel.vx_has-error,
.vx_floatingLabel_complex.vx_has-error,
.vx_floatingLabel_active.vx_has-error,
.vx_floatingLabel.vx_has-error-with-message,
.vx_floatingLabel_complex.vx_has-error-with-message,
.vx_floatingLabel_active.vx_has-error-with-message {
  border-color: #D20000;
}
.vx_floatingLabel.vx_has-error .vx_form-control,
.vx_floatingLabel_complex.vx_has-error .vx_form-control,
.vx_floatingLabel_active.vx_has-error .vx_form-control,
.vx_floatingLabel.vx_has-error-with-message .vx_form-control,
.vx_floatingLabel_complex.vx_has-error-with-message .vx_form-control,
.vx_floatingLabel_active.vx_has-error-with-message .vx_form-control {
  border-color: #D20000;
}
.vx_floatingLabel.vx_has-error .vx_form-control:hover,
.vx_floatingLabel_complex.vx_has-error .vx_form-control:hover,
.vx_floatingLabel_active.vx_has-error .vx_form-control:hover,
.vx_floatingLabel.vx_has-error-with-message .vx_form-control:hover,
.vx_floatingLabel_complex.vx_has-error-with-message .vx_form-control:hover,
.vx_floatingLabel_active.vx_has-error-with-message .vx_form-control:hover {
  background: transparent;
}
.vx_floatingLabel.vx_has-error.hasFocus:after,
.vx_floatingLabel_complex.vx_has-error.hasFocus:after,
.vx_floatingLabel_active.vx_has-error.hasFocus:after,
.vx_floatingLabel.vx_has-error-with-message.hasFocus:after,
.vx_floatingLabel_complex.vx_has-error-with-message.hasFocus:after,
.vx_floatingLabel_active.vx_has-error-with-message.hasFocus:after,
.vx_floatingLabel.vx_has-error.hasFocus.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_complex.vx_has-error.hasFocus.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_active.vx_has-error.hasFocus.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel.vx_has-error-with-message.hasFocus.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_complex.vx_has-error-with-message.hasFocus.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_active.vx_has-error-with-message.hasFocus.vx_floatingLabel_complex > .vx_form-control:after {
  color: #D20000;
}
.vx_floatingLabel.vx_has-error .vx_form-control-error-icon,
.vx_floatingLabel_complex.vx_has-error .vx_form-control-error-icon,
.vx_floatingLabel_active.vx_has-error .vx_form-control-error-icon,
.vx_floatingLabel.vx_has-error-with-message .vx_form-control-error-icon,
.vx_floatingLabel_complex.vx_has-error-with-message .vx_form-control-error-icon,
.vx_floatingLabel_active.vx_has-error-with-message .vx_form-control-error-icon {
  display: table-cell;
  visibility: visible;
  width: inherit;
  min-width: 2.25em;
  color: #D20000;
  text-align: left;
}
.vx_floatingLabel.vx_has-error:before,
.vx_floatingLabel_complex.vx_has-error:before,
.vx_floatingLabel_active.vx_has-error:before,
.vx_floatingLabel.vx_has-error-with-message:before,
.vx_floatingLabel_complex.vx_has-error-with-message:before,
.vx_floatingLabel_active.vx_has-error-with-message:before,
.vx_floatingLabel.vx_has-error:after,
.vx_floatingLabel_complex.vx_has-error:after,
.vx_floatingLabel_active.vx_has-error:after,
.vx_floatingLabel.vx_has-error-with-message:after,
.vx_floatingLabel_complex.vx_has-error-with-message:after,
.vx_floatingLabel_active.vx_has-error-with-message:after {
  color: #D20000;
}
.vx_floatingLabel.vx_has-error.hasValue input,
.vx_floatingLabel_complex.vx_has-error.hasValue input,
.vx_floatingLabel_active.vx_has-error.hasValue input,
.vx_floatingLabel.vx_has-error-with-message.hasValue input,
.vx_floatingLabel_complex.vx_has-error-with-message.hasValue input,
.vx_floatingLabel_active.vx_has-error-with-message.hasValue input,
.vx_floatingLabel.vx_has-error.hasFocus input,
.vx_floatingLabel_complex.vx_has-error.hasFocus input,
.vx_floatingLabel_active.vx_has-error.hasFocus input,
.vx_floatingLabel.vx_has-error-with-message.hasFocus input,
.vx_floatingLabel_complex.vx_has-error-with-message.hasFocus input,
.vx_floatingLabel_active.vx_has-error-with-message.hasFocus input {
  color: #2c2e2f;
}
.vx_floatingLabel_complex {
  display: block;
  padding: 0;
  height: auto;
  border: 1px solid #9da3a6;
  border-radius: 5px;
  text-align: left;
  -webkit-transition: border-color 0.15s;
  -o-transition: border-color 0.15s;
  transition: border-color 0.15s;
}
.vx_floatingLabel_complex .vx_form-control {
  display: table;
  table-layout: fixed;
  padding: 0;
  border: none;
  border-radius: 0;
  background: transparent;
}
.vx_floatingLabel_complex .vx_form-control input {
  display: block;
  width: 100%;
  border: none;
  background: none;
}
.vx_floatingLabel_complex .vx_form-control-icon + .vx_form-control input,
.vx_floatingLabel_complex .vx_form-control-icon + .vx_form-control:before,
.vx_floatingLabel_complex .vx_form-control-icon + .vx_form-control:after {
  padding-left: 8px;
  padding-right: 8px;
}
.vx_floatingLabel_complex label {
  max-width: 1px;
}
.vx_floatingLabel_complex:before,
.vx_floatingLabel_complex:after {
  content: '';
  display: none;
}
.vx_floatingLabel_complex.vx_has-message {
  border-color: #0092d1;
}
.vx_floatingLabel_complex.vx_has-error .vx_form-control:before,
.vx_floatingLabel_complex.vx_has-error-with-message .vx_form-control:before,
.vx_floatingLabel_complex.vx_has-error .vx_form-control:after,
.vx_floatingLabel_complex.vx_has-error-with-message .vx_form-control:after {
  color: #D20000;
}
.vx_floatingLabel_complex .vx_form-control-message {
  border-width: 0;
  border-top-width: 1px;
}
.vx_floatingLabel:before {
  color: #2C2E2F;
  opacity: 0;
}
.vx_floatingLabel input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 1;
}
.vx_floatingLabel input:-moz-placeholder {
  /* FF 4-18 */
  opacity: 1;
}
.vx_floatingLabel input::-moz-placeholder {
  /* FF 19+ */
  opacity: 1;
}
.vx_floatingLabel input:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 1;
}
.vx_floatingLabel input:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 1;
}
.vx_floatingLabel input:focus:-moz-placeholder {
  /* FF 4-18 */
  opacity: 1;
}
.vx_floatingLabel input:focus::-moz-placeholder {
  /* FF 19+ */
  opacity: 1;
}
.vx_floatingLabel input:focus:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 1;
}
.vx_floatingLabel.hasFocus:before,
.vx_floatingLabel.hasValue:before {
  opacity: 0;
}
.vx_floatingLabel.hasFocus:before input,
.vx_floatingLabel.hasValue:before input {
  color: inherit;
}
.vx_floatingLabel.hasFocus:after,
.vx_floatingLabel.hasValue:after {
  opacity: 1;
  top: -10px;
}
.vx_floatingLabel.hasValue:after {
  color: #2C2E2F;
}
.vx_floatingLabel_active input {
  color: transparent;
}
.vx_floatingLabel_active input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 0;
}
.vx_floatingLabel_active input:-moz-placeholder {
  /* FF 4-18 */
  opacity: 0;
}
.vx_floatingLabel_active input::-moz-placeholder {
  /* FF 19+ */
  opacity: 0;
}
.vx_floatingLabel_active input:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0;
}
.vx_floatingLabel_active input:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 1;
}
.vx_floatingLabel_active input:focus:-moz-placeholder {
  /* FF 4-18 */
  opacity: 1;
}
.vx_floatingLabel_active input:focus::-moz-placeholder {
  /* FF 19+ */
  opacity: 1;
}
.vx_floatingLabel_active input:focus:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 1;
}
.vx_floatingLabel_active:before,
.vx_floatingLabel_active.vx_floatingLabel_complex > .vx_form-control:before {
  opacity: 1;
}
.vx_floatingLabel_active:after,
.vx_floatingLabel_active.vx_floatingLabel_complex > .vx_form-control:after {
  opacity: 0;
}
.vx_floatingLabel_active.hasFocus input,
.vx_floatingLabel_active.hasValue input {
  color: inherit;
}
.vx_floatingLabel_active.hasFocus:before,
.vx_floatingLabel_active.hasFocus.vx_floatingLabel_complex > .vx_form-control:before,
.vx_floatingLabel_active.hasValue:before,
.vx_floatingLabel_active.hasValue.vx_floatingLabel_complex > .vx_form-control:before {
  opacity: 0;
}
.vx_floatingLabel_active.hasFocus:after,
.vx_floatingLabel_active.hasFocus.vx_floatingLabel_complex > .vx_form-control:after,
.vx_floatingLabel_active.hasValue:after,
.vx_floatingLabel_active.hasValue.vx_floatingLabel_complex > .vx_form-control:after {
  opacity: 1;
}
.vx_floatingLabel_active.hasFocus:after,
.vx_floatingLabel_active.hasFocus.vx_floatingLabel_complex > .vx_form-control:after {
  color: #0070BA;
}
.vx_floatingLabel_active.vx_is-disabled {
  background: #F5F7FA;
}
.vx_floatingLabel_active.vx_is-disabled.hasFocus:after,
.vx_floatingLabel_active.vx_is-disabled.hasFocus.vx_floatingLabel_complex > .vx_form-control:after {
  color: #6C7378;
}
.vx_floatingLabel_active.vx_is-disabled input[disabled],
.vx_floatingLabel_active.vx_is-disabled input[disabled]:hover,
.vx_floatingLabel_active.vx_is-disabled input[disabled]:active,
.vx_floatingLabel_active.vx_is-disabled input.vx_is-disabled,
.vx_floatingLabel_active.vx_is-disabled input.vx_is-disabled:hover,
.vx_floatingLabel_active.vx_is-disabled input.vx_is-disabled:active {
  background: transparent;
  color: #6C7378;
}
.vx_has-spinner-small,
.vx_has-spinner-medium,
.vx_has-spinner-large {
  overflow: hidden;
}
.vx_has-spinner-small.vx_has-spinner-bottom::after,
.vx_has-spinner-medium.vx_has-spinner-bottom::after,
.vx_has-spinner-large.vx_has-spinner-bottom::after {
  top: auto;
}
.vx_btn {
  appearance: none;
}
.vx_btn[disabled] {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.2);
  border-style: none;
}
.vx_btn[disabled]:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.2);
  border-style: none;
}
.vx_btn[disabled].vx_btn-secondary_reversed {
  color: rgba(255, 255, 255, 0.4);
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.4);
}
@media (max-width: 700px) {
  .vx_globalNav-listItem_logout {
    margin-right: 1em;
  }
}
.vx_globalNav-container {
  max-width: 1256px !important;
  padding-left: 48px !important;
  padding-right: 48px !important;
}
@media (max-width: 1160px) {
  .vx_globalNav-container {
    max-width: none !important;
  }
}
@media (max-width: 900px) {
  .vx_globalNav-container {
    max-width: none !important;
  }
}
@media (max-width: 700px) {
  .vx_globalNav-container {
    max-width: none !important;
  }
}
.vx_globalNav-link_settings {
  padding-top: 1.5rem;
}
.vx_globalFooter-content {
  padding-right: 48px;
  padding-left: 48px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1256px;
}
@media (max-width: 700px) {
  .vx_globalFooter-content {
    padding-left: 36px;
    padding-right: 36px;
  }
}
.footerSmallText:empty {
  display: none;
}
.vx_mainContent {
  max-width: none !important;
  padding-bottom: 0 !important;
  margin: 0 !important;
}
.vx_floatingLabel .vx_select::before {
  font-weight: lighter;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
html {
  font-size: 16px;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  text-size-adjust: 100%;
  background-image: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2C2E2F;
}
body div[role='tooltip'] {
  z-index: 2002;
}
header.pp-header {
  z-index: 2001;
}
body.menu-open-dw {
  transform: none !important;
}
footer.vx_globalFooter {
  margin-top: 0 !important;
}
.pp-header.dw-pp-header.pp-header--menu-open .main-menu .main-menu-list a.pypl-header__subnav-control::after {
  border: 0.125rem solid transparent;
}
.global-footer.rebrand-footer hr.hidden-lg-down.primary {
  border: 1px solid #cbd2d6;
  border-bottom: 0;
}
.global-footer .footer-main,
.global-footer .footer-secondary,
.global-footer .footer-tertiary {
  margin: 0 !important;
}
#ccpaCookieContent_wrapper {
  width: 100%;
}
