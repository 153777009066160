@import (reference) '~stylesheets/modules/type';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';

.title {
  text-align: center;
  margin-bottom: 32px;
}

.category {
  margin-bottom: 16px;
}

.category-dropdown select {
  height: 64px;
}

.textbox-wrapper {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 32px;
  }

  &.zipcode {
    margin-bottom: 12px;
  }
}

.tip-wrapper {
  margin-bottom: 32px;
}

.charity-header {
  color: #687173;
}

.gn-disclaimer {
  margin-top: 24px;
  margin-bottom: 24px;

  a {
    font-weight: 600;
  }

  a:focus {
    text-decoration: underline !important;
  }
}

.form__button {
  text-align: center;
}
