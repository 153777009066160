@import (reference) '~stylesheets/modules/type';
@import (reference) '~pp-tokens/build/web/less/tokens';
@import (reference) '~stylesheets/colors';

.title {
  text-align: center;
  margin-bottom: 4px;
}

.sub-title {
  color: @ppui-v2-color-black;
  margin-bottom: 32px;
}

.row-margin {
  margin-bottom: 10px;
}

.upload-card {
  justify-content: center;
  align-items: center;
  display: flex;
  color: #005ea6;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
}

.image-checkbox-container {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;

  span {
    border-radius: 50% !important;
  }

  span::after {
    border-radius: 50% !important;
  }
}

.image-delete-container {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  color: @white;
  background-color: @dark-bg;
  flex-direction: column;
}
