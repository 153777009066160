@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~stylesheets/modules/type';
@import (reference) '~pp-tokens/build/web/less/tokens';
@import (reference) '~stylesheets/twbs';
@import (reference) '~stylesheets/colors';

.initials {
  width: 100%;
  text-align: center;
  color: white;
  .text-3();
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 400;
}

.small-initials {
  composes: initials;
  .text-5();
  font-weight: 700;
}

.anonymous-avatar {
  background: @ppui-v2-color-green-500 !important;
  color: @sysColorSuccessMainHover !important;
}
