@import (reference) '~stylesheets/twbs';
@import (reference) '~stylesheets/colors';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~stylesheets/modules/type';
@import (reference) '~pp-tokens/build/web/less/tokens';

@modal-content-padding: 24px;
@modal-content-width: 450px;
@modal-max-width: 612px;
@wide-modal-content-width: 448px;
@wide-modal-max-width: 720px;
@modal-no-header-padding-top: 24px;
@modal-header-height-mobile: 80px;
@modal-header-height-desktop: 90px;
@modal-header-height-mobile-with-header: 265px;
@modal-header-height-desktop-with-header: 296px;

@modal-icon-size: 30px;
@modal-icon-margin-top: 30px;
@modal-icon-margin-bottom: 30px;
@modal-icon-total-size: @modal-icon-size + @modal-icon-margin-top +
  @modal-icon-margin-bottom;

.modal-overflow-container {
  position: fixed;

  @media (min-width: @screen-sm-min) {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 2000;
  }

  @media (max-width: @screen-sm-min) {
    // (1) While animating, we still want to show the stuff behind
    // so we use the dekstop's code to do that....

    &:global(.modal-enter-active) {
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 2000;
    }

    // (2) But, when animation completes, we want to hide the stuff behind. Here we're assuming
    // that "stuff behind" are a sibling of the modal.
    // We're setting it to position: relative (as opposed to static) so that z-indexes work

    &:global(:not(.modal-enter-active):not(.modal-enter)) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: auto;

      & ~ * {
        display: none;
      }
    }
  }

  .modal-wrapper {
    min-height: 100vh;
    background-color: @ppui-v2-color-neutral-100;

    &.glimpse {
      background-color: @glimpse-background;
    }
  }
}

:global(.limit-scrollability) {
  @media (min-width: @screen-sm-min) {
    height: 100vh;
    overflow-y: hidden;
  }

  // hack-ish way to have a media query selecting only webkit browsers
  @media (min-width: @screen-sm-min) and (-webkit-min-device-pixel-ratio: 0) {
    overflow-y: scroll;
  }
}

.white-part {
  min-height: 100%;
  margin: 0 auto;
  background-color: @vxWhite;
  max-width: @modal-max-width;

  @media (max-width: @screen-sm-min) {
    max-width: none;
    min-height: none;
  }
  width: 100%;
  position: relative;
}

.outer-wide-view-container {
  max-width: @wide-modal-max-width;
}

.modal-header {
  width: 100%;
  height: 58px;
  padding: 12px 16px;

  .left-header {
    float: left;
    position: relative;
    z-index: 1;
  }

  .x-button {
    display: block;
    float: right;
    cursor: pointer;
    position: relative;
    z-index: 1;
  }
}

.contents {
  margin: 0 auto;
  width: 100%;
  max-width: @modal-content-width;
  min-height: calc(~'100vh - ' @modal-icon-total-size);
  overflow: hidden;

  form {
    margin-bottom: 0; // fixes margin additions that push down the pp-icon
  }
}

.inner-wide-view-container {
  max-width: @wide-modal-content-width;
}

.modal-progress {
  width: 100%;

  .progress-bar {
    padding-bottom: 0.25rem !important;
  }
}

.stepName {
  @media (max-width: @screen-sm-min) {
    padding: 90px 16px !important;
  }
}
