@import (reference) '~pp-tokens/build/web/less/tokens';
@import (reference) '~stylesheets/twbs';
@import (reference) '~stylesheets/modules/type';
@import (reference) '~stylesheets/colors';

.content-wrapper {
  display: flex;
  margin: 0 4px 24px;
  cursor: pointer;
  justify-self: start;
  align-items: center;
  padding: 1rem;

  .content-icon {
    margin: auto 16px auto -8px !important;
  }

  .content-tile {
    margin: 0;
  }
}

a.card-link {
  text-decoration: none;
}

.caption-text {
  color: @sysColorBackgroundMediumContrast !important;
}

.caption-text-small-group {
  color: @ppui-v2-color-black !important;
}
