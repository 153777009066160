@import (reference) '~stylesheets/colors';
@import (reference) '~pp-tokens/build/web/less/tokens';

.container {
  width: 100%;

  &__top {
    display: flex;
    margin: 0 auto;
    max-width: 1320px;
    padding: 32px 24px;
    justify-content: space-between;
  }

  &__body {
    width: 100%;
    background-color: @white;
  }
}

.body__content {
  margin: 0 auto;
  max-width: 1320px;
  display: flex;
}

.content {
  &__sidebar {
    z-index: 1;
    width: 440px;
    padding: 32px 16px 0;
    box-shadow: 20px 0 10px -8px rgba(0, 0, 0, 0.08);
  }

  &__details {
    float: none;
    overflow: hidden;
    flex: 1;
    background-color: @white;
    padding: 32px 24px 0 49px;
  }
}

.sidebar__cards {
  height: 100%;
  padding: 15px 12px 0 0;
  overflow-y: auto;
}

.cards__start-fundraiser-btn {
  display: none;
}

.visible-md {
  display: none !important;
}

@media (max-width: @ppui-grid-media-query-xl) {
  .container__top {
    padding-left: 44px;
    padding-right: 44px;
  }

  .content {
    &__sidebar {
      width: 407px;
      z-index: 0;
    }

    &__details {
      padding-left: 24px;
    }
  }
}

@media (max-width: @ppui-grid-media-query-lg) {
  .content__sidebar {
    width: 364px;
  }
}

@media (max-width: @ppui-grid-media-query-md) {
  .container__top {
    padding: 24px 16px;
  }

  .top__heading-text {
    font-size: 1.75rem !important;
  }

  .content {
    &__sidebar {
      width: 100%;
      padding: 16px;
    }

    &__details {
      position: absolute;
      width: 100%;
      top: 0;
      left: 100%;
      transition: left 0.25s;
      height: 100%;
      overflow-y: auto;
      padding: 21px 48px 0;
    }
  }

  .cards__start-fundraiser-btn {
    display: block;
    text-align: center;
    margin-left: 10px;
  }

  .visible-md {
    display: block !important;
  }

  .hidden-md {
    display: none !important;
  }
}

@media (max-width: @ppui-grid-media-query-sm) {
  .content__details {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.content__details--slide-in {
  left: 0;
}
