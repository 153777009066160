@import (reference) '~stylesheets/modules/type';
@import (reference) '~stylesheets/colors';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~pp-tokens/build/web/less/tokens';

.preview-container {
  cursor: move;
  box-sizing: border-box;
  border: 1px solid #b7bcbf;
  border-radius: 4px;
  background-color: #fff;
  padding: 0 12.5;

  .drag {
    display: flex;
    justify-content: center;
    color: #7c888a;
    height: 34.5px;
    align-items: center;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .action {
      color: @ppui-v2-color-blue-400;
      margin-left: 16px;
    }
  }
}
