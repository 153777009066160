@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~stylesheets/modules/type';
@import (reference) '../../../stylesheets/colors';
@import (reference) '~paypalme-components/less/tooltip';
@import (reference) '~pp-tokens/build/web/less/tokens';

.copy-box-wrapper {
  position: relative;
}

.copy-box {
  width: 100%;
  border-radius: 4px;
  padding: 16px 0;
  text-overflow: ellipsis;
  text-align: left;
  color: @pp-text-color;
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  flex-grow: 1;
  .text-5_lighter();
  background-color: @ppui-v2-color-neutral-100;

  input[type='text'] {
    border: 0;
    margin: 0;
    padding: 0;
    outline: none;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 1.2;
    font-size: 16px;
    background-color: #f7f5f0;
    color: black;
  }
}

.copy-box-disabled {
  composes: copy-box;

  input[type='text'] {
    color: @vxSecondaryLightGray;
  }
}

.copy-link {
  margin-left: 9px;
  position: relative;

  a {
    .text-5_lighter-inline();
    color: #142c8e;
    padding: 4px 12px 6px;
    border: 1px solid #142c8e;
    border-radius: 15px;
    font-size: 14px;
    background-color: transparent;
    font-family: @fontFamilyBig;
  }
}

.copied-tooltip {
  .paypalme-tooltip(6px);
}

.tooltip-enter {
  opacity: 0.01;
}

.tooltip-enter.tooltip-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.tooltip-leave {
  opacity: 1;
}

.tooltip-leave.tooltip-leave-active {
  opacity: 0.01;
  transition: opacity 250ms ease-out;
}
