.suggestions {
  border-radius: 0 0 5px 5px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  list-style: none;
  margin: 0;
  overflow-y: auto;
  padding: 0 0.25rem;
  width: 100%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
  background-color: #fff;
  max-height: 400px;
  border: solid 1px #cbd2d6;
}

.suggestionborder {
  border: none;
}

.suggestions li {
  padding: 0.5rem 1rem;
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.suggestions li:hover {
  background-color: #f5f7fa;
}

.highlightedSuggestion {
  background-color: #f5f7fa;
}

.loadingSpinner {
  padding-left: 45%;
  padding-top: 5%;
}

.search-input-border-radius input {
  border-radius: 36px !important;
}
