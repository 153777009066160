.mb24 {
  margin-bottom: 24px;
}

.title {
  text-align: center;
  margin-bottom: 8px;
}

.subtitle {
  margin-bottom: 32px;
  text-align: left;
}

.footerInfo {
  .mb24();
  font-size: 14px !important;
}
