@import (reference) '~stylesheets/modules/type';
@import (reference) '~stylesheets/twbs';
@import (reference) '~pp-tokens/build/web/less/tokens';

.share-icons {
  text-align: center;
  margin: 22px 0 0;

  &:empty {
    display: none;
  }

  .share-icon {
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
    color: #142c8e;
    cursor: pointer;
    background-color: @ppui-v2-color-neutral-100 !important;
    padding: 10px;
    border-radius: 50%;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: @screen-sm-min) and (max-width: @screen-lg-min) {
      margin-left: 3px;
      margin-right: 3px;

      .sub-text {
        display: none;
      }
    }
  }
}

.sub-text {
  .text-6_lighter();
  color: @vxGray;
}

.row {
  padding: 0 0 1.5em;
  position: relative;
}
