.tab_layout button {
  font-weight: bold;
}

.tab_content {
  margin: 0;
}

.contribution_content {
  margin: -29px 0 0;
}

.organizer-update-doner {
  margin-top: 2rem;
}

.show-more {
  text-align: center;
}
