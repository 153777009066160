@import (reference) '~stylesheets/twbs';
@import (reference) '~pp-tokens/build/web/less/tokens';

.title {
  margin-bottom: 12px;
}

.list-container {
  overflow: scroll;
}

.list-container:not(:last-child) {
  margin-bottom: 30px;
}

.activity-title {
  margin-bottom: 32px;
}

.show-more {
  text-align: center;
  padding: 10px 0 20px;
}

.update-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activity-empty {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem !important;
  margin: 20px 0;
  border: none !important;

  @media (max-width: @screen-sm-min) {
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 !important;
  }
}

.empty-post-button {
  width: 195px !important;

  @media (max-width: @screen-lg-min) {
    width: 275px !important;
  }

  @media (max-width: @screen-md-min) {
    width: 320px !important;
  }

  @media (max-width: @screen-sm-min) {
    width: 137px !important;
  }
}

a.postupdate-link:focus {
  display: inline-block;
  border-radius: 50%;
}

.postupdate-icon {
  border: 1px solid @ppui-v2-color-blue-600 !important;
  padding: 5px !important;
}

.empty-update-right-side {
  padding: 16px;
}

.empty_text {
  padding: 0 16px;

  @media (max-width: @screen-lg-min) {
    padding: 0 16px;
  }

  @media (max-width: @screen-md-min) {
    margin: 15px 0;
    padding: 0 16px;
  }

  @media (max-width: @screen-sm-min) {
    margin: 15px 0;
    padding: 0;
  }
}
