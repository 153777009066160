@import (reference) '~stylesheets/modules/type';

.title {
  text-align: center;
  margin-bottom: 48px;
}

.legend {
  margin-bottom: 1rem;
}

.textbox-wrapper {
  margin-bottom: 62px;
  margin-top: 30px;

  @media screen and (max-width: 540px) {
    width: 95%;
    margin: 30px auto;
  }
}

.thumbnail {
  display: flex;
  align-items: center;
  min-height: 1.875rem;
  margin-right: 1rem;
  width: 45px;
  min-width: 45px;
  height: auto;
}

.item-text {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: 2.125rem;
}

@media screen and (max-width: 592px) {
  .item-text {
    margin-right: 1rem;
  }
}
