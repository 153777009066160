@import (reference) '~stylesheets/twbs';

.container {
  width: 100%;
}

.edit-link {
  margin-bottom: 44px;
  display: block;
}

.no-one {
  margin-top: 48px;
  text-align: center;
}

.add-currency {
  display: inline-block;
}

.progress-bar {
  margin-bottom: 92px;
}

.manage-icons {
  color: rgb(0, 112, 186);
  background-color: #f5f7fa;
  padding: 6px;
  border-radius: 50%;
}

.manage-icon-row {
  margin-bottom: 30px;
  margin-left: 6%;
  margin-right: 6%;
}

.manage-icon-col {
  text-align: center;
  margin-bottom: 30px;
}

.manage-icon-text-align {
  margin-top: 0.8rem;
  text-align: center;
}

a.manage-link:hover {
  text-decoration: none;
}

.manage-icon-row > div.manage-icon-col > a.manage-link:focus {
  display: inline-block;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.contributors {
  text-align: center;
}

.btn {
  clear: both;
  margin: 0 auto 24px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.pool-header {
  margin-bottom: 36px;

  & > :nth-child(2),
  :nth-child(3) {
    justify-content: center;
  }
}

.pool-header-left {
  margin-bottom: 1.5rem;

  & > :nth-child(2),
  :nth-child(3) {
    justify-content: start;
  }
}

.activityLoader {
  justify-content: center;
  display: flex;
}
