@import (reference) '~stylesheets/modules/type';

.charityOrganiserDetailsContainer {
  margin: 32 0 0;
  display: flex;
  justify-content: start;
  align-items: center;
}

.charityTextLight {
  color: #6c7378;
}

.charityOrganiserDetails {
  padding-left: 5px;
}

.charityOrganiserDetailsCaption {
  margin-left: 4px;
  flex: 1;
}

.details-margin {
  margin: 38px 0 0;

  .expandable-content {
    margin-top: 28px;
  }
}

.strong {
  font-weight: bold;
}
