@import (reference) '~stylesheets/twbs';
@import (reference) '~stylesheets/colors';

.vx_has-spinner-small,
.vx_has-spinner-medium,
.vx_has-spinner-large {
  overflow: hidden;

  &.vx_has-spinner-bottom {
    &::after {
      top: auto;
    }
  }
}

.vx_btn {
  appearance: none;

  &[disabled] {
    background-color: fade(@black, 10%);
    color: fade(@black, 20%);
    border-style: none;

    &:hover {
      background-color: fade(@black, 10%);
      color: fade(@black, 20%);
      border-style: none;
    }

    &.vx_btn-secondary_reversed {
      color: fade(@white, 40%);
      border-style: solid;
      border-color: fade(@white, 40%);
    }
  }
}

.vx_globalNav-listItem_logout {
  @media (max-width: @screen-sm-min) {
    margin-right: 1em;
  }
}

.vx_globalNav-container {
  max-width: @screen-lg-min + (48px * 2) !important;
  padding-left: 48px !important;
  padding-right: 48px !important;

  @media (max-width: @screen-lg-min) {
    max-width: none !important;
  }

  @media (max-width: @screen-md-min) {
    max-width: none !important;
  }

  @media (max-width: @screen-sm-min) {
    max-width: none !important;
  }
}

.vx_globalNav-link_settings {
  padding-top: 1.5rem;
}

.vx_globalFooter-content {
  .pools-grid-container();
}

.footerSmallText:empty {
  display: none;
}

.vx_mainContent {
  max-width: none !important;
  padding-bottom: 0 !important;
  margin: 0 !important;
}

.vx_icon_align(@color) {
  height: 98px;
  width: 98px;
  display: block;
  margin: 0 auto 26px;
  color: @color;

  // Fixes the vx icon lib's line height
  line-height: 98px;

  &::before {
    position: relative;
    top: -25px;
    left: -17px;
  }
}

.vx_floatingLabel {
  .vx_select::before {
    font-weight: lighter;
  }
}
