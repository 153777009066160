.collapsible {
  display: contents;
  margin-bottom: 12px;
  white-space: pre-line;
  word-wrap: break-word;
}

.collapsed {
  overflow: hidden;
  display: -webkit-box;

  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}

.header {
  margin-bottom: 24px;
}

.footer {
  // Figma margin - Button's padding = 12px visual separation
  margin-bottom: calc(12px - 0.75rem);
}

.button {
  text-align: center;
}
