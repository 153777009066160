@import (reference) '~stylesheets/twbs';
@import (reference) '~stylesheets/colors';

.dialog-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: @dialog-background;
  z-index: 10000;
}

.dialog {
  position: absolute !important;
  top: 50%;
  left: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  max-height: 474px;
  min-height: 300px;
  border-radius: 6px;

  section {
    padding: 0;

    @media (max-width: @screen-sm-min) {
      padding: 57px 18px 40px;
    }
  }
}

.small-dialog {
  composes: dialog;
  width: 340px !important;
}

.medium-dialog {
  composes: dialog;
  width: 452px !important;
}

.large-dialog {
  composes: dialog;
  width: 612px !important;
}

.small-dialog,
.medium-dialog,
.large-dialog {
  @media (max-width: @screen-sm-min) {
    width: 340px !important;
  }
}

.dialog-contents {
  padding: 63px 70px 72px;

  @media (max-width: @screen-sm-min) {
    padding: 57px 40px 40px;
  }
}

.x-button {
  line-height: 1;
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1;
}
