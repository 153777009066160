@import (reference) '~pp-tokens/build/web/less/tokens';

.buttonContainer {
  display: flex;
  align-items: center;
  width: 100%;

  div:nth-child(2) {
    > span {
      margin-left: 18px;
      font-family: @ppui-font-family-400;
      font-size: 20px;
      line-height: 28px;
      width: 100%;
    }
  }
}

.listContainer {
  margin: 0 0.375rem;

  li {
    display: flex;
    align-items: center;
    height: 87px;
  }
}
