@import (reference) '~stylesheets/modules/type';
@import (reference) '~stylesheets/vx_extensions';

.pledge {
  &__success {
    text-align: center;
    margin-top: 133px;

    @media (max-width: 760px) {
      margin-top: 0;
    }
  }
}

.form {
  &__title {
    margin-bottom: 1.5rem;
  }

  &__currency-input {
    margin-bottom: 0.5rem;
  }
}

.success {
  &__icon {
    width: 120px;
  }

  &__title {
    margin: 2.5rem 0 2.25rem;
  }
}

.cta {
  margin-top: 60px;
  text-align: center;
}
