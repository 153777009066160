@import (reference) '~stylesheets/modules/type';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';

.large-icon {
  height: 98px;
  width: 98px;
  display: block;
  margin: 0 auto 26px;

  // Fixes the vx icon lib's line height
  line-height: 98px;

  &::before {
    position: relative;
    top: -25px;
    left: -17px;
  }

  // These are vxlib's classes, so we wrap them in :global()
  &:global(.icon-attention-large) {
    color: @vxOrange;
  }

  &:global(.icon-critical-large) {
    color: @vxRed;
  }
}

.standardError {
  text-align: center;

  .error-icon {
    width: 160px;
    margin-bottom: 30px;
  }

  .custom-icon {
    margin-bottom: 30px;
  }

  @media (max-width: 426px) {
    padding: 0 16px;
  }
}

.title {
  .text-2();
  text-align: center;
  margin-top: 15px;
  margin-bottom: 12px;
}

.subtitle {
  .text-5_lighter();
  text-align: center;
  margin-bottom: 36px;

  &:last-child {
    margin-bottom: 0;
  }
}

.iconAlign {
  margin-bottom: 17px;
}
