@import (reference) '~stylesheets/modules/type';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~stylesheets/vx_extensions';

.title {
  text-align: center;
  margin-bottom: 8px;
}

.subtitle {
  margin-bottom: 32px;
  text-align: center;
}

.textarea-wrapper {
  margin-bottom: 32px;
}

.img-container-header {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  img {
    height: 160px;
    width: 160px;

    @media (max-width: @screen-sm-min) {
      height: 136px;
      width: 136px;
    }
  }
}

.publish-container {
  @media (max-width: @screen-sm-min) {
    padding: 0 16px;
  }
}
