.dragzone {
  box-sizing: border-box;
  height: 130px;
  border: 1px dashed #909191;
  border-radius: 5px;
  background-color: #fff;
}

.dragover {
  background-color: #f5f7fa;
}

.not-supported {
  cursor: not-allowed;
  background-color: #fff;
}

.dragzone:hover {
  background-color: #f5f7fa;
}
