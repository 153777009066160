@import (reference) '~stylesheets/twbs';

@transition-duration: 250ms;

:global {
  .modal-enter {
    transform: translateY(100%);
    will-change: transform;
  }

  .modal-enter-active {
    transform: translateY(0%);
    transition: transform @transition-duration;

    // While animating, don't allow the user to touch any of the stuff behind (== siblings)
    & ~ * {
      pointer-events: none;
    }
  }

  .modal-leave {
    transform: translateY(0%);
    will-change: transform;

    &.modal-leave-active {
      transform: translateY(100%);
      transition: transform @transition-duration;
    }
  }
}
