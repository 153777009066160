@import (reference) '~vxpattern-lib-paypal/dist/components/alerts/_alerts';

.alert {
  .vx_alert();
  border-left-width: 1px;
  border-right-width: 1px;
  border-radius: 5px;
}

.alert-help {
  composes: alert;
  .vx_alert-help();
}

.alert-warning {
  composes: alert;
  .vx_alert-warning();
}

.alert-critical {
  composes: alert;
  .vx_alert-critical();
}

.alert-success {
  composes: alert;
  .vx_alert-success();
}

.alert-text {
  .vx_alert-text();
}
