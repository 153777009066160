@import (reference) '~stylesheets/twbs';
@import (reference) '~stylesheets/variables';
@import (reference) '~pp-tokens/build/web/less/tokens';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';

.content-inside {
  padding-bottom: @footer-height + @footer-margin;
  background: @ppui-v2-color-neutral-100;

  &.white-bg {
    background: @ppui-color-white;
  }

  &.no-header {
    padding-top: 0;
  }

  &.no-footer {
    padding-bottom: 0;
  }
}

.clear {
  clear: both;
}

.marketing-header-background {
  background: white;

  header::before {
    height: 72px !important;
  }

  @media (max-width: 768px) {
    height: 72px;
  }
}

div.background {
  background: @ppui-color-white;
  padding-bottom: 16px;
  z-index: 0 !important;
}

.sticky-footer-container {
  display: flex;
  flex-direction: column;
}

.sticky-footer {
  margin-top: auto;
}
