@import (reference) '~stylesheets/twbs';

.donate-button {
  margin-top: 0;
  margin-bottom: 0;

  display: block;
  min-width: 0; // HACK: override vxlib's bug

  border: none;
  color: black;
  font-weight: bold;
  width: 100%;

  &.preview {
    cursor: pointer !important;
    pointer-events: none !important;
    width: 100% !important;
  }
}

.donate-disabled-wrapper {
  display: flex;
  margin-bottom: 100px;
}

.donate-disabled-text {
  padding: 2px 5px;
}
