@import (reference) '~stylesheets/twbs';
@import (reference) '~stylesheets/colors';
@import (reference) '~stylesheets/modules/type';
@import (reference) '~paypalme-components/less/tooltip';
@import (reference) '~pp-tokens/build/web/less/tokens';

.title {
  .text-2();
  margin-bottom: 12px;
}

.list-container {
  overflow: scroll;
}

.list-container:not(:last-child) {
  margin-bottom: 30px;
}

.activity-title {
  margin-bottom: 32px;
}

.show-more {
  text-align: center;
  padding: 10px 0 20px;
  cursor: pointer;
}

.update-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activity-empty {
  background-color: @ppui-v2-color-neutral-100 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem !important;
  margin: 20px 0;

  @media (max-width: @screen-sm-min) {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

.empty-image {
  height: 42px;
  width: 43px;
  padding-right: 11px;
}

.empty-update-text {
  @media (max-width: @screen-sm-min) {
    width: 200px;
  }
}

.empty-post-button {
  width: 47% !important;

  @media (max-width: @screen-sm-min) {
    width: 50% !important;
    margin-top: 10px;
  }

  @media (max-width: @screen-md-min) {
    width: 77% !important;
  }
}

a.postupdate-link:focus {
  display: inline-block;
  border-radius: 50%;
}

.postupdate-icon {
  border: 1px solid @ppui-v2-color-blue-600 !important;
  padding: 5px !important;
}

.list-updates {
  @media (max-width: @screen-sm-min) {
    padding: 0 16px;
  }
}
