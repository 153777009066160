@import (reference) '~stylesheets/twbs';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~stylesheets/modules/type';

.ended-title {
  margin-bottom: 12px;
}

.title {
  margin-bottom: 20px;
  text-align: center;
}

.subtitle {
  margin-bottom: 20px;
}

.textbox-wrapper {
  margin: 12px 0;

  &:last-child {
    margin-bottom: 20px;
  }
}

.description-container {
  width: 100%;
}

.textbox-wrapper {
  position: relative;
}

.delete-link {
  display: flex;
}

.delete-icon {
  font-size: 16px;
  margin-top: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.save-button {
  margin-top: 36px;
  width: 100%;
}

.delete-update {
  margin-top: 36px;
}
