@import (reference) '~stylesheets/twbs';

.header-section {
  text-align: center;
}

.title {
  margin-bottom: 16px;
  text-align: center;
  font-style: normal;

  @media (max-width: @screen-sm-min) {
    margin-top: 15px;
  }
}

.button-cf {
  margin-bottom: 16px;
  width: 100%;
}

.top-alignment {
  margin-top: 20px;
}
