@import (reference) '~stylesheets/vx_extensions';

.empty-image {
  height: 96px;
  width: 96px;
}

.category-selection-image {
  height: 160px;
  width: 160px;

  @media (max-width: @screen-sm-min) {
    height: 136px;
    width: 136px;
  }
}

.creation-share-image {
  height: 160px;
  width: 160px;

  @media (max-width: @screen-sm-min) {
    height: 136px;
    width: 136px;
  }
}

.state-image {
  width: 96px;
}
