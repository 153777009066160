@import (reference) '~stylesheets/modules/type';

.title {
  .text-2();
  margin-bottom: 12px;
}

.subtitle {
  .text-legal();
  margin-bottom: 24px;
}

.balance {
  .text-5_lighter();
  margin-bottom: 24px;
}

.list-container {
  overflow-y: auto;
}

.list-container:not(:last-child) {
  margin-bottom: 30px;
}

.activity-title {
  margin-bottom: 32px;
}
