@import (reference) '~stylesheets/modules/type';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';

.title {
  .text-5_lighter();
  padding-bottom: 4px;
  margin-bottom: 15px;
  border-bottom: 1px solid @vxSecondaryLightGray;
}

.row {
  display: none;
}

.more-link {
  padding-top: 0.75em;
  display: block;
  .text-5();
}

.date-row {
  color: #687173;
  text-transform: uppercase;
  margin-bottom: 4px;

  &:not(:first-child) {
    padding-top: 0.75em;
  }
}

.activity-link {
  padding: 0;
  position: static;
  text-decoration: none !important;
}

.show-more {
  text-align: center;
  padding: 10px 0 20px;
}
