.zero-fundraiser {
  margin-top: 48px;
  text-align: center;

  &__illustration-image {
    width: 153px;
    margin-bottom: 24px;
  }

  &__title,
  &__subtitle {
    margin-bottom: 16px;
  }
}

.state-pictograms {
  display: flex;
  flex-direction: column;
  align-items: center;
}
