@import (reference) '~stylesheets/modules/type';
@import (reference) '~stylesheets/twbs';
@import (reference) '~pp-tokens/build/web/less/tokens';

.margin-bottom-12 {
  margin-bottom: 12px;
}

.ppgf-all-items-container {
  overflow-x: auto;
  overflow-y: hidden;
}

.ppgf-flex-container {
  display: flex;
  align-items: center;
  margin: auto;
  overflow-x: auto;
  overflow-y: hidden;

  @media (max-width: @screen-sm-min) {
    display: block;
    max-width: 100%;
    margin: auto;
    height: auto;
  }
}

.ppgf-step-detail {
  display: block;

  img {
    height: 117.33px;
    margin: 0 auto;
    display: block;
  }

  @media (max-width: @screen-sm-min) {
    display: flex;
    max-height: auto;
    text-align: center;
    margin-bottom: 35px;

    img {
      height: 50px;
      width: 50px;
    }
  }
}

.ppgf-right-arrow-container {
  text-align: center;
  max-height: 173px;
  flex: 0 0 72px;
}

.more-details-button {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 30px;
  overflow-x: auto;
}

.ppgf-container {
  max-width: 1112px;
  margin: 0 auto;
  padding: 0 16px;
}

.ppgf-container-title {
  padding-top: 71.5px;
  text-align: center;
  margin-bottom: 24px;

  @media (max-width: @screen-sm-min) {
    padding-top: 48px;
    margin-bottom: 25px;
    text-align: center;
  }
}

.sub-text {
  padding: 10px 24px;

  @media (max-width: @screen-sm-min) {
    padding-top: 0;
    text-align: left;
  }
}

.ppgf-carousel-container {
  width: 92%;
  max-width: 1300px;
  text-align: center;
  margin: 5px auto 24px;

  @media (max-width: 1200px) {
    margin: 10px auto 5px;
  }

  @media (max-width: 767px) {
    margin: 10px auto 5px;
    width: 320px;
  }
}

.ppgf-carousel-title {
  padding: 48px 0 30px;
  text-align: center;

  @media (max-width: 767px) {
    padding: 24px 0 30px;
    font-size: 28px;
    line-height: 1.2;
  }
}

.ppgf-carousel-image {
  margin: auto;
  padding: 0 20px 24px;

  @media (max-width: 1063px) {
    padding: 0 10px 20px;
    width: 136px;
  }
}

.ppgf-carousel-slide {
  width: 50%;
  text-align: center;
  margin: auto;

  @media (max-width: 479px) {
    width: 90%;
  }
}

.ppgf-container-title-small {
  padding-top: 48px;
  margin-bottom: 0;
  text-align: center;
}

.ppgf-horizontal-scroll {
  max-height: 366px;
}

.ppgf-step-text {
  text-align: left;
  padding-top: unset;

  a {
    color: @ppui-v2-color-blue-400;
  }
}

.ppgf-button-container {
  text-align: center;
  margin: 48px 0;

  @media (max-width: 1063px) {
    margin: 24px 0;
  }
}
