@import (reference) '~stylesheets/modules/type';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~pp-tokens/build/web/less/tokens';

.done-btn {
  margin-top: 40px;
}

.done-btn,
.title,
.tip,
.remove-image {
  user-select: none;
}

.title {
  .text-2();
  margin-bottom: 24px;
  user-select: none;
}

.title_cf {
  margin-bottom: 22px;
}

.image-container {
  margin-bottom: 40px;
}

.upload-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 130px;
  cursor: pointer;

  .upload-info-text {
    text-align: center;

    span {
      color: @ppui-v2-color-blue-400;
      font-size: 16px;
    }
  }

  .upload-info-icon {
    color: @ppui-v2-color-grey-600;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.tip {
  margin: 0;

  span {
    color: @ppui-v2-color-grey-600;

    a,
    a:visited,
    a:hover {
      color: @ppui-v2-color-grey-600;
      text-decoration-color: @ppui-v2-color-grey-600;
    }
    font-family: @fontFamilyBig;
  }
}

.remove-image {
  margin-bottom: 36px;
  display: inline-block;
  .text-5_lighter();
}

.choose-background {
  text-align: center;
  width: 100%;
  margin-bottom: 48px;
  .text-5_lighter();
}

.choose-color {
  width: 100%;
  .text-legal();
  text-align: center;
  margin-top: 30px;
  margin-bottom: 27px;
}

.color-form-container {
  width: 300px;
  margin: 0 auto 53px;
}

.color-form {
  text-align: center;
}
