@import (reference) '~stylesheets/twbs';
@import (reference) '~stylesheets/modules/type';
@import (reference) '~paypalme-components/less/tooltip';
@import (reference) '~pp-tokens/build/web/less/tokens';
@import (reference) '~stylesheets/colors';

.errorMsg {
  margin-bottom: 16px;
  margin-left: 6px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
}

.sub-text {
  .text-6_lighter();
  margin-left: 8px;
  color: @vxGray;
}

.copy-box-wrapper {
  position: relative;

  span {
    vertical-align: initial !important;
  }
}

a.copy-wrapper {
  text-decoration: none;

  span {
    color: @ppui-v2-color-blue-600;
  }

  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }
}

.copied-tooltip {
  .paypalme-tooltip(6px);
}

.tooltip-enter {
  opacity: 0.01;
}

.tooltip-enter.tooltip-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.tooltip-leave {
  opacity: 1;
}

.tooltip-leave.tooltip-leave-active {
  opacity: 0.01;
  transition: opacity 250ms ease-out;
}

.share-icon-color {
  color: @ppui-v2-color-blue-600;
}

.share-button-container {
  font-weight: normal;
  margin-bottom: 60px;

  @media (min-width: @screen-xs-min) and (max-width: @screen-md-max) {
    margin-bottom: 36px;
  }

  .share-icon {
    color: @ppui-v2-color-blue-600;
  }

  .share-label {
    margin-top: 8px;
    text-align: center;
  }

  .share-label-cursor {
    cursor: pointer;
    margin-top: 5px !important;
    color: @sysColorBackgroundHighContrast;

    @media (min-width: @screen-xs-min) and (max-width: @screen-md-max) {
      margin-top: 0 !important;
    }
  }

  @media (min-width: @screen-tab-md-min) {
    .share-icon {
      display: flex;
      cursor: pointer;
      background-color: @ppui-v2-color-neutral-100;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      align-items: center;
      margin: auto !important;
      flex-direction: column;
      justify-content: space-between;

      & span {
        margin-top: 5px;
      }
    }
  }

  @media (min-width: @screen-xs-min) and (max-width: @screen-md-max) {
    display: flex;

    .share-icon {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }

    .share-label {
      margin-left: 10px;
      margin-top: 2px;
      font-size: 18px !important;
    }
  }
}
