.activity-row {
  margin: 16px 0;
  display: flex;
  justify-content: start;
  padding: 16px !important;
}

.update-tile {
  text-decoration: none !important;
  display: block;
}
