@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~stylesheets/twbs';
@import (reference) '~stylesheets/colors';

.charity-row-one {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  @media (max-width: @phonePortraitMax) {
    flex-direction: column;
    align-items: start;
  }

  @media (max-width: @screen-sm-min) {
    flex-direction: column;
    align-items: start;
  }

  & div.charity-name-section {
    margin-left: 8px;

    @media (max-width: @phonePortraitMax) {
      margin-left: 0;
      margin-top: 8px;
    }

    @media (max-width: @screen-sm-min) {
      margin-left: 0;
      margin-top: 8px;
    }
  }

  & div > img {
    object-fit: scale-down;
  }
}

.charity-row-two {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 26px;
}

.web-icon {
  margin-left: 35px;
  cursor: pointer;
}

.alert {
  background-color: @sysColorBackgroundMain !important;
  margin-top: 10px;
}

.un-enrolled-link {
  margin-top: 40px;
}
