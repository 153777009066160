@import (reference) '~stylesheets/modules/type';

.form {
  &__title {
    text-align: center;
    margin-bottom: 8px;
    width: 384px;
  }

  &__subtitle,
  &__buttom_margin {
    margin-bottom: 32px;
  }

  &__button {
    text-align: center;
    margin-top: 40px;
  }
}
