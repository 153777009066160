@import (reference) '~stylesheets/twbs';
@import (reference) '~stylesheets/modules/type';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~pp-tokens/build/web/less/tokens';

.activity-row {
  margin: 16px 0;
  display: flex;
  justify-content: start;
  padding: 16px !important;
}

.details-content {
  display: inline-block;
  width: 100%;
  outline: none;
}

.details-content-mweb {
  @media (max-width: @screen-sm-min) {
    padding: 16px;
  }
}

.truncate-subject {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2rem !important;
}

.subject-name {
  width: 80%;

  @media (max-width: @screen-sm-min) {
    width: 100%;
  }
}

a.row-edit-link:focus {
  display: inline-block;
  border-radius: 50%;
}

.subject-timestamp {
  color: @ppui-color-grey-600 !important;
}

.edit-button {
  padding: 0.25rem !important;
  margin-top: 5px;
}

.subject-description {
  margin-top: 12px;
  margin-bottom: 5px;
}

.update-button {
  margin-top: 2rem;
  justify-content: center;
}

.parentheses {
  &::before {
    content: ' (';
  }

  &::after {
    content: ')';
  }
}

.truncate-description {
  grid-area: body_text;
  display: -webkit-box;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow: hidden;
  line-height: 1.3rem;
  max-height: 4.5rem;
  -webkit-line-clamp: 3;

  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  position: relative;
}

.organizer-update {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: @screen-sm-min) {
    display: block;
  }
}
