@import (reference) '~stylesheets/modules/type';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~stylesheets/vx_extensions';

.spinner-title {
  .text-2();
  text-align: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-2rem {
  margin-bottom: 2rem;
}

.create-indicator-container {
  width: 100%;
}

.go-to-pool-button {
  width: 100%;
}

.feedback {
  text-align: center;
}

.feedback-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  margin-top: 24px;
}

.create-indicator {
  .vx_icon_align(@vxLightGreen);
}

.title {
  // .text-2();
  text-align: center;
  margin-bottom: 8px;
}

.subtitle {
  margin-bottom: 32px;
  text-align: left;
}

.textarea-wrapper {
  margin-bottom: 32px;
  margin-top: 24px;
}

.success {
  background-image: url('https://www.paypalobjects.com/paypal-ui/illustrations/svg/success.svg');
  background-position: top center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 100%;
  height: 128px;
  vertical-align: top;
  margin-bottom: 30px;
  margin-top: -148px;
}

.img-container-header {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  img {
    height: 180px;
    width: 180px;
  }
}

.publish-container {
  @media (max-width: 426px) {
    padding: 0 16px;
  }
}

.mshare-container {
  height: auto !important;
}
