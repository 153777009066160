@import (reference) '~stylesheets/modules/type';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';

.title {
  .text-2();
  margin-bottom: 24px;
}

.delete-button {
  margin: 36px auto 0;
}
