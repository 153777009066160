@import (reference) '~pp-tokens/build/web/less/tokens';

.plaque,
.circle {
  width: 100%;
  float: left;
  border-radius: @ppui-border-radius-lg;
  background-color: @ppui-v2-color-neutral-200;
  border: 0.0625rem solid transparent;
}

.circle {
  border-radius: @ppui-border-radius-circle;
}

.title {
  height: 48px;
  margin-bottom: 16px;
}

.badge {
  float: left;
  width: 136px;
  height: 28px;
  margin-right: 16px;
  margin-bottom: 32px;
}

.amount {
  float: left;
  clear: both;
  width: 243px;
  height: 48px;
  margin-bottom: 8px;
}

.progress-bar {
  height: 20px;
  margin-bottom: 8px;
}

.amount-raised {
  height: 20px;
  margin-bottom: 92px;
}

.actions {
  float: left;
  margin-bottom: 92px;

  &__action {
    float: left;
    width: 64px;
    height: 92px;
    margin-right: 62px;

    .circle {
      width: 64px;
      height: 64px;
      margin-bottom: 8px;
    }

    .plaque {
      height: 20px;
    }
  }
}

.activity {
  height: 24px;
  margin-bottom: 24px;
}
