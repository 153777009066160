@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~stylesheets/modules/type';
@import (reference) '~stylesheets/twbs';
@import (reference) '~stylesheets/colors';

.title {
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 10px;
}

.subtitle {
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 16px;
}

.close-button {
  margin-top: 12px;
}

.ok-button {
  margin-bottom: 0;
}

.delete-button {
  margin-bottom: 0;
  width: 100%;
  color: @white !important;
}

.dialog-content {
  padding: 0 70px 72px;

  @media (max-width: @screen-sm-min) {
    padding: 0 22px 22px;
  }
}

.icon {
  text-align: center;
  margin-bottom: 17px;
}
