.header__container {
  & > div {
    height: 3.5rem; // assigns space if header present for mobile view - no header in Venice
  }

  @media (min-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 901;
  }
}
