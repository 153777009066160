@import (reference) '~stylesheets/modules/type';
@import (reference) '~stylesheets/colors';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~pp-tokens/build/web/less/tokens';
@import (reference) '~stylesheets/twbs';

.activity-row {
  margin: 16px 8px;
  justify-content: start;
  padding: 16px;
}

.avatar-wrapper {
  @size: 48px;
  width: @size;
  height: @size;
}

.details-wrapper {
  margin-left: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.details-content {
  display: inline-block;
}

.subject-name {
  word-break: break-word;
}

.activity-status {
  color: @sysColorBackgroundMediumContrast !important;
  font-size: 0.9rem !important;
}

.activity-owner-details {
  display: flex;
}

.details-note {
  display: inline-block;
  margin-top: 5px;
  margin-left: 8%;
  width: 92%;

  @media (max-width: @screen-lg-min) {
    margin-left: 11%;
    width: 89% !important;
  }

  @media (max-width: @screen-md-min) {
    margin-left: 16%;
    width: 84% !important;
  }

  @media (max-width: @screen-sm-min) {
    margin-left: 23%;
    width: 77% !important;
  }
}
