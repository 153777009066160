.card__content {
  display: flex;
  height: 160px;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 !important;
}

.content {
  &__top {
    flex-grow: 1;
  }

  &__bottom {
    flex-shrink: 0;
  }
}

.top__image {
  height: 84px;
  max-width: 99%;
  display: none;

  &--visible {
    display: block;
  }
}
