@import (reference) '~stylesheets/twbs';
@import (reference) '~stylesheets/colors';

.sub-text {
  color: @sysColorBackgroundHighContrast;
  text-align: center;

  @media (min-width: @screen-tab-md-min) {
    margin-top: 10;
  }

  @media (min-width: @screen-xs-min) and (max-width: @screen-md-max) {
    margin-left: 10px;
    font-size: 20px !important;
  }
}
