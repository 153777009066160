@import (reference) '../../../stylesheets/colors';

.mock-text-container {
  height: 1em;
  display: inline-block;
}

.mock-text-aligner {
  height: 1em;
  vertical-align: middle;
  display: inline-block;
}

.mock-text {
  display: inline-block;
  height: 0.5em;
  background-color: @mock-text-color;
}
