@import (reference) '~pp-tokens/build/web/less/tokens';

.sheet__pictogram {
  text-align: center;
  margin-bottom: 32px;
}

.sheet__body {
  margin-bottom: 32px;
}

.sheet__disclaimer {
  margin-bottom: 16px;

  & a:focus {
    text-decoration: underline !important;
  }
}

.body__title {
  margin-bottom: 16px;
  text-align: center;
}

.body__subtitle {
  margin-bottom: 16px;
}

.sheet__cta {
  text-align: center;

  a {
    color: @ppui-color-white !important;
  }
}
