@import (reference) '~stylesheets/modules/type';

.eligibilty-errors {
  text-align: center;
}

.icon-container {
  width: 116px;
  height: 116px;
  display: inline-block;
  margin-bottom: 35px;
}

.title {
  .text-2();
  margin-bottom: 8px;
}

.description {
  .text-5_lighter();
  margin-bottom: 36px;
}

.go-to-account-button {
  margin-top: 10px;
}
