@import (reference) '~stylesheets/modules/type';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~pp-tokens/build/web/less/tokens';

.preview-container {
  padding: 0 12.5;

  .viewer {
    height: max-content;
  }
}
