@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~pp-tokens/build/web/less/tokens';
@import (reference) '../../stylesheets/twbs.less';
@import (reference) '~stylesheets/colors';

.campaign_container_row {
  margin: 1rem 0;
}

.donate-button {
  margin-top: 30px;
}

.manage-button {
  display: block;
  margin-top: 30px;
  width: 100%;

  @media (max-width: @phonePortraitMax) {
    margin-top: 16px;
  }
}

.text-center {
  text-align: center;
}

.social-share-container {
  border: 1px solid @ppui-v2-color-neutral-300;
  margin-top: 56px;
  border-radius: 4px;
  padding: 16px 12px;
  box-sizing: border-box;
}

.btn {
  float: right;
  padding: 15px;
}

.donateDisabledWrapper {
  display: flex;
  margin-bottom: 2rem;
}

.donateDisabledText {
  padding: 2px 5px;
}

// CSS Grid Details
.campaign_container {
  padding: 3rem 2rem 2rem;
  max-width: 1024px;
  display: grid;
  grid-column-gap: 5.86%;
  grid-row-gap: 32px;
  grid-template-columns: 57.07% 37.07%;
  grid-template-areas:
    'campaignHeader campaignHeader'
    'campaignCover campaignProgress'
    'campaignTabs campaignSharing'
    'charity emptyDiv'
    'donations campaignReporting';
}

.share-caption {
  margin-top: 16px;
}

.campaignHeader {
  grid-area: campaignHeader;

  @media (max-width: @phonePortraitMax) {
    padding-left: 15px;
  }
}

.campaignManage {
  padding-top: 5px;
  grid-area: campaignManage;
}

.campaignCover {
  grid-area: campaignCover;
  height: 300px;
  position: relative;
  margin-bottom: 50px;

  @media (max-width: @phonePortraitMax) {
    height: 228px;
  }
}

.campaignProgress {
  grid-area: campaignProgress;

  @media (max-width: @phonePortraitMax) {
    padding-left: 15px;
  }
}

.progress-bar {
  margin-bottom: 32px;
}

.campaignTabs {
  grid-area: campaignTabs;
}

.campaignSharing {
  grid-area: campaignSharing;
}

.campaignReporting {
  grid-area: campaignReporting;
}

.charity {
  grid-area: charity;
}

.emptyDiv {
  grid-area: emptyDiv;
}

.campaignReporting.gnc {
  margin-top: 2rem;
}

.emptyColumn {
  grid-area: emptyColumn;
}

.donations {
  margin: 16px 0;
  grid-area: donations;
}

.pageLoading {
  align-items: center;
  background-color: rba(0, 0, 0, 0);
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 9999;
}

.share-button {
  display: inherit;
  margin-top: 16px;

  button {
    width: 100%;
  }
}

@media (max-width: @phonePortraitMax) {
  .campaign_container {
    padding: 2rem 1rem;
    grid-column-gap: inherit;
    max-width: 100%;
    grid-row-gap: 15px;
    grid-template-columns: 100%;
    grid-template-areas:
      'campaignCover'
      'campaignHeader'
      'campaignProgress'
      'campaignManage'
      'campaignTabs'
      'charity'
      'donations'
      'campaignSharing'
      'campaignReporting';
  }

  .campaignManage {
    > a {
      width: 100%;
    }
  }
}

.findMoreLink {
  @media (max-width: @phonePortraitMax) {
    display: none;
  }
}

.gncDisclaimerContainer {
  max-width: 1040px;
  margin: 0 auto;
  padding: 48px 16px;
}

@media (min-width: @screen-sm-min) {
  .fundraiser_header {
    margin-top: 1rem;
  }
}

.charity {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: @sysColorBackgroundMain;
  margin-top: 68px;
}

.p-15 {
  padding: 15px;
}
