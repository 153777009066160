@import (reference) '~stylesheets/twbs';
@import (reference) '~stylesheets/modules/type';
@import (reference) '~pp-tokens/build/web/less/tokens';
@import (reference) '~stylesheets/colors';
@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';

@padding: 2rem;

.row {
  padding: @padding 0;
  position: relative;

  &:last-child {
    border: none;
    padding-bottom: 0;
  }
}

.avatar-wrapper {
  @size: 56px;
  width: @size;
  height: @size;
  display: inline-block;
  margin-right: 14px;
  vertical-align: top;
}

.details-wrapper {
  display: inline-block;
  width: 60%;
  margin-top: 3px;
}

.details-note {
  display: inline-block;
  margin-top: 3px;
  margin-left: 10%;
  width: 90%;

  @media (max-width: @screen-lg-min) {
    margin-left: 13%;
    width: 87% !important;
  }

  @media (max-width: @screen-md-min) {
    margin-left: 17%;
    width: 83% !important;
  }

  @media (max-width: @screen-sm-min) {
    margin-left: 20%;
    width: 80% !important;
  }
}

.details-wrapper-preview {
  composes: details-wrapper;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.amount {
  .text-3_lighter();
  display: inline-block;
  width: 150px;
  position: absolute;
  text-align: right;
  right: 0;
  margin-top: 10px;
  color: @vxDarkGray;

  @media (max-width: 370px) {
    margin-top: 15px;
  }
}

.amount-preview {
  composes: amount;
  color: @mock-amount-color;
}

.contributor-name {
  .text-5_lighter();
  color: @vxDarkGray;
}

.days-ago,
.note {
  .text-6_lighter();
  color: @vxSecondaryGray;
}

.days-ago {
  line-height: 1;
  margin-bottom: 12px;
  color: @ppui-color-grey-600 !important;
}

.note {
  line-height: 1.3;
  background-color: @ppui-v2-color-neutral-100 !important;
  border-radius: 8px;
  padding: 12px;
}

.pledge-contribution-row {
  padding-top: 0.5rem;
}
