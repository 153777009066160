@import (reference) '~vxpattern-lib-paypal/dist/components/core/_variables';
@import (reference) '~stylesheets/colors';

.stroke-color(@color) {
  &,
  & * {
    fill: @color;
    stroke: @color;
  }
}

svg.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.stroke-color-black {
    .stroke-color(@pp-text-color);
  }

  &.stroke-color-blue {
    .stroke-color(@vxAccessibleBlue);
  }

  &.stroke-color-white {
    .stroke-color(white);
  }

  &.disable-fill {
    &,
    & * {
      fill: none;
    }
  }

  &:not(.disable-fill) {
    &,
    & * {
      stroke: none;
    }
  }
}
