@media (max-width: 700px) {
}
.step-animation-container {
  position: relative;
  min-height: calc(100% - 180px);
}
@media (max-width: 700px) {
  .step-animation-container {
    min-height: calc(100% - 170px);
  }
}
.step-enter-forward {
  position: absolute;
  width: 100%;
  transform: translateX(120%);
  will-change: transform;
}
.step-enter-forward.step-enter-forward-active {
  transform: translateX(0%);
  transition: all 250ms ease-out;
}
.step-leave-forward {
  position: absolute;
  width: 100%;
  transform: translateX(0%);
  will-change: transform;
}
.step-leave-forward.step-leave-forward-active {
  transform: translateX(-120%);
  transition: all 250ms ease-out;
}
.step-enter-backward {
  position: absolute;
  width: 100%;
  transform: translateX(-120%);
  will-change: transform;
}
.step-enter-backward.step-enter-backward-active {
  transform: translateX(0%);
  transition: all 250ms ease-out;
}
.step-leave-backward {
  position: absolute;
  width: 100%;
  transform: translateX(0%);
  will-change: transform;
}
.step-leave-backward.step-leave-backward-active {
  transform: translateX(120%);
  transition: all 250ms ease-out;
}
